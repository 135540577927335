const initialState = {
  bonnieVillages: [],
};

const bonnieVillageReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_BONNIE_VILLAGES_SUCCESS":
      return {
        ...state,
        bonnieVillages: action.payload,
      };
    case "ADD_BONNIE_VILLAGE_SUCCESS":
      return {
        ...state,
        bonnieVillages: [action.payload, ...state.bonnieVillages],
      };
    case "EDIT_BONNIE_VILLAGE_SUCCESS":
      console.log("action.payload: ", action.payload);
      return {
        ...state,
        bonnieVillages: state.bonnieVillages.map((village) =>
          village.id === action.payload.id
            ? { ...village, ...action.payload.bonnieVillage }
            : village
        ),
      };
    case "DELETE_BONNIE_VILLAGE_SUCCESS":
      return {
        ...state,
        bonnieVillages: state.bonnieVillages.filter(
          (village) => village.id !== action.payload
        ),
      };
    default:
      return state;
  }
};

export default bonnieVillageReducer;
