import React, { useEffect, useState } from "react";
import { Button, Table } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import "../../assets/css/Statistics.css";
import TopTwentyStatsGraph from "./TopTwentyStatsGraph";
import { getServerSettingsData } from "../../store/actions/serverSettingsAction";
import moment from "moment-timezone";
import {
  getTop20Alliances,
  getTop20Players,
} from "../../store/actions/playerStatisticsAction";

const TopTwenty = ({ condition }) => {
  const dispatch = useDispatch();
  const { serverSettingsData } = useSelector((state) => state.serverSettings);
  const [topPlayers, setTopPlayers] = useState([]);
  const [topAlliances, setTopAlliances] = useState([]);
  const [showPlayerData, setShowPlayerData] = useState(true);
  const [showPopulationChart, setShowPopulationChart] = useState(true);
  const [timezone, setTimeZone] = useState();
  const [sortBy, setSortBy] = useState("totalPopulation");

  useEffect(() => {
    const fetchTopPlayers = async () => {
      const todayUnix = moment.tz(timezone).startOf("day").unix();
      const players = await dispatch(getTop20Players(todayUnix, sortBy));
      setTopPlayers(players);
    };

    const fetchTopAlliances = async () => {
      const todayUnix = moment.tz(timezone).startOf("day").unix();
      const alliances = await dispatch(getTop20Alliances(todayUnix, sortBy));
      setTopAlliances(alliances);
    };

    if (showPlayerData) {
      fetchTopPlayers();
    } else {
      fetchTopAlliances();
    }
  }, [showPlayerData, showPopulationChart, timezone, sortBy, dispatch]);

  const handlePlayerPopulationChartClick = () => {
    setShowPlayerData(true);
    setShowPopulationChart(true);
    setSortBy("totalPopulation");
  };

  const handlePlayerConquestChartClick = () => {
    setShowPlayerData(true);
    setShowPopulationChart(false);
    setSortBy("conqueredVillagesCount");
  };

  const handleAlliancePopulationChartClick = () => {
    setShowPlayerData(false);
    setShowPopulationChart(true);
    setSortBy("totalPopulation");
  };

  const handleAllianceConquestChartClick = () => {
    setShowPlayerData(false);
    setShowPopulationChart(false);
    setSortBy("conqueredVillagesCount");
  };

  useEffect(() => {
    if (serverSettingsData && serverSettingsData.length > 0) {
      const data = serverSettingsData[0];
      setTimeZone(data?.timezone);
    }
  }, [serverSettingsData]);

  useEffect(() => {
    dispatch(getServerSettingsData());
  }, [dispatch]);

  return (
    <div className="mt-5">
      <div className="d-flex justify-content-between flex-wrap mb-4">
        <div className="button-container">
          <h5 className="text-center mb-3 section-title">Players</h5>
          <Button
            className="custom-button text-white p-2 mb-1"
            disabled={condition === "Read Only"}
            onClick={handlePlayerPopulationChartClick}
          >
            <i className="fas fa-users"></i> Top 20 Charts for Population
          </Button>
          <Button
            className="custom-button text-white p-2 mb-1"
            disabled={condition === "Read Only"}
            onClick={handlePlayerConquestChartClick}
          >
            <i className="fas fa-trophy"></i> Top 20 Charts for Conquest
          </Button>
        </div>
        <div className="button-container">
          <h5 className="text-center mb-3 section-title">Alliances</h5>
          <Button
            className="custom-button text-white p-2 mb-1"
            disabled={condition === "Read Only"}
            onClick={handleAlliancePopulationChartClick}
          >
            <i className="fas fa-users"></i> Top 20 Charts for Population
          </Button>
          <Button
            className="custom-button text-white p-2 mb-1"
            disabled={condition === "Read Only"}
            onClick={handleAllianceConquestChartClick}
          >
            <i className="fas fa-trophy"></i> Top 20 Charts for Conquest
          </Button>
        </div>
      </div>

      <div className="table-responsive">
        <Table bordered>
          <thead>
            <tr>
              <th className="text-center bg-info p-3">Rank</th>
              {showPlayerData && (
                <th className="text-center bg-info p-3">Player</th>
              )}
              <th className="text-center bg-info p-3">Alliance</th>
              <th className="text-center bg-info p-3">Villages</th>
              <th className="text-center bg-info p-3">Population </th>
              <th className="text-center bg-info p-3">Conquests</th>
              <th className="text-center bg-info p-3">Population Graph</th>
            </tr>
          </thead>
          {showPlayerData !== null && showPopulationChart !== null && (
            <tbody>
              {showPlayerData
                ? topPlayers.map((player, index) => (
                    <tr key={index}>
                      <td className="text-center">{index + 1}</td>
                      <td className="text-center">{player?.playerName}</td>
                      <td className="text-center">{player?.allianceTag}</td>
                      <td className="text-center">{player?.villagesCount}</td>
                      <td className="text-center">
                        {player?.totalPopulation?.toLocaleString()}
                      </td>
                      <td className="text-center">
                        {player?.conqueredVillagesCount}
                      </td>
                      <td className="text-center">
                        <div className="d-flex justify-content-center">
                          <TopTwentyStatsGraph
                            data={player?.playerID}
                            type="player"
                          />
                        </div>
                      </td>
                    </tr>
                  ))
                : topAlliances.map((alliance, index) => (
                    <tr key={index}>
                      <td className="text-center">{index + 1}</td>
                      <td className="text-center">{alliance?.allianceTag}</td>
                      <td className="text-center">{alliance?.villagesCount}</td>
                      <td className="text-center">
                        {alliance?.totalPopulation?.toLocaleString()}
                      </td>
                      <td className="text-center">
                        {alliance?.conqueredVillagesCount}
                      </td>
                      <td className="text-center">
                        <div className="d-flex justify-content-center">
                          <TopTwentyStatsGraph
                            data={alliance?.allianceID}
                            type="alliance"
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
            </tbody>
          )}
        </Table>
      </div>
    </div>
  );
};

export default TopTwenty;
