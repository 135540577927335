// import { createStore, applyMiddleware } from 'redux';
// import {thunk} from 'redux-thunk';
// import rootReducer from './reducers/rootReducer';

// const store = createStore(rootReducer, applyMiddleware(thunk));

// export default store;

import { applyMiddleware, compose, createStore } from "redux";
import rootReducer from "./reducers/rootReducer";
import { thunk } from "redux-thunk";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
let store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

export default store;
