import firebase from "../../config/firebase";
import { toast } from "react-toastify";

export const getColumns = () => async (dispatch) => {
  dispatch(loader(true));
  try {
    firebase
      .firestore()
      .collection("permanentRosterColumns")
      .orderBy("index", "asc")
      .onSnapshot(async (data) => {
        let tempData = [];
        for (let doc of data.docs) {
          let id = doc.id;
          let columnData = doc.data();
          tempData.push({
            id: id,
            ...columnData,
          });
        }

        const rowsSnapshot = await firebase
          .firestore()
          .collection("permanentRosterRows")
          .get();
        let rowsData = rowsSnapshot.docs.map((rowDoc) => ({
          rowId: rowDoc.id,
          ...rowDoc.data(),
        }));
        dispatch({ type: "GET_COLUMNS", payload: tempData });
        dispatch({ type: "GET_ROWS", payload: rowsData });
        dispatch(loader(false));
      });
  } catch (error) {
    dispatch(loader(false));
    alert(error.message);
  }
};

export const swapColumns = (dragIndex, dropIndex) => async (dispatch) => {
  try {
    const columnsRef = firebase
      .firestore()
      .collection("permanentRosterColumns");

    const snapshot = await columnsRef.orderBy("index", "asc").get();
    const columns = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

    const updatedColumns = columns.map((column) => {
      if (column.index === dragIndex) {
        return { ...column, index: dropIndex };
      } else if (column.index === dropIndex) {
        return { ...column, index: dragIndex };
      }
      return column;
    });

    await Promise.all(
      updatedColumns.map(async (column) => {
        const docId = column.id;
        await columnsRef.doc(docId).update({ index: column.index });
      })
    );

    const updatedSnapshot = await columnsRef.orderBy("index", "asc").get();
    const updatedSortedColumns = updatedSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    dispatch({ type: "SWAP_COLUMNS_SUCCESS", payload: updatedSortedColumns });
    toast.success("Columns swaped successfully");
  } catch (error) {
    toast.error("Error swapping columns:", error);
  }
};

export const addNewRow =
  (payload, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      firebase
        .firestore()
        .collection("permanentRosterRows")
        .add({ ...payload })
        .then(onSuccess());
      toast.success("Row added successfully");
    } catch (error) {
      console.error(error);
    }
  };

export const deleteRow =
  (id, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      firebase
        .firestore()
        .collection("permanentRosterRows")
        .doc(id)
        .delete()
        .then(onSuccess());
      toast.success("Row deleted successfully");
    } catch (error) {
      toast.error("Failed deleting row");
    }
  };
export const updateRow =
  (id, key, value, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      firebase
        .firestore()
        .collection("permanentRosterRows")
        .doc(id)
        .update({
          [key]: value,
        })
        .then(onSuccess());
    } catch (error) {
      console.error(error);
    }
  };

export const addNewColumn =
  (payload, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      firebase
        .firestore()
        .collection("permanentRosterColumns")
        .add({ ...payload })
        .then(onSuccess());
      toast.success("New column added successfully");
    } catch (error) {
      toast.error("Failed adding new column");
    }
  };

export const deleteColumn =
  (id, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      firebase
        .firestore()
        .collection("permanentRosterColumns")
        .doc(id)
        .delete()
        .then(onSuccess());
      toast.success("Column deleted successfully");
    } catch (error) {
      toast.error("Failed deleting column");
    }
  };

export const saveFilteredData =
  (data, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      dispatch(loader(true));
      await firebase
        .firestore()
        .collection("playerRosterSavedData")
        .add({ data });
      toast.success("Filtered data saved!");
      onSuccess();
      dispatch(loader(false));
    } catch (error) {
      toast.error("Error saving filtered data");
      dispatch(loader(false));
    }
  };

export const getFilteredData = () => async (dispatch) => {
  try {
    dispatch(loader(true));
    const snapshot = await firebase
      .firestore()
      .collection("playerRosterSavedData")
      .get();
    const filteredData = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    dispatch({
      type: "SAVE_FILTERED_DATA",
      payload: filteredData,
    });
    dispatch(loader(false));
    console.log("Filtered data fetched and stored in state!");
  } catch (error) {
    console.error("Error fetching and storing filtered data:", error);
  }
};

export const deleteFilteredData =
  (id, onSuccess = () => {}) =>
  async (dispatch) => {
    dispatch(loader(true));
    try {
      await firebase
        .firestore()
        .collection("playerRosterSavedData")
        .doc(id)
        .delete();
      dispatch({ type: "DELETE_FILTERED_DATA", payload: id });
      toast.success("Saved view deleted!");
      onSuccess();
      dispatch(loader(false));
      dispatch(getFilteredData());
    } catch (error) {
      toast.error("Error deleting saved view");
    }
  };

export const loader = (val) => async (dispatch) => {
  dispatch({
    type: "LOADER",
    payload: val,
  });
};
