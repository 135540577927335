const initialState = {
  uid: "",
  user: null,
  error: null,
  isLoading: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_UID_FROM_LOCAL_STORAGE":
      return {
        ...state,
        uid: action.payload,
      };
    case "REGISTER_SUCCESS":
    case "LOGIN_SUCCESS":
      return {
        ...state,
        uid: action.payload.userId,
        user: action.payload.userData,
        error: null,
      };
    case "REGISTER_FAIL":
    case "LOGIN_FAIL":
      return {
        ...state,
        uid: "",
        user: {
          data: {},
        },
        error: action.payload,
      };
    case "FETCH_USER_DATA_START":
      return {
        ...state,
        isLoading: true,
      };
    case "ALL_DISCORD_NAMES":
      return {
        ...state,
        discordNames: action.payload,
      };
    case "FETCH_USER_DATA_SUCCESS":
      return {
        ...state,
        user: action.payload,
        isLoading: false,
      };
    case "FETCH_USER_DATA_FAIL":
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case "LOADING":
      return {
        isLoading: action.payload,
      };
    default:
      return state;
  }
};

export default authReducer;
