import { toast } from "react-toastify";
import firebase from "../../config/firebase";

export const saveUnitData = (units, collectionName) => {
  return async (dispatch) => {
    try {
      const firestore = firebase.firestore();
      const batch = firestore.batch();

      units.forEach((unit) => {
        const docRef = firestore.collection(collectionName).doc(unit.id);
        batch.update(docRef, unit);
      });

      await batch.commit();

      toast.success("Data updated successfully");
    } catch (error) {
      toast.error("Error updating data");
    }
  };
};

export const getTeutonsData = () => {
  return async (dispatch) => {
    try {
      dispatch(loader(true));
      const firestore = firebase.firestore();
      const unitsRef = firestore
        .collection("teutonsTroopData")
        .orderBy("createdAt", "asc");
      const snapshot = await unitsRef.get();

      const Teutons = [];
      snapshot.forEach((doc) => {
        Teutons.push({ id: doc.id, ...doc.data() });
      });

      dispatch({ type: "GET_TEUTONS_DATA", payload: Teutons });
      dispatch(loader(false));
    } catch (error) {
      console.error("Error getting unit data: ", error);
      dispatch(loader(false));
    }
  };
};

export const getRomansData = () => {
  return async (dispatch) => {
    try {
      dispatch(loader(true));
      const firestore = firebase.firestore();
      const unitsRef = firestore
        .collection("romansTroopData")
        .orderBy("createdAt", "asc");
      const snapshot = await unitsRef.get();

      const Romans = [];
      snapshot.forEach((doc) => {
        Romans.push({ id: doc.id, ...doc.data() });
      });

      dispatch({ type: "GET_ROMANS_DATA", payload: Romans });
      dispatch(loader(false));
    } catch (error) {
      console.error("Error getting unit data: ", error);
      dispatch(loader(false));
    }
  };
};
export const getGualsData = () => {
  return async (dispatch) => {
    try {
      dispatch(loader(true));
      const firestore = firebase.firestore();
      const unitsRef = firestore
        .collection("gaulsTroopData")
        .orderBy("createdAt", "asc");
      const snapshot = await unitsRef.get();

      const Guals = [];
      snapshot.forEach((doc) => {
        Guals.push({ id: doc.id, ...doc.data() });
      });

      dispatch({ type: "GET_GUALS_DATA", payload: Guals });
      dispatch(loader(false));
    } catch (error) {
      console.error("Error getting unit data: ", error);
      dispatch(loader(false));
    }
  };
};
export const getRNatureData = () => {
  return async (dispatch) => {
    try {
      dispatch(loader(true));
      const firestore = firebase.firestore();
      const unitsRef = firestore
        .collection("natureTroopData")
        .orderBy("createdAt", "asc");
      const snapshot = await unitsRef.get();

      const Nature = [];
      snapshot.forEach((doc) => {
        Nature.push({ id: doc.id, ...doc.data() });
      });

      dispatch({ type: "GET_NATURE_DATA", payload: Nature });
      dispatch(loader(false));
    } catch (error) {
      console.error("Error getting unit data: ", error);
      dispatch(loader(false));
    }
  };
};
export const getNatarsData = () => {
  return async (dispatch) => {
    try {
      dispatch(loader(true));
      const firestore = firebase.firestore();
      const unitsRef = firestore.collection("natarsTroopData").orderBy("createdAt", "asc");
      const snapshot = await unitsRef.get();
      const Natars = [];
      snapshot.forEach((doc) => {
        Natars.push({ id: doc.id, ...doc.data() });
      });

      dispatch({ type: "GET_NATARS_DATA", payload: Natars });
      dispatch(loader(false));
    } catch (error) {
      console.error("Error getting unit data: ", error);
      dispatch(loader(false));
    }
  };
};

export const loader = (val) => async (dispatch) => {
  dispatch({
    type: "IS_TROOP_DATA_LOADING",
    payload: val,
  });
};
