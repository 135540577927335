import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, CardHeader, Spinner, Table } from "reactstrap";
import FavoriteModal from "./FavoriteModal";
import {
  deleteFavoriteCoordinate,
  fetchFavoriteCoordinates,
} from "../../../store/actions/favoriteCoordinatesActions";
import { useDispatch, useSelector } from "react-redux";
import ConfirmationModal from "../../shared/ConfirmationModal";

const FavoriteCoordinates = ({ updateOffsetWithCoordinates }) => {
  const dispatch = useDispatch();
  const { uid, user } = useSelector((state) => state.auth);
  const { favoriteCoordinates } = useSelector(
    (state) => state.favoriteCoordinate
  );

  const [isFetching, setIsFetching] = useState(false);
  const [favouriteModal, setFavouriteModal] = useState(false);
  const [activeFavourite, setActiveFavourite] = useState(null);
  const [editMode, setEditMode] = useState(false);

  const [confirmationModal, setConfirmationModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const favouriteModalToggler = () => {
    if (favouriteModal) {
      setActiveFavourite(null);
      setEditMode(false);
    }
    setFavouriteModal(!favouriteModal);
  };

  const confirmationModalToggler = () => {
    if (confirmationModal) {
      setActiveFavourite(null);
    }
    setConfirmationModal(!confirmationModal);
  };

  const initiateView = (fav) => {
    updateOffsetWithCoordinates(fav?.coordinates);
  };

  const initiateEdit = (fav) => {
    setActiveFavourite(fav);
    setEditMode(true);
    favouriteModalToggler();
  };

  const initiateDelete = (fav) => {
    setActiveFavourite(fav);
    confirmationModalToggler();
  };

  const handleDeleteFavoriteCoordinate = () => {
    setIsDeleting(true);
    dispatch(deleteFavoriteCoordinate({ id: activeFavourite?.id }))
      .then(() => confirmationModalToggler())
      .finally(() => setIsDeleting(false));
  };

  useEffect(() => {
    if (uid) {
      setIsFetching(true);
      dispatch(fetchFavoriteCoordinates({ uid })).finally(() =>
        setIsFetching(false)
      );
    }
  }, [uid]);

  return (
    <div className="ms-2">
      <Card className="shadow-sm overflow-hidden">
        <CardHeader className="d-flex justify-content-between align-items-center">
          <h5 className="mb-0">Favorite Coordinates</h5>
          <Button color="primary" onClick={favouriteModalToggler}>
            Add
          </Button>
        </CardHeader>
        <CardBody className="p-0">
          <Table responsive className="m-0 border-0">
            <thead>
              <th>Name</th>
              <th>X Coordinate</th>
              <th>Y Coordinate</th>
              <th className="text-center">Actions</th>
            </thead>
            <tbody className="m-0 p-0">
              {isFetching ? (
                <tr>
                  <td colSpan={4} className="text-center py-3">
                    <Spinner color="primary" size="sm" />
                  </td>
                </tr>
              ) : favoriteCoordinates?.length > 0 ? (
                favoriteCoordinates?.map((fav) => (
                  <tr key={fav?.id}>
                    <td>{fav?.name}</td>
                    <td>{fav?.coordinates?.x}</td>
                    <td>{fav?.coordinates?.y}</td>
                    <td>
                      <div className="d-flex justify-content-center align-items-center">
                        <Button
                          color="primary"
                          onClick={() => initiateView(fav)}
                          className="d-flex justify-content-center align-items-center me-2 overflow-hidden p-1"
                          style={{ width: "35px", height: "35px" }}
                        >
                          <i className="fas fa-eye" />
                        </Button>
                        <Button
                          color="warning"
                          onClick={() => initiateEdit(fav)}
                          className="d-flex justify-content-center align-items-center me-2 overflow-hidden p-1"
                          style={{ width: "35px", height: "35px" }}
                        >
                          <i className="fas fa-edit text-white" />
                        </Button>
                        <Button
                          color="danger"
                          onClick={() => initiateDelete(fav)}
                          className="d-flex justify-content-center align-items-center overflow-hidden p-1"
                          style={{ width: "35px", height: "35px" }}
                        >
                          <i className="fas fa-trash" />
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={4} className="text-center py-3">
                    Favorite list is empty
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </CardBody>
      </Card>
      <FavoriteModal
        isOpen={favouriteModal}
        toggle={favouriteModalToggler}
        editMode={editMode}
        activeFavorite={activeFavourite}
      />
      <ConfirmationModal
        title="Delete Favorite"
        text="Are you sure you want to delete this favorite?"
        isOpen={confirmationModal}
        toggle={confirmationModalToggler}
        isLoading={isDeleting}
        onConfirmation={handleDeleteFavoriteCoordinate}
      />
    </div>
  );
};

export default FavoriteCoordinates;
