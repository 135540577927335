import React, { useEffect, useState } from "react";
import "../../assets/css/HammerTracker.css";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewRow,
  columnResize,
  deleteRow,
  getAllColumnData,
  getModifiersData,
  getRomanRows,
  saveData,
} from "../../store/actions/hammerTrackerAction";
import { getRomansData } from "../../store/actions/TroopGlossaryAction";
import { getServerSettingsData } from "../../store/actions/serverSettingsAction";
import { getPermissions } from "../../store/actions/userpermissionsAction";
import editIcon from "../../assets/Images/Edit.jpg";
import { toast } from "react-toastify";

const Roman = () => {
  const dispatch = useDispatch();
  const { Roman, loading, columnData } = useSelector(
    (state) => state.hammerTracker
  );
  const { serverSettingsData } = useSelector((state) => state.serverSettings);
  const { Modifiers } = useSelector((state) => state.hammerTracker);
  const { Romans } = useSelector((state) => state.troopGlossary);
  const { user } = useSelector((state) => state.auth);
  const { permission } = useSelector((state) => state.userPermissions);
  const [condition, setCondition] = useState("");
  const [editedIndex, setEditedIndex] = useState(null);
  const [editedFieldName, setEditedFieldName] = useState(null);
  const [editedValue, setEditedValue] = useState("");
  const [romansData, setRomansData] = useState([]);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [resizingColumnKey, setResizingColumnKey] = useState(null);
  const [initialX, setInitialX] = useState(null);
  const [initialWidth, setInitialWidth] = useState(null);
  const [date, setDate] = useState();
  const [formData, setFormData] = useState({
    type: "Roman",
    hammerType: "enemy",
    playerName: "",
    villageName: "",
    villageCoords: { x: 0, y: 0 },
    villageMapLink: "",
    legionnaire: 0,
    praetorian: 0,
    imperian: 0,
    equites_legati: 0,
    equites_imperatoris: 0,
    equites_caesaris: 0,
    battering_ram: 0,
    fire_catapult: 0,
    senator: 0,
    settler: 0,
    hero: 0,
    trainerArtefactActive: false,
    artefactThreatLevel: "low",
    numberOfOases: 0,
    greatBarracks: false,
    greatStable: false,
    settled: false,
    serverRank: null,
  });

  //-------------Roles--------------//

  const permissionData = permission?.hammerTracker;
  const role = user?.role;
  const functionalRole = user?.functionalRole;

  //--------- Functions ---------//

  const handleChange = (e) => {
    const { id, value } = e.target;
    const numericValue = /^\d+$/.test(value) ? parseInt(value) : value;
    if (id === "xCoord") {
      setFormData({
        ...formData,
        villageCoords: {
          ...formData.villageCoords,
          x: numericValue,
        },
      });
    } else if (id === "yCoord") {
      setFormData({
        ...formData,
        villageCoords: {
          ...formData.villageCoords,
          y: numericValue,
        },
      });
    } else {
      setFormData({
        ...formData,
        [id]: numericValue,
      });
    }
  };

  const handleSubmit = async () => {
    try {
      await dispatch(addNewRow(formData));
      setFormData({
        type: "Roman",
        playerName: "",
        villageName: "",
        villageCoords: { x: 0, y: 0 },
        villageMapLink: "",
        legionnaire: 0,
        praetorian: 0,
        imperian: 0,
        equites_legati: 0,
        equites_imperatoris: 0,
        equites_caesaris: 0,
        battering_ram: 0,
        fire_catapult: 0,
        senator: 0,
        settler: 0,
        hero: 0,
        trainerArtefactActive: false,
        artefactThreatLevel: "low",
        numberOfOases: 0,
        greatBarracks: false,
        greatStable: false,
        settled: false,
        serverRank: null,
      });
      dispatch(getRomanRows());
    } catch (error) {
      toast.error("Failed to add new data");
    }
  };

  const handleDelete = async (id) => {
    try {
      await dispatch(deleteRow(id));
      dispatch(getRomanRows());
    } catch (error) {
      toast.error("Failed to delete data");
    }
  };

  const handleSave = async (rowId) => {
    try {
      const updatedRow = Roman.find((row) => row.id === rowId);
      const updatedData = { ...updatedRow, [editedFieldName]: editedValue };
      await dispatch(saveData([updatedData]));
      dispatch(getRomanRows());
      setEditedValue("");
      setEditedIndex(null);
      setEditedFieldName(null);
    } catch (error) {
      toast.error("Failed to save data");
    }
  };

  const handleSelectChange = async (e, rowId, fieldName) => {
    const { value } = e.target;
    try {
      const updatedRow = Roman.find((row) => row.id === rowId);
      const updatedData = { ...updatedRow, [fieldName]: value };
      await dispatch(saveData([updatedData]));
      setRomansData((prevData) =>
        prevData.map((item) =>
          item.id === rowId ? { ...item, [fieldName]: value } : item
        )
      );
      dispatch(getRomanRows());
      setEditedIndex(null);
      setEditedFieldName(null);
      setEditedValue("");
    } catch (error) {
      toast.error("Failed to update data");
    }
  };

  const handleCheckboxChange = async (e, rowId, fieldName) => {
    const { checked } = e.target;
    try {
      const updatedRow = Roman.find((row) => row.id === rowId);
      const updatedData = { ...updatedRow, [fieldName]: checked };
      await dispatch(saveData([updatedData]));
      setRomansData((prevData) =>
        prevData.map((item) =>
          item.id === rowId ? { ...item, [fieldName]: checked } : item
        )
      );
      dispatch(getRomanRows());
    } catch (error) {
      toast.error("Failed to update data");
    }
  };

  const handleKeyDown = (e, rowId) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSave(rowId);
      dispatch(getRomanRows());
    }
  };

  const handleClickOutside = (e) => {
    if (!e.target.closest("input") && !e.target.closest(".troop-table-cell")) {
      setEditedIndex(null);
      setEditedValue("");
      setEditedFieldName(null);
    }
  };

  //--------- Field Names -----------//

  const fieldNames = [
    "playerName",
    "villageName",
    "villageCoords",
    "villageMapLink",
    "legionnaire",
    "praetorian",
    "imperian",
    "equites_legati",
    "equites_imperatoris",
    "equites_caesaris",
    "battering_ram",
    "fire_catapult",
    "senator",
    "settler",
    "hero",
    "attackPower",
    "serverRank",
  ];

  //--------- Functions to handle sorting-------- //

  const handleSort = (column, option) => {
    let sortedUsers = [...romansData];

    if (option === "asc") {
      sortedUsers.sort((a, b) => {
        const valueA = getValue(a[column]);
        const valueB = getValue(b[column]);
        return compareValues(valueA, valueB);
      });
      setSortOrder("asc");
    } else if (option === "desc") {
      sortedUsers.sort((a, b) => {
        const valueA = getValue(a[column]);
        const valueB = getValue(b[column]);
        return compareValues(valueB, valueA);
      });
      setSortOrder("desc");
    }

    setSortColumn(column);
    setRomansData(sortedUsers);
  };

  const getValue = (value) => {
    if (typeof value === "boolean") {
      return value ? 1 : 0;
    } else if (typeof value === "object") {
      return JSON.stringify(value);
    }
    return value;
  };

  const compareValues = (valueA, valueB) => {
    if (valueA === valueB) {
      return 0;
    }
    return valueA < valueB ? -1 : 1;
  };

  const sortedUsers = [...romansData].sort((a, b) => {
    if (sortColumn) {
      const valueA = a[sortColumn];
      const valueB = b[sortColumn];

      const isEmptyA = valueA === undefined || valueA === null || valueA === "";
      const isEmptyB = valueB === undefined || valueB === null || valueB === "";

      if (isEmptyA && !isEmptyB) {
        return 1;
      }
      if (!isEmptyA && isEmptyB) {
        return -1;
      }

      const numberValueA = parseInt(valueA);
      const numberValueB = parseInt(valueB);

      if (!isNaN(numberValueA) && !isNaN(numberValueB)) {
        return sortOrder === "asc"
          ? numberValueA - numberValueB
          : numberValueB - numberValueA;
      } else {
        if (valueA && valueB) {
          const stringA = String(valueA);
          const stringB = String(valueB);
          return sortOrder === "asc"
            ? stringA.localeCompare(stringB)
            : stringB.localeCompare(stringA);
        } else {
          return 0;
        }
      }
    }
    return 0;
  });

  const clearFilters = () => {
    setSortColumn(null);
    if (Roman) {
      let romanData = calculateRomansData(Roman);
      setRomansData(romanData);
    }
  };

  //-------------- Columns --------------//

  const columns = [
    { key: "playerName", label: "Player" },
    { key: "villageName", label: "Village Name" },
    { key: "villageCoords", label: "(X, Y)" },
    { key: "villageMapLink", label: "Village Map Link" },
    { key: "legionnaire", label: "Legionnaire" },
    { key: "praetorian", label: "Praetorian" },
    { key: "imperian", label: "Imperian" },
    { key: "equites_legati", label: "Equites Legati" },
    { key: "equites_imperatoris", label: "Equites Imperatoris" },
    { key: "equites_caesaris", label: "Equites Caesaris" },
    { key: "battering_ram", label: "Battering Ram" },
    { key: "fire_catapult", label: "Fire Catapult" },
    { key: "senator", label: "Senator" },
    { key: "settler", label: "Settler" },
    { key: "hero", label: "Hero" },
    { key: "attackPower", label: "Hammer Attack Power" },
    { key: "serverRank", label: "Server Rank" },
    { key: "timestamp", label: "Timestamp" },
    { key: "trainerArtefactActive", label: "Trainer Artefact Active" },
    { key: "numberOfOases", label: "Number of Oases" },
    { key: "greatBarracks", label: "Great Barracks" },
    { key: "greatStable", label: "Great Stable" },
    { key: "settled", label: "First 3 Settled" },
    { key: "artefactThreatLevel", label: "Artefact Threat Level" },
    { key: "threatProbablity", label: "Threat Probablity" },
  ];

  //---------Server Day Calculations ---------//

  const startDateParts = date && date ? date.split("-") : [];
  const startDate = new Date(
    parseInt(startDateParts[0]),
    parseInt(startDateParts[1]) - 1,
    parseInt(startDateParts[2])
  );

  const calculateServerDay = (startDate) => {
    const startTimestamp = startDate.getTime();
    const currentTimestamp = Date.now();
    const millisecondsPerDay = 1000 * 60 * 60 * 24;

    const timeDifference = currentTimestamp - startTimestamp;

    const serverDay = Math.floor(timeDifference / millisecondsPerDay);

    return serverDay;
  };

  const serverDay = calculateServerDay(startDate);

  const calculateHeroAttackPower = (hero, serverDay) => {
    let bonusPercentage = 0;
    let bonusPower = 0;

    if (serverDay >= 0 && serverDay <= 90) {
      bonusPercentage = 0.05;
      bonusPower = 1000;
    } else if (serverDay >= 91 && serverDay <= 150) {
      bonusPercentage = 0.1;
      bonusPower = 2000;
    } else {
      bonusPercentage = 0.15;
      bonusPower = 4000;
    }
    const heroAttackPower =
      (hero || 0) * (1 + bonusPercentage) + (hero > 0 ? bonusPower : 0);

    return heroAttackPower;
  };

  //---------Modifiers Calculations ---------//

  let ModifierValue =
    serverDay >= 1 && serverDay <= 29
      ? Modifiers?.day1
      : serverDay >= 30 && serverDay <= 59
      ? Modifiers?.day30
      : serverDay >= 60 && serverDay <= 89
      ? Modifiers?.day60
      : serverDay >= 90 && serverDay <= 119
      ? Modifiers?.day90
      : serverDay >= 120 && serverDay <= 149
      ? Modifiers?.day120
      : serverDay >= 150 && serverDay <= 179
      ? Modifiers?.day150
      : serverDay >= 180 && serverDay <= 209
      ? Modifiers?.day180
      : Modifiers?.day210;

  //----------TimeStamp ----- //

  function getFormattedDate(date) {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  }

  //---- Server Rank Calculations--- ///

  function estimateServerRank(hammers) {
    hammers.sort((a, b) => b.attackPower - a.attackPower);

    //Above Friendly Hammer Calculation

    let firstFriendlyIndex = -1;
    for (let i = 0; i < hammers.length; i++) {
      if (hammers[i].hammerType === "friendly") {
        firstFriendlyIndex = i;
        break;
      }
    }

    if (firstFriendlyIndex !== -1) {
      let friendlyAttackPower = hammers[firstFriendlyIndex].attackPower;
      let friendlyServerRank = hammers[firstFriendlyIndex].serverRank;

      for (let i = firstFriendlyIndex - 1; i >= 0; i--) {
        if (hammers[i].hammerType === "enemy") {
          let enemyAttackPower = hammers[i].attackPower;
          let estimatedRank = Math.floor(
            friendlyServerRank / (enemyAttackPower / friendlyAttackPower)
          );
          hammers[i].serverRank = estimatedRank;

          let prevRank = estimatedRank;
          for (let k = i - 1; k >= 0; k--) {
            if (hammers[k].serverRank === prevRank) {
              hammers[k].serverRank = prevRank + 1;
              prevRank++;
            } else {
              break;
            }
          }
        }
      }
    }

    //Below Friendly Hammer Calculation

    let lastFriendlyIndex = -1;
    for (let i = hammers.length - 1; i >= 0; i--) {
      if (hammers[i].hammerType === "friendly") {
        lastFriendlyIndex = i;
        break;
      }
    }

    if (lastFriendlyIndex !== -1) {
      let friendlyAttackPower = hammers[lastFriendlyIndex].attackPower;
      let friendlyServerRank = hammers[lastFriendlyIndex].serverRank;

      for (let i = lastFriendlyIndex + 1; i < hammers.length; i++) {
        if (hammers[i].hammerType === "enemy") {
          let enemyAttackPower = hammers[i].attackPower;
          let estimatedRank = Math.floor(
            friendlyServerRank / (enemyAttackPower / friendlyAttackPower)
          );
          hammers[i].serverRank = estimatedRank;

          let prevRank = estimatedRank;
          for (let k = i + 1; k < hammers.length; k++) {
            if (hammers[k].serverRank === prevRank) {
              hammers[k].serverRank = prevRank + 1;
              prevRank++;
            } else {
              break;
            }
          }
        }
      }
    }

    //between Two friendly hammers

    let friendlyIndexes = [];
    let enemyIndexes = [];

    for (let i = 0; i < hammers.length; i++) {
      if (hammers[i].hammerType === "friendly") {
        friendlyIndexes.push(i);
      } else if (hammers[i].hammerType === "enemy") {
        enemyIndexes.push(i);
      }
    }

    for (let i = 0; i < enemyIndexes.length; i++) {
      let enemyIndex = enemyIndexes[i];
      let prevFriendlyIndex = -1;
      let nextFriendlyIndex = -1;

      for (let j = 0; j < friendlyIndexes.length; j++) {
        if (friendlyIndexes[j] < enemyIndex) {
          prevFriendlyIndex = friendlyIndexes[j];
        } else if (friendlyIndexes[j] > enemyIndex) {
          nextFriendlyIndex = friendlyIndexes[j];
          break;
        }
      }

      if (prevFriendlyIndex !== -1 && nextFriendlyIndex !== -1) {
        let prevFriendlyRank = Number(hammers[prevFriendlyIndex].serverRank);
        let nextFriendlyRank = Number(hammers[nextFriendlyIndex].serverRank);
        let numHammersBetween = nextFriendlyIndex - prevFriendlyIndex - 1;

        let rankIncrement =
          (nextFriendlyRank - prevFriendlyRank) / (numHammersBetween + 1);

        for (let k = prevFriendlyIndex + 1; k < nextFriendlyIndex; k++) {
          hammers[k].serverRank = Math.round(
            prevFriendlyRank + rankIncrement * (k - prevFriendlyIndex)
          );
        }
      }
    }

    return hammers;
  }

  //--------- Hammer Attack Power and Threat Probability Calculations ------- //

  const calculateRomansData = (Roman) => {
    let romansData = [];
    Roman.forEach((roman) => {
      roman["attackPower"] = Math.round(
        (roman?.legionnaire || 0) * (Romans[0]?.offense || 0) +
          (roman?.praetorian || 0) * (Romans[1]?.offense || 0) +
          (roman?.imperian || 0) * (Romans[2]?.offense || 0) +
          (roman?.equites_legati || 0) * (Romans[3]?.offense || 0) +
          (roman?.equites_imperatoris || 0) * (Romans[4]?.offense || 0) +
          (roman?.equites_caesaris || 0) * (Romans[5]?.offense || 0) +
          (roman?.battering_ram || 0) * (Romans[6]?.offense || 0) +
          (roman?.fire_catapult || 0) * (Romans[7]?.offense || 0) +
          (roman?.senator || 0) * (Romans[8]?.offense || 0) +
          (roman?.settler || 0) * (Romans[9]?.offense || 0) +
          calculateHeroAttackPower(roman?.hero, serverDay)
      );
      roman["threatProbablity"] =
        Math.log(
          Math.pow(
            (roman?.legionnaire || 0) * (Romans[0]?.offense || 0) +
              (roman?.praetorian || 0) * (Romans[1]?.offense || 0) +
              (roman?.imperian || 0) * (Romans[2]?.offense || 0) +
              (roman?.equites_legati || 0) * (Romans[3]?.offense || 0) +
              (roman?.equites_imperatoris || 0) * (Romans[4]?.offense || 0) +
              (roman?.equites_caesaris || 0) * (Romans[5]?.offense || 0) +
              (roman?.battering_ram || 0) * (Romans[6]?.offense || 0) +
              (roman?.fire_catapult || 0) * (Romans[7]?.offense || 0) +
              (roman?.senator || 0) * (Romans[8]?.offense || 0) +
              (roman?.settler || 0) * (Romans[9]?.offense || 0) +
              calculateHeroAttackPower(roman?.hero, serverDay),
            ModifierValue
          )
        ) < 20
          ? "0"
          : Math.min(
              Math.round(
                ((Math.log(
                  Math.pow(
                    (roman?.legionnaire || 0) * (Romans[0]?.offense || 0) +
                      (roman?.praetorian || 0) * (Romans[1]?.offense || 0) +
                      (roman?.imperian || 0) * (Romans[2]?.offense || 0) +
                      (roman?.equites_legati || 0) * (Romans[3]?.offense || 0) +
                      (roman?.equites_imperatoris || 0) *
                        (Romans[4]?.offense || 0) +
                      (roman?.equites_caesaris || 0) *
                        (Romans[5]?.offense || 0) +
                      (roman?.battering_ram || 0) * (Romans[6]?.offense || 0) +
                      (roman?.fire_catapult || 0) * (Romans[7]?.offense || 0) +
                      (roman?.senator || 0) * (Romans[8]?.offense || 0) +
                      (roman?.settler || 0) * (Romans[9]?.offense || 0) +
                      calculateHeroAttackPower(roman?.hero, serverDay),
                    ModifierValue
                  )
                ) -
                  20) /
                  10) *
                  (roman?.trainerArtefactActive === true
                    ? Modifiers?.trainerArtefactYes
                    : Modifiers?.trainerArtefactNo) *
                  (roman?.greatBarracks === true
                    ? Modifiers?.greatBarracksYes
                    : Modifiers?.greatBarracksNo) *
                  (roman?.greatStable === true
                    ? Modifiers?.greatStableYes
                    : Modifiers?.greatStableNo) *
                  (roman?.settled === true
                    ? Modifiers?.first3SettledYes
                    : Modifiers?.first3SettledNo) *
                  (roman?.numberOfOases === "0"
                    ? Modifiers?.oasesInput0
                    : roman?.numberOfOases === "1"
                    ? Modifiers?.oasesInput1
                    : roman?.numberOfOases === "2"
                    ? Modifiers?.oasesInput2
                    : Modifiers?.oasesInput3) *
                  (roman?.artefactThreatLevel === "none"
                    ? Modifiers?.artefactNone
                    : roman?.artefactThreatLevel === "low"
                    ? Modifiers?.artefactLow
                    : roman?.artefactThreatLevel === "medium"
                    ? Modifiers?.artefactMedium
                    : Modifiers?.artefactHigh) *
                  100
              ),
              100
            );
      romansData.push({ ...roman });
    });
    romansData = estimateServerRank(romansData);
    return romansData;
  };

  //----------Column Resize-----------//
  const handleMouseDown = (columnKey, e) => {
    setResizingColumnKey(columnKey);
    setInitialX(e.clientX);
    setInitialWidth(
      parseInt(
        window
          .getComputedStyle(e.target.closest("th"))
          .getPropertyValue("width")
      )
    );
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const handleMouseUp = () => {
    setResizingColumnKey(null);
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
  };

  const handleMouseMove = (e) => {
    if (resizingColumnKey !== null) {
      const width = initialWidth + (e.clientX - initialX);
      setColumnSize(resizingColumnKey, width + "px");
    }
  };

  const setColumnSize = (columnKey, size) => {
    const minWidth = 100;
    const maxWidth = 500;
    let newSize = parseInt(size);
    newSize = Math.max(minWidth, Math.min(newSize, maxWidth));

    dispatch(columnResize(columnKey, newSize + "px", "Roman"));
  };

  const firebaseTimestampToString = (timestamp) => {
    const date = new Date(
      timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000
    );
    return date.toISOString().split("T")[0];
  };

  //--------- UseEffect ----------//

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (editedIndex !== null && editedFieldName !== null) {
      const updatedRow = Roman.find((row) => row.id === editedIndex);
      if (updatedRow) {
        const updatedData = { ...updatedRow, [editedFieldName]: editedValue };
        dispatch(saveData([updatedData]));
      }
    }
  }, [editedIndex, editedFieldName, editedValue, Roman, dispatch]);

  useEffect(() => {
    if (Roman) {
      let romansData = calculateRomansData(Roman);
      setRomansData(romansData);
    }
  }, [Roman, Romans]);

  useEffect(() => {
    if (permission && permissionData) {
      const rolePermission = permissionData[role];
      const functionalPermission = permissionData[functionalRole];
      if (
        functionalPermission !== undefined &&
        functionalPermission.trim() !== ""
      ) {
        setCondition(functionalPermission);
      } else if (rolePermission !== undefined && rolePermission.trim() !== "") {
        setCondition(rolePermission);
      }
    }
  }, [permission, permissionData, role, functionalRole]);

  useEffect(() => {
    if (serverSettingsData && serverSettingsData.length > 0) {
      const date = serverSettingsData[0]?.startDate;
      const dateString = date ? firebaseTimestampToString(date) : null;
      setDate(dateString);
    }
  }, [serverSettingsData]);

  useEffect(() => {
    dispatch(getPermissions());
    dispatch(getRomansData());
    dispatch(getRomanRows());
    dispatch(getServerSettingsData());
    dispatch(getModifiersData());
    dispatch(getAllColumnData());
  }, []);

  return (
    <>
      <div className="table-responsive">
        <table className="table">
          <thead>
            {columns.map((column) => {
              const columnInfo = columnData.find(
                (item) => item.key === column.key && item.type === "Roman"
              );
              const width = columnInfo ? columnInfo.columnSize : "auto";
              return (
                <th key={column.key} className="table-header text-center">
                  <div
                    style={{ width: width }}
                    className={
                      column.key === "villageMapLink"
                        ? "resizable-div1"
                        : "resizable-div"
                    }
                    {...(condition === "Full Access"
                      ? {
                          onMouseDown: (e) => handleMouseDown(column.key, e),
                          onMouseMove: handleMouseMove,
                          onMouseUp: handleMouseUp,
                        }
                      : {})}
                  >
                    <span>{column.label}</span>
                    <i
                      style={{
                        cursor: "pointer",
                        color: sortColumn === column.key ? "red" : "",
                        border:
                          sortColumn === column.key ? "1px solid red" : "none",
                      }}
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      className={"bi bi-filter ms-3"}
                      disabled={condition === "Read Only"}
                    ></i>
                    <ul style={{ cursor: "pointer" }} className="dropdown-menu">
                      <li
                        style={{ cursor: "pointer" }}
                        className="dropdown-item nn"
                        onClick={() => handleSort(column.key, "desc")}
                      >
                        Sort By Highest Values
                      </li>
                      <li
                        style={{ cursor: "pointer" }}
                        className="dropdown-item nn"
                        onClick={() => handleSort(column.key, "asc")}
                      >
                        Sort By Lowest Values
                      </li>
                      <li
                        style={{ cursor: "pointer" }}
                        className="dropdown-item nn"
                        onClick={() => clearFilters()}
                      >
                        Clear filter
                      </li>
                    </ul>
                  </div>
                </th>
              );
            })}
            <th className="table-header">
              <div className="resizable-div text-center">Action</div>
            </th>
          </thead>
          {loading ? (
            <tbody>
              <tr>
                <td className="text-center" colSpan="10">
                  <div className="spinner-border" role="status"></div>
                  <span className="visually-hidden">Loading...</span>
                </td>
              </tr>
            </tbody>
          ) : (
            <tbody>
              {sortedUsers.map((row) => (
                <tr key={row.id}>
                  {fieldNames.map((fieldName) => (
                    <td
                      key={fieldName}
                      className="table-cell text-center"
                      style={{
                        backgroundColor:
                          row?.hammerType === "friendly" ? "lime" : "inherit",
                      }}
                    >
                      {editedIndex === row.id &&
                      editedFieldName === fieldName ? (
                        fieldName === "villageCoords" ? (
                          <div className="d-flex">
                            <input
                              type="number"
                              className="w-50"
                              value={editedValue.x || ""}
                              onChange={(e) =>
                                setEditedValue({
                                  ...editedValue,
                                  x: e.target.value,
                                })
                              }
                              onBlur={() => handleSave(row.id)}
                              onKeyDown={(e) => handleKeyDown(e, row.id)}
                              disabled={condition === "Read Only"}
                            />
                            <input
                              type="number"
                              className="w-50"
                              value={editedValue.y || ""}
                              onChange={(e) =>
                                setEditedValue({
                                  ...editedValue,
                                  y: e.target.value,
                                })
                              }
                              onBlur={() => handleSave(row.id)}
                              onKeyDown={(e) => handleKeyDown(e, row.id)}
                              disabled={condition === "Read Only"}
                            />
                          </div>
                        ) : (
                          <input
                            type={
                              typeof row[fieldName] === "number"
                                ? "number"
                                : "text"
                            }
                            value={editedValue}
                            onChange={(e) => setEditedValue(e.target.value)}
                            onBlur={() => handleSave(row.id)}
                            onKeyDown={(e) => handleKeyDown(e, row.id)}
                            disabled={condition === "Read Only"}
                          />
                        )
                      ) : (
                        <div>
                          {fieldName === "villageMapLink" ? (
                            <div>
                              <a
                                className="text-decoration-none overflow-auto"
                                href={
                                  row[fieldName].startsWith("http")
                                    ? row[fieldName]
                                    : `https://${row[fieldName]}`
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {row[fieldName]}
                              </a>
                              <img
                                className="ms-2"
                                src={editIcon}
                                width={40}
                                role="button"
                                onClick={() => {
                                  if (condition !== "Read Only") {
                                    setEditedIndex(row.id);
                                    setEditedFieldName(fieldName);
                                    setEditedValue(row[fieldName] || "");
                                  }
                                }}
                              />
                            </div>
                          ) : (
                            <div
                              onClick={() => {
                                if (condition !== "Read Only") {
                                  setEditedIndex(row.id);
                                  setEditedFieldName(fieldName);
                                  setEditedValue(row[fieldName] || "");
                                }
                              }}
                            >
                              {fieldName === "villageCoords"
                                ? `${row?.villageCoords?.x || ""}, ${
                                    row?.villageCoords?.y || ""
                                  }`
                                : fieldName === "name" ||
                                  fieldName === "villageName"
                                ? row[fieldName] || "\u00A0"
                                : !isNaN(parseFloat(row[fieldName]))
                                ? parseFloat(row[fieldName]).toLocaleString()
                                : row[fieldName] || "\u00A0"}
                            </div>
                          )}
                        </div>
                      )}
                    </td>
                  ))}
                  <td
                    className="table-cell text-center"
                    style={{
                      backgroundColor:
                        row?.hammerType === "friendly" ? "lime" : "inherit",
                    }}
                  >
                    {row?.timestamp
                      ? `${new Date(row.timestamp.toDate()).toLocaleTimeString(
                          [],
                          { hour: "2-digit", minute: "2-digit", hour12: false }
                        )} - ${getFormattedDate(row.timestamp.toDate())}`
                      : "-"}
                  </td>
                  <td
                    className="table-cell text-center"
                    style={{
                      backgroundColor:
                        row?.hammerType === "friendly" ? "lime" : "inherit",
                    }}
                  >
                    <div className="form-group d-flex mt-2 justify-content-center">
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id={`trainerArtefactActive-${row?.id}`}
                          checked={row?.trainerArtefactActive}
                          onClick={(e) =>
                            handleCheckboxChange(
                              e,
                              row.id,
                              "trainerArtefactActive"
                            )
                          }
                          disabled={condition === "Read Only"}
                        />
                      </div>
                    </div>
                  </td>
                  <td
                    className="table-cell text-center"
                    style={{
                      backgroundColor:
                        row?.hammerType === "friendly" ? "lime" : "inherit",
                    }}
                  >
                    <select
                      className="form-select"
                      value={row?.numberOfOases}
                      disabled={condition === "Read Only"}
                      onChange={(e) =>
                        handleSelectChange(e, row.id, "numberOfOases")
                      }
                    >
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                    </select>
                  </td>
                  <td
                    className="table-cell text-center"
                    style={{
                      backgroundColor:
                        row?.hammerType === "friendly" ? "lime" : "inherit",
                    }}
                  >
                    <div className="form-group d-flex mt-2 justify-content-center">
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id={`greatBarracks-${row?.id}`}
                          checked={row?.greatBarracks}
                          onChange={(e) =>
                            handleCheckboxChange(e, row.id, "greatBarracks")
                          }
                          disabled={condition === "Read Only"}
                        />
                      </div>
                    </div>
                  </td>
                  <td
                    className="table-cell text-center"
                    style={{
                      backgroundColor:
                        row?.hammerType === "friendly" ? "lime" : "inherit",
                    }}
                  >
                    <div className="form-group d-flex mt-2 justify-content-center">
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id={`greatStable-${row?.id}`}
                          checked={row?.greatStable}
                          onClick={(e) =>
                            handleCheckboxChange(e, row.id, "greatStable")
                          }
                          disabled={condition === "Read Only"}
                        />
                      </div>
                    </div>
                  </td>
                  <td
                    className="table-cell text-center"
                    style={{
                      backgroundColor:
                        row?.hammerType === "friendly" ? "lime" : "inherit",
                    }}
                  >
                    <div className="form-group d-flex mt-2 justify-content-center">
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id={`settled-${row?.id}`}
                          checked={row?.settled}
                          onClick={(e) =>
                            handleCheckboxChange(e, row.id, "settled")
                          }
                          disabled={condition === "Read Only"}
                        />
                      </div>
                    </div>
                  </td>
                  <td
                    className="table-cell text-center"
                    style={{
                      backgroundColor:
                        row?.hammerType === "friendly" ? "lime" : "inherit",
                    }}
                  >
                    <select
                      className="form-select"
                      value={row?.artefactThreatLevel}
                      onChange={(e) =>
                        handleSelectChange(e, row.id, "artefactThreatLevel")
                      }
                      disabled={condition === "Read Only"}
                    >
                      <option value="none">None</option>
                      <option value="low">Low</option>
                      <option value="medium">Medium</option>
                      <option value="high">High</option>
                    </select>
                  </td>
                  <td
                    className="table-cell text-center"
                    style={{
                      backgroundColor:
                        row?.hammerType === "friendly" ? "lime" : "inherit",
                    }}
                  >
                    <div className="d-flex align-items-center gap-2">
                      <span>{row?.threatProbablity}%</span>
                      <div
                        className="bar-graph"
                        style={{
                          position: "relative",
                          width: `${row?.threatProbablity}%`,
                          height: "15px",
                          backgroundColor: (() => {
                            const probability = row?.threatProbablity;
                            if (probability < 0) return "transparent";
                            if (probability < 30) return "green";
                            else if (probability < 60) return "yellow";
                            else return "red";
                          })(),
                        }}
                      ></div>
                    </div>
                  </td>
                  <td
                    className="table-cell text-center"
                    style={{
                      backgroundColor:
                        row?.hammerType === "friendly" ? "lime" : "inherit",
                    }}
                  >
                    <button
                      className="btn btn-danger"
                      onClick={() => handleDelete(row?.id)}
                      disabled={condition === "Read Only"}
                    >
                      -
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>
      <div
        class="modal fade"
        id="exampleModal2"
        tabindex="-2"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add New Entry</h5>
              <button
                type="button"
                className="close bg-transparent border-0"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal2"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <label htmlFor="hammerType">Hammer Type:</label>
                <div>
                  <select
                    className="form-select"
                    id="hammerType"
                    value={formData.hammerType}
                    onChange={handleChange}
                  >
                    <option value="friendly">Friendly</option>
                    <option value="enemy">Enemy</option>
                  </select>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="playerName">Player:</label>
                <input
                  type="text"
                  className="form-control"
                  id="playerName"
                  value={formData.playerName}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="villageName">Village Name:</label>
                <input
                  type="text"
                  className="form-control"
                  id="villageName"
                  value={formData.villageName}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="villageCoords">Village Coords:</label>
                <div className="d-flex">
                  <input
                    type="number"
                    className="form-control mr-2"
                    id="xCoord"
                    placeholder="X"
                    value={formData.villageCoords.x}
                    onChange={handleChange}
                  />
                  <input
                    type="number"
                    className="form-control ms-2"
                    id="yCoord"
                    placeholder="Y"
                    value={formData.villageCoords.y}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="villageMapLink">Village Map Link:</label>
                <input
                  type="url"
                  className="form-control"
                  id="villageMapLink"
                  value={formData.villageMapLink}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="legionnaire">Legionnaire:</label>
                <input
                  type="number"
                  className="form-control"
                  id="legionnaire"
                  value={formData.legionnaire}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="praetorian">Praetorian:</label>
                <input
                  type="number"
                  className="form-control"
                  id="praetorian"
                  value={formData.praetorian}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="imperian">Imperian:</label>
                <input
                  type="number"
                  className="form-control"
                  id="imperian"
                  value={formData.imperian}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="equites_legati">Equites Legati:</label>
                <input
                  type="number"
                  className="form-control"
                  id="equites_legati"
                  value={formData.equites_legati}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="equites_imperatoris">
                  Equites Imperatoris:
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="equites_imperatoris"
                  value={formData.equites_imperatoris}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="equites_caesaris">Equites Caesaris:</label>
                <input
                  type="number"
                  className="form-control"
                  id="equites_caesaris"
                  value={formData.equites_caesaris}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="battering_ram">Battering Ram:</label>
                <input
                  type="number"
                  className="form-control"
                  id="battering_ram"
                  value={formData.battering_ram}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="fire_catapult">Fire Catapult:</label>
                <input
                  type="number"
                  className="form-control"
                  id="fire_catapult"
                  value={formData.fire_catapult}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="senator">Senator:</label>
                <input
                  type="number"
                  className="form-control"
                  id="senator"
                  value={formData.senator}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="settler">Settler:</label>
                <input
                  type="number"
                  className="form-control"
                  id="settler"
                  value={formData.settler}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="hero">Hero:</label>
                <input
                  type="number"
                  className="form-control"
                  id="hero"
                  value={formData.hero}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group d-flex mt-2">
                <label htmlFor="trainerArtefactActive">
                  Trainer Artefact Active:
                </label>
                <div className="form-check form-switch ms-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="trainerArtefactActive"
                    checked={formData.trainerArtefactActive}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="form-group mt-2">
                <label htmlFor="artefactThreatLevel">
                  Artefact Threat Level:
                </label>
                <div>
                  <select
                    className="form-select"
                    id="artefactThreatLevel"
                    value={formData.artefactThreatLevel}
                    onChange={handleChange}
                  >
                    <option value="low">Low</option>
                    <option value="medium">Medium</option>
                    <option value="high">High</option>
                  </select>
                </div>
              </div>
              <div className="form-group mt-2">
                <label htmlFor="numberOfOases">Number of Oases:</label>
                <div>
                  <select
                    className="form-select"
                    id="numberOfOases"
                    value={formData.numberOfOases}
                    onChange={handleChange}
                  >
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </div>
              </div>
              <div className="form-group d-flex mt-2">
                <label htmlFor="greatBarracks">Great Barracks:</label>
                <div className="form-check form-switch ms-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="greatBarracks"
                    checked={formData.greatBarracks}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="form-group d-flex mt-2">
                <label htmlFor="greatStable">Great Stable:</label>
                <div className="form-check form-switch ms-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="greatStable"
                    checked={formData.greatStable}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="form-group d-flex mt-2">
                <label htmlFor="settled">First 3 settled? </label>
                <div className="form-check form-switch ms-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="settled"
                    checked={formData.settled}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal2"
              >
                Close
              </button>
              <button
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal2"
                className="btn btn-primary"
                onClick={handleSubmit}
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-start mt-2 mb-3">
        <button
          className="btn btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal2"
          disabled={condition === "Read Only"}
        >
          Add New Row
        </button>
      </div>
    </>
  );
};

export default Roman;
