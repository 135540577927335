import React, { useEffect, useState } from "react";
import Teuton from "../components/HammerTracker/Teuton";
import Roman from "../components/HammerTracker/Roman";
import Gual from "../components/HammerTracker/Gual";
import {
  getHammerTrackerData,
  getModifiersData,
  saveModiefiers,
} from "../store/actions/hammerTrackerAction";
import { useDispatch, useSelector } from "react-redux";
import { getPermissions } from "../store/actions/userpermissionsAction";
import { Button } from "reactstrap";
import HammerReportParse from "../components/HammerTracker/ReportParse";

const HammerTracker = () => {
  //-----------useSelectors -----------//
  const dispatch = useDispatch();
  const { Modifiers } = useSelector((state) => state.hammerTracker);
  const { user } = useSelector((state) => state.auth);
  const { permission } = useSelector((state) => state.userPermissions);

  //------------- useStates -----------//

  const [condition, setCondition] = useState("");
  const [activeTab, setActiveTab] = useState("Teutons");
  const [inputValues, setInputValues] = useState({});
  const [formChanged, setFormChanged] = useState(false);
  const [modalReport, setModalReport] = useState(false);
  const toggleCSV = () => setModalReport(!modalReport);

  //-------------Roles/Permissions ------------//

  const permissionData = permission?.hammerTracker;
  const role = user?.role;
  const functionalRole = user?.functionalRole;
  const functionalPermission =
    permission && permissionData && permissionData[functionalRole];

  //--------------Functions -------------//

  const handleInputChange = (event, id) => {
    const { value } = event.target;
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [id]: parseFloat(value),
    }));
    setFormChanged(true);
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (Modifiers?.id) {
      const documentId = Modifiers.id;
      dispatch(saveModiefiers(inputValues, documentId));
      setFormChanged(false);
    } else {
      dispatch(saveModiefiers(inputValues));
      setInputValues({});
      setFormChanged(false);
    }
  };

  //-------------- useEffects ---------------//

  useEffect(() => {
    if (Modifiers) {
      setInputValues(Modifiers);
    }
  }, [Modifiers]);

  useEffect(() => {
    if (permission && permissionData) {
      const rolePermission = permissionData[role];
      const functionalPermission = permissionData[functionalRole];
      if (
        functionalPermission !== undefined &&
        functionalPermission.trim() !== ""
      ) {
        setCondition(functionalPermission);
      } else if (rolePermission !== undefined && rolePermission.trim() !== "") {
        setCondition(rolePermission);
      }
    }
  }, [permission, permissionData, role, functionalRole]);

  useEffect(() => {
    dispatch(getHammerTrackerData());
    dispatch(getPermissions());
    dispatch(getModifiersData());
  }, []);

  return (
    <div className="px-5 container-fluid">
      {condition == "Locked" ? (
        <div
          className="text-center d-flex justify-content-center align-items-center "
          style={{ height: "70vh" }}
        >
          <h2>
            This page is not available for{" "}
            {functionalPermission !== undefined &&
            functionalPermission.trim() !== ""
              ? functionalRole
              : role}
          </h2>{" "}
        </div>
      ) : (
        <>
          <div className="d-flex justify-content-between align-items-center flex-wrap mt-3">
            <h2 className="text-center mb-4">Hammer Tracker</h2>
            <div className="mb-3">
              <button
                className="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal3"
                disabled={condition !== "Full Access"}
              >
                Modifiers
              </button>
              <Button
                className="text-white ms-1"
                color="info"
                type="button"
                disabled={condition === "Read Only"}
                onClick={toggleCSV}
              >
                Update
              </Button>
            </div>
          </div>
          <ul className="nav nav-tabs">
            <li className="nav-item">
              <button
                className={`nav-link ${
                  activeTab === "Teutons" ? "active" : ""
                }`}
                onClick={() => handleTabChange("Teutons")}
              >
                Teutons
              </button>
            </li>
            <li className="nav-item">
              <button
                className={`nav-link ${activeTab === "Romans" ? "active" : ""}`}
                onClick={() => handleTabChange("Romans")}
              >
                Romans
              </button>
            </li>
            <li className="nav-item">
              <button
                className={`nav-link ${activeTab === "Guals" ? "active" : ""}`}
                onClick={() => handleTabChange("Guals")}
              >
                Gauls
              </button>
            </li>
          </ul>
          <div className="tab-content">
            <div
              className={`tab-pane table-responsive fade ${
                activeTab === "Teutons" ? "show active" : ""
              }`}
            >
              <Teuton />
            </div>
            <div
              className={`tab-pane table-responsive fade ${
                activeTab === "Romans" ? "show active" : ""
              }`}
            >
              <Roman />
            </div>
            <div
              className={`tab-pane table-responsive fade ${
                activeTab === "Guals" ? "show active" : ""
              }`}
            >
              <Gual />
            </div>
          </div>

          {/* Modifiers Modal  */}

          <div
            class="modal fade"
            id="exampleModal3"
            tabindex="-2"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    Modifiers
                  </h5>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body">
                  <div className="row mb-4">
                    <h6>Trainer Artefact Active</h6>
                    <div className="col">
                      <label
                        htmlFor="trainerArtefactYes"
                        className="form-label"
                      >
                        Yes
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="trainerArtefactYes"
                        value={inputValues.trainerArtefactYes || ""}
                        onChange={(event) =>
                          handleInputChange(event, "trainerArtefactYes")
                        }
                      />
                    </div>
                    <div className="col">
                      <label htmlFor="trainerArtefactNo" className="form-label">
                        No
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="trainerArtefactNo"
                        value={inputValues.trainerArtefactNo || ""}
                        onChange={(event) =>
                          handleInputChange(event, "trainerArtefactNo")
                        }
                      />
                    </div>
                  </div>
                  <div className="row mb-4">
                    <h6>Number of Oases</h6>
                    {[0, 1, 2, 3].map((num) => (
                      <div className="col" key={`oasesInput${num}`}>
                        <label
                          htmlFor={`oasesInput${num}`}
                          className="form-label"
                        >
                          {num}
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id={`oasesInput${num}`}
                          value={inputValues[`oasesInput${num}`] || ""}
                          onChange={(event) =>
                            handleInputChange(event, `oasesInput${num}`)
                          }
                        />
                      </div>
                    ))}
                  </div>
                  <div className="row mb-4">
                    <h6>Great Barracks</h6>
                    <div className="col">
                      <label htmlFor="greatBarracksYes" className="form-label">
                        Yes
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="greatBarracksYes"
                        value={inputValues.greatBarracksYes || ""}
                        onChange={(event) =>
                          handleInputChange(event, "greatBarracksYes")
                        }
                      />
                    </div>
                    <div className="col">
                      <label htmlFor="greatBarracksNo" className="form-label">
                        No
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="greatBarracksNo"
                        value={inputValues.greatBarracksNo || ""}
                        onChange={(event) =>
                          handleInputChange(event, "greatBarracksNo")
                        }
                      />
                    </div>
                  </div>
                  <div className="row mb-4">
                    <h6>Great Stable</h6>
                    <div className="col">
                      <label htmlFor="greatStableYes" className="form-label">
                        Yes
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="greatStableYes"
                        value={inputValues.greatStableYes || ""}
                        onChange={(event) =>
                          handleInputChange(event, "greatStableYes")
                        }
                      />
                    </div>
                    <div className="col">
                      <label htmlFor="greatStableNo" className="form-label">
                        No
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="greatStableNo"
                        value={inputValues.greatStableNo || ""}
                        onChange={(event) =>
                          handleInputChange(event, "greatStableNo")
                        }
                      />
                    </div>
                  </div>
                  <div className="row mb-4">
                    <h6>First 3 Settled</h6>
                    <div className="col">
                      <label htmlFor="first3SettledYes" className="form-label">
                        Yes
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="first3SettledYes"
                        value={inputValues.first3SettledYes || ""}
                        onChange={(event) =>
                          handleInputChange(event, "first3SettledYes")
                        }
                      />
                    </div>
                    <div className="col">
                      <label htmlFor="first3SettledNo" className="form-label">
                        No
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="first3SettledNo"
                        value={inputValues.first3SettledNo || ""}
                        onChange={(event) =>
                          handleInputChange(event, "first3SettledNo")
                        }
                      />
                    </div>
                  </div>
                  <div className="row mb-4">
                    <h6>Artefact Threat Level</h6>
                    <div className="col">
                      <label htmlFor="artefactNone" className="form-label">
                        None
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="artefactNone"
                        value={inputValues.artefactNone || ""}
                        onChange={(event) =>
                          handleInputChange(event, "artefactNone")
                        }
                      />
                    </div>
                    <div className="col">
                      <label htmlFor="artefactLow" className="form-label">
                        Low
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="artefactLow"
                        value={inputValues.artefactLow || ""}
                        onChange={(event) =>
                          handleInputChange(event, "artefactLow")
                        }
                      />
                    </div>
                    <div className="col">
                      <label htmlFor="artefactMedium" className="form-label">
                        Medium
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="artefactMedium"
                        value={inputValues.artefactMedium || ""}
                        onChange={(event) =>
                          handleInputChange(event, "artefactMedium")
                        }
                      />
                    </div>
                    <div className="col">
                      <label htmlFor="artefactHigh" className="form-label">
                        High
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="artefactHigh"
                        value={inputValues.artefactHigh || ""}
                        onChange={(event) =>
                          handleInputChange(event, "artefactHigh")
                        }
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <h6>Exponent Factor</h6>
                    <div className="col">
                      <label htmlFor="day1" className="form-label">
                        Day 1:
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="day1"
                        value={inputValues.day1 || ""}
                        onChange={(event) => handleInputChange(event, "day1")}
                      />
                    </div>
                    <div className="col">
                      <label htmlFor="day30" className="form-label">
                        Day 30:
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="day30"
                        value={inputValues.day30 || ""}
                        onChange={(event) => handleInputChange(event, "day30")}
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col">
                      <label htmlFor="day60" className="form-label">
                        Day 60:
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="day60"
                        value={inputValues.day60 || ""}
                        onChange={(event) => handleInputChange(event, "day60")}
                      />
                    </div>
                    <div className="col">
                      <label htmlFor="day90" className="form-label">
                        Day 90:
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="day90"
                        value={inputValues.day90 || ""}
                        onChange={(event) => handleInputChange(event, "day90")}
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col">
                      <label htmlFor="day120" className="form-label">
                        Day 120:
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="day120"
                        value={inputValues.day120 || ""}
                        onChange={(event) => handleInputChange(event, "day120")}
                      />
                    </div>
                    <div className="col">
                      <label htmlFor="day150" className="form-label">
                        Day 150:
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="day150"
                        value={inputValues.day150 || ""}
                        onChange={(event) => handleInputChange(event, "day150")}
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col">
                      <label htmlFor="day180" className="form-label">
                        Day 180:
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="day180"
                        value={inputValues.day180 || ""}
                        onChange={(event) => handleInputChange(event, "day180")}
                      />
                    </div>
                    <div className="col">
                      <label htmlFor="day210" className="form-label">
                        Day 210:
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="day210"
                        value={inputValues.day210 || ""}
                        onChange={(event) => handleInputChange(event, "day210")}
                      />
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    className="btn btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal3"
                    onClick={handleSubmit}
                    disabled={!formChanged}
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <HammerReportParse toggle={toggleCSV} modal={modalReport} />
    </div>
  );
};

export default HammerTracker;
