import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFilteredData } from "../store/actions/playroasterAction";
import "../assets/css/AllianceBonus.css";
import moment from "moment-timezone";
import {
  addDonation,
  addGameAccountName,
  addWebhook,
  completeGoal,
  createNewGoal,
  getGoalData,
} from "../store/actions/allianceBonusAction";
import resource from "../assets/Images/troopGlossaryIcons/Total_Resources_Cost_Icon.png";
import { getPermissions } from "../store/actions/userpermissionsAction";
import { getWebhooks } from "../store/actions/resourcePushAction";
import { getRPHData } from "../store/actions/playerTroopAction";
import { getServerSettingsData } from "../store/actions/serverSettingsAction";
import DiscordWebhookModal from "../components/ResourcePush/DiscordWebhookModal";
import DonateResourcesModal from "../components/AllianceBonus/DonateResourcesModal";
import StartGoalModal from "../components/AllianceBonus/StartGoalModal";
import { getUsers } from "../store/actions/dashboardAction";
import { toast } from "react-toastify";

const AllianceBonus = () => {
  const dispatch = useDispatch();
  const { users } = useSelector((state) => state.dashboard);
  const { permission } = useSelector((state) => state.userPermissions);
  const { serverSettingsData } = useSelector((state) => state.serverSettings);
  const { user } = useSelector((state) => state.auth);
  const { goalData, loading } = useSelector((state) => state.allianceBonus);
  const { RPH } = useSelector((state) => state.playerTroop);
  const { webHooks } = useSelector((state) => state.resourcePush);
  const [filterData, setFilterData] = useState([]);
  const [incompleteGoal, setIncompleteGoal] = useState([]);
  const [sortColumn, setSortColumn] = useState(null);
  const [selectedFilterValues, setSelectedFilterValues] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [condition, setCondition] = useState("");
  const [webhookURL, setWebhookURL] = useState("");
  const [webhookMessage, setWebhookMessage] = useState("");
  const [isModified, setIsModified] = useState(false);
  const [timezone, setTimeZone] = useState();
  const [webhookModalOpen, setWebhookModalOpen] = useState(false);
  const [goalModalOpen, setGoalModalOpen] = useState(false);
  const [donateModalOpen, setDonateModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    goalSelection: "",
    resourceNeeded: 0,
    endDate: "",
    isComplete: false,
    resources: [],
  });
  const [donateResources, setDonateResources] = useState({
    donatedResources: 0,
    player: "",
  });
  const toggleDonateModal = () => setDonateModalOpen(!donateModalOpen);
  const toggleWebhookModal = () => setWebhookModalOpen(!webhookModalOpen);
  const toggleGoalModal = () => setGoalModalOpen(!goalModalOpen);

  //---------------- Roles/Permissions --------------//

  const permissionData = permission?.allianceBonus;
  const role = user?.role;
  const functionalRole = user?.functionalRole;
  const functionalPermission =
    permission && permissionData && permissionData[functionalRole];

  //---------------Functions --------------------//

  const handleChange = (e) => {
    const { id, value } = e.target;
    const numericValue = /^\d+$/.test(value) ? parseInt(value) : value;

    setFormData({
      ...formData,
      [id]: numericValue,
    });
  };

  const handleDonateResources = (e) => {
    const { id, value } = e.target;
    const numericValue = /^\d+$/.test(value) ? parseInt(value) : value;

    setDonateResources({
      ...donateResources,
      [id]: numericValue,
    });
  };

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      await dispatch(createNewGoal(formData));

      const messageContent = webhookMessage;

      const payload = {
        content: messageContent,
      };

      await fetch(webhookURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      setFormData({
        goalSelection: "",
        resourceNeeded: 0,
        endDate: "",
        isComplete: false,
        resources: [],
      });
      dispatch(getGoalData());
      setIsSubmitting(false);
      toggleGoalModal();
    } catch (error) {
      toast.error("Failed to create new Goal");
    }
  };

  const handleDiscordSave = () => {
    dispatch(addWebhook({ webhookURL, webhookMessage }));
    setIsModified(false);
    toggleWebhookModal();
  };

  const submitDonation = async () => {
    try {
      setIsSubmitting(true);
      await dispatch(addDonation(donateResources));
      setDonateResources({
        donatedResources: 0,
        player: user && user?.gameAccountName,
      });
      dispatch(getGoalData());
      toggleDonateModal();
      setIsSubmitting(false);
    } catch (error) {
      toast.error("Failed to submit donation");
    }
  };

  function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();

    if (month < 10) {
      month = "0" + month;
    }
    if (day < 10) {
      day = "0" + day;
    }

    return `${year}-${month}-${day}`;
  }

  function formatDate(dateString) {
    if (!dateString) return "";
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
  }

  //------------ Field Names ---------------- //

  const fieldNames = [
    { key: "player", label: "Player" },
    {
      key: "resourcesHour",
      label: (
        <span className="bg-info">
          <img src={resource} width={25} className="mx-1" />
          <span>/ hour</span>
        </span>
      ),
    },
    {
      key: "needToDonate",
      label: (
        <span className="bg-info">
          <img src={resource} width={25} className="mx-1" />
          <span>Need to Donate</span>
        </span>
      ),
    },
    {
      key: "donatedResources",
      label: (
        <span className="bg-info">
          <span>Total</span>
          <img src={resource} width={25} className="mx-1" />
          <span>Donated</span>
        </span>
      ),
    },
    { key: "allianceRanking", label: "Alliance Ranking" },
  ];

  //--------- Functions to handle sorting-------- //

  const handleSort = (column, option) => {
    let sortedUsers = [...incompleteGoal];

    if (option === "asc") {
      sortedUsers.forEach((user) => {
        user.resources.sort((a, b) => compareValues(a[column], b[column]));
      });
    } else if (option === "desc") {
      sortedUsers.forEach((user) => {
        user.resources.sort((a, b) => compareValues(b[column], a[column]));
      });
    }

    setSortColumn(column);
    setIncompleteGoal(sortedUsers);
  };

  const compareValues = (valueA, valueB) => {
    if (valueA === valueB) {
      return 0;
    }
    return valueA < valueB ? -1 : 1;
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleApplyFilter = (columnName) => {
    const selectedValues = selectedFilterValues[columnName];

    if (!selectedValues || selectedValues.length === 0) {
      return;
    }

    const filteredRows = incompleteGoal.map((user) => ({
      ...user,
      resources: user.resources.filter((resource) => {
        return (
          resource &&
          resource[columnName] !== undefined &&
          selectedValues.includes(String(resource[columnName]))
        );
      }),
    }));

    setIncompleteGoal(filteredRows);
  };

  const uniqueValuesForColumn = (columnName) => {
    const uniqueValues = new Set();

    if (incompleteGoal && incompleteGoal.length > 0) {
      incompleteGoal.forEach((user) => {
        user.resources.forEach((resource) => {
          if (resource && resource[columnName] !== undefined) {
            const value = String(resource[columnName]);
            uniqueValues.add(value);
          }
        });
      });
    }

    return Array.from(uniqueValues);
  };

  const clearFilters = () => {
    setSortColumn(null);
    if (filterData.length > 0) {
      const incompleteGoals = goalData.filter((goal) => !goal.isComplete);
      if (incompleteGoals.length > 0) {
        let allianceBonus = calculateDonation(incompleteGoals[0]);
        setIncompleteGoal([allianceBonus]);
      }
    }
    setSelectedFilterValues({});
    setSearchTerm("");
  };

  //-------- Calculate resources to donate, alliance Ranking, Percentage donated---------//

  const calculateDonation = (data) => {
    if (!data || !data.resources) {
      return null;
    }

    console.log(data);
    let resources = [];
    let totalDonatedResources = 0;
    let percentageDonated = 0;
    const numberOfPlayers = data.resources.length;
    const resourceNeeded = data?.resourceNeeded;

    if (RPH) {
      RPH.forEach((rph) => {
        const playerName = rph.player;
        const lastResourcePerHour =
          rph.ResourcePerHour[rph.ResourcePerHour.length - 1].rph;

        data.resources.forEach((resource) => {
          if (resource.player === playerName) {
            resource.resourcesHour = lastResourcePerHour;
          }
        });
      });
    }

    const playersWithoutResourcesHour = data.resources.filter(
      (resource) => !resource.resourcesHour
    );
    const playersWithResourcesHour = data.resources.filter(
      (resource) => resource.resourcesHour
    );

    playersWithoutResourcesHour.forEach((resource) => {
      const needToDonate = Math.floor(resourceNeeded / numberOfPlayers);
      resource.needToDonate = needToDonate;
      resources.push({ ...resource });

      totalDonatedResources += resource.donatedResources || 0;
    });

    const remainingResourceNeeded =
      resourceNeeded -
      playersWithoutResourcesHour.reduce(
        (total, resource) => total + resource.needToDonate,
        0
      );

    const resourcePerHourSum = playersWithResourcesHour.reduce(
      (total, resource) => {
        return total + (resource.resourcesHour || 0);
      },
      0
    );

    const average =
      resourcePerHourSum !== 0
        ? remainingResourceNeeded / resourcePerHourSum
        : 0;

    playersWithResourcesHour.forEach((resource) => {
      let needToDonate = 0;
      if (resource.resourcesHour) {
        needToDonate = Math.floor(average * resource.resourcesHour);
      }
      resource.needToDonate = needToDonate;
      resources.push({ ...resource });

      totalDonatedResources += resource.donatedResources || 0;
    });

    percentageDonated = (totalDonatedResources / resourceNeeded) * 100;

    resources.sort((a, b) => b.donatedResources - a.donatedResources);

    resources.forEach((resource, index) => {
      resource["allianceRanking"] = index + 1;
    });

    data.resources = resources;
    data.totalDonatedResources = totalDonatedResources;
    data.percentageDonated = percentageDonated;
    return data;
  };

  //-------------UseEffects ---------------//

  useEffect(() => {
    if (users && users.length > 0) {
      const data = users;
      const uniqueData = [];
      const seenNames = new Set();
      data.forEach((item) => {
        if (!seenNames.has(item.gameAccountName)) {
          seenNames.add(item.gameAccountName);
          uniqueData.push(item);
        }
      });
      setFilterData(uniqueData);
    }
  }, [users]);

  useEffect(() => {
    const fetchAndDispatch = async () => {
      if (filterData.length > 0) {
        try {
          const gameAccountNames = filterData.map(
            (item) => item.gameAccountName
          );
          for (const gameAccountName of gameAccountNames) {
            await dispatch(addGameAccountName(gameAccountName));
          }
        } catch (error) {
          console.error("Failed to fetch and dispatch:", error);
        }
      }
    };

    fetchAndDispatch();
  }, [filterData]);

  useEffect(() => {
    if (user) {
      setDonateResources((prevState) => ({
        ...prevState,
        player: user.gameAccountName || "",
      }));
    }
  }, [user]);

  useEffect(() => {
    if (filterData.length > 0) {
      const incompleteGoals = goalData.filter((goal) => !goal.isComplete);
      if (incompleteGoals.length > 0) {
        let allianceBonus = calculateDonation(incompleteGoals[0]);
        setIncompleteGoal([allianceBonus]);

        const timeZone = timezone;

        const currentDate = moment.tz(timeZone);
        const endDate = moment.tz(allianceBonus.endDate, timeZone);

        if (
          allianceBonus.percentageDonated >= 100 ||
          endDate.isBefore(currentDate)
        ) {
          dispatch(completeGoal(true));
          setIncompleteGoal([]);
        }
      }
    }
  }, [goalData, filterData]);

  useEffect(() => {
    if (permission && permissionData) {
      const rolePermission = permissionData[role];
      const functionalPermission = permissionData[functionalRole];
      if (
        functionalPermission !== undefined &&
        functionalPermission.trim() !== ""
      ) {
        setCondition(functionalPermission);
      } else if (rolePermission !== undefined && rolePermission.trim() !== "") {
        setCondition(rolePermission);
      }
    }
  }, [permission, permissionData, role, functionalRole]);

  useEffect(() => {
    if (webHooks) {
      const allianceBonusWebhook = webHooks.find(
        (hook) => hook.id === "allianceBonus"
      );
      if (allianceBonusWebhook) {
        setWebhookURL(allianceBonusWebhook?.webhookURL);
        setWebhookMessage(allianceBonusWebhook?.webhookMessage);
      }
    }
  }, [webHooks]);

  useEffect(() => {
    if (serverSettingsData && serverSettingsData.length > 0) {
      const data = serverSettingsData[0];
      setTimeZone(data.timezone);
    }
  }, [serverSettingsData]);

  useEffect(() => {
    dispatch(getFilteredData());
    dispatch(getGoalData());
    dispatch(getPermissions());
    dispatch(getWebhooks());
    dispatch(getRPHData());
    dispatch(getServerSettingsData());
    dispatch(getUsers());
  }, []);

  return (
    <>
      {condition == "Locked" ? (
        <div
          className="text-center d-flex justify-content-center align-items-center "
          style={{ height: "70vh" }}
        >
          <h2>
            This page is not available for{" "}
            {functionalPermission !== undefined &&
            functionalPermission.trim() !== ""
              ? functionalRole
              : role}
          </h2>
        </div>
      ) : (
        <div className="mt-3 container-fluid px-4">
          <h2 className="text-center mb-4">Alliance Bonus</h2>
          <div className="d-flex justify-content-between flex-wrap mb-1">
            <div className="d-flex align-content-center justify-content-center align-items-center">
              <button
                className="btn btn-info py-2 text-white"
                onClick={toggleDonateModal}
              >
                Donate
              </button>
              {condition === "Full Access" && (
                <button
                  className="btn btn-info py-2 text-white ms-2"
                  onClick={toggleWebhookModal}
                  disabled={!condition === "Full Access"}
                >
                  Webhook
                </button>
              )}
            </div>
            <div className="align-items-center gap-2 mb-3">
              <h6 className="text-center">
                Complete By: {formatDate(incompleteGoal[0]?.endDate)}
              </h6>
              <div className="progress-bar mt-2">
                <div
                  className="bar-graph"
                  style={{
                    position: "relative",
                    width: `${incompleteGoal[0]?.percentageDonated}%`,
                    height: "100%",
                    backgroundColor: (() => {
                      const percentage =
                        incompleteGoal[0]?.percentageDonated || 0;
                      return percentage <= 0
                        ? "transparent"
                        : percentage < 30
                        ? "green"
                        : percentage < 60
                        ? "yellow"
                        : "red";
                    })(),
                  }}
                ></div>
              </div>
            </div>
            <div className="d-flex align-content-center justify-content-center align-items-center">
              {condition !== "Read Only" && (
                <button
                  className="btn btn-info py-2 mb-1 text-white"
                  onClick={toggleGoalModal}
                  disabled={
                    incompleteGoal[0]?.isComplete === false ||
                    loading ||
                    condition === "Read Only"
                  }
                >
                  Start New Goal
                </button>
              )}
            </div>
          </div>
          <p className="text-center mt-1 text-capitalize mb-1 fw-bold fs-4 p-3 rounded">
            <span className="text-secondary">Goal:</span>
            <span className="text-primary ms-2">
              {incompleteGoal[0]?.goalSelection || "No Goal Selected"}
            </span>
          </p>
          <div className="table-responsive">
            <table className="table">
              <thead>
                {fieldNames.map((fieldName) => {
                  const isResourcesHour = fieldName.key === "resourcesHour";
                  const hideFieldReadOnly =
                    condition === "Read Only" || condition === "Limited Edit";
                  const hideFieldOthers = condition === "Read Only";
                  const hiddenFields = ["donatedResources", "allianceRanking"];
                  if (
                    (isResourcesHour && hideFieldReadOnly) ||
                    (hiddenFields.includes(fieldName.key) && hideFieldOthers)
                  ) {
                    return null;
                  }
                  return (
                    <th key={fieldName.key} className="p-3 text-center bg-info">
                      {fieldName.label}
                      {!hideFieldReadOnly && (
                        <>
                          <i
                            style={{
                              cursor: "pointer",
                              color: sortColumn === fieldName.key ? "red" : "",
                              border:
                                sortColumn === fieldName.key
                                  ? "2px solid red"
                                  : "none",
                            }}
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            className={"bi bi-filter ms-1 bg-info"}
                            disabled={condition === "Read Only"}
                          ></i>
                          <ul
                            style={{ cursor: "pointer" }}
                            className="dropdown-menu"
                          >
                            <li
                              role="button"
                              className="dropdown-item cursor-pointer "
                              onClick={() => handleSort(fieldName.key, "desc")}
                            >
                              Sort By Highest Values
                            </li>
                            <li
                              role="button"
                              className="dropdown-item cursor-pointer "
                              onClick={() => handleSort(fieldName.key, "asc")}
                            >
                              Sort By Lowest Values
                            </li>
                            <li
                              role="button"
                              className="dropdown-item cursor-pointer "
                              onClick={() => clearFilters()}
                            >
                              Clear filter
                            </li>
                            <li
                              style={{ cursor: "pointer" }}
                              className="dropdown-item "
                            >
                              Search
                              <input
                                className="mx-2"
                                placeholder="Search"
                                style={{ width: "70%" }}
                                type="text"
                                value={searchTerm}
                                onChange={handleSearchChange}
                              />
                            </li>
                            <div>
                              <p>Selected Values:</p>
                              {selectedFilterValues[fieldName.key]?.map(
                                (value, i) => (
                                  <span key={i}>{value},</span>
                                )
                              )}
                            </div>
                            <select
                              style={{ width: "100%" }}
                              onChange={(e) => {
                                const selectedValues = Array.from(
                                  e.target.selectedOptions,
                                  (option) => option.value
                                );
                                setSelectedFilterValues((prevValues) => {
                                  const currentValues =
                                    prevValues[fieldName.key] || [];
                                  const newValues = currentValues.includes(
                                    selectedValues[0]
                                  )
                                    ? currentValues.filter(
                                        (value) => value !== selectedValues[0]
                                      )
                                    : [...currentValues, ...selectedValues];
                                  return {
                                    ...prevValues,
                                    [fieldName.key]: newValues,
                                  };
                                });
                              }}
                              multiple
                            >
                              <option
                                value="selectAll"
                                onClick={() => {
                                  const allValues = uniqueValuesForColumn(
                                    fieldName.key
                                  ).filter((value) =>
                                    value
                                      .toLowerCase()
                                      .includes(searchTerm.toLowerCase())
                                  );
                                  setSelectedFilterValues((prevValues) => ({
                                    ...prevValues,
                                    [fieldName.key]: allValues,
                                  }));
                                }}
                              >
                                Select All
                              </option>
                              value && typeof value === 'string' ?
                              value.toLowerCase() : value
                              {uniqueValuesForColumn(fieldName.key)
                                ?.filter((value) =>
                                  value
                                    .toLowerCase()
                                    .includes(searchTerm?.toLowerCase())
                                )
                                .map((value, i) => (
                                  <option key={i} value={value}>
                                    {`${value} ${
                                      selectedFilterValues[
                                        fieldName.key
                                      ]?.includes(value)
                                        ? "✓"
                                        : ""
                                    }`}
                                  </option>
                                ))}
                            </select>
                            <div className="d-flex justify-content-end gap-2 mt-2">
                              <button
                                className="btn btn-primary"
                                disabled={condition === "Read Only"}
                                onClick={() => handleApplyFilter(fieldName.key)}
                              >
                                Apply Filter
                              </button>
                              <button
                                className="btn btn-danger"
                                disabled={condition === "Read Only"}
                                onClick={() => setSelectedFilterValues("")}
                              >
                                Cancel
                              </button>
                            </div>
                          </ul>
                        </>
                      )}
                    </th>
                  );
                })}
              </thead>
              {loading ? (
                <tbody>
                  <tr>
                    <td className="text-center" colSpan="12">
                      <div className="d-flex justify-content-center align-items-center">
                        <div className="spinner-border" role="status"></div>
                        <span className="visually-hidden ms-2">Loading...</span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              ) : (
                <tbody>
                  {incompleteGoal.map((data, index) =>
                    data?.resources
                      .filter((resource) =>
                        users.some(
                          (filterItem) =>
                            filterItem.gameAccountName === resource.player
                        )
                      )
                      .map((resource, resourceIndex) => (
                        <tr key={index + "-" + resourceIndex}>
                          <td className="text-center">{resource?.player}</td>
                          {(condition === "Full Access" ||
                            condition === "Full Edit") && (
                            <td className="text-center">
                              {resource?.resourcesHour
                                ? resource?.resourcesHour.toLocaleString()
                                : ""}
                            </td>
                          )}
                          <td className="text-center">
                            {resource?.needToDonate?.toLocaleString()}
                          </td>
                          {condition !== "Read Only" && (
                            <>
                              <td className="text-center">
                                {resource?.donatedResources
                                  ? resource?.donatedResources.toLocaleString()
                                  : ""}
                              </td>
                              <td className="text-center">
                                {resource?.allianceRanking}
                              </td>
                            </>
                          )}
                        </tr>
                      ))
                  )}
                </tbody>
              )}
            </table>
          </div>
          <StartGoalModal
            isOpen={goalModalOpen}
            toggle={toggleGoalModal}
            formData={formData}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            getCurrentDate={getCurrentDate}
            isSubmitting={isSubmitting}
          />
          <DonateResourcesModal
            isOpen={donateModalOpen}
            toggle={toggleDonateModal}
            donateResources={donateResources}
            handleDonateResources={handleDonateResources}
            submitDonation={submitDonation}
            isSubmitting={isSubmitting}
          />
          <DiscordWebhookModal
            isOpen={webhookModalOpen}
            toggle={toggleWebhookModal}
            webhookURL={webhookURL}
            setWebhookURL={setWebhookURL}
            webhookMessage={webhookMessage}
            setWebhookMessage={setWebhookMessage}
            handleDiscordSave={handleDiscordSave}
            isModified={isModified}
            setIsModified={setIsModified}
          />
        </div>
      )}
    </>
  );
};

export default AllianceBonus;
