import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
} from "reactstrap";
import Papa from "papaparse";
import { toast } from "react-toastify";
import moment from "moment-timezone";
import { useDispatch, useSelector } from "react-redux";
import { updateResourcesPerHour } from "../../store/actions/playerTroopAction";

const RPHReportParse = ({ modal, toggle }) => {
  const { user } = useSelector((state) => state.auth);
  const { serverSettingsData } = useSelector((state) => state.serverSettings);
  const dispatch = useDispatch();
  const [reportText, setReportText] = useState("");
  const [player, setPlayer] = useState();
  const [serverDay, setServerDay] = useState(0);

  const handleImport = () => {
    try {
      const parsedReport = Papa.parse(reportText);

      if (
        !parsedReport ||
        !parsedReport.data ||
        parsedReport.data.length === 0
      ) {
        toast.error("Failed to parse report data.");
      }
      const reportData = parsedReport.data;

      const sumArrivalIndex = reportData.findIndex((row) => {
        return Array.isArray(row) && row.some((cell) => /Sum/.test(cell));
      });
      const playerArrivalIndex = sumArrivalIndex + 1;

      const playerName = reportData[playerArrivalIndex][0];
      const cleanedStrings = reportData[sumArrivalIndex].map((str) =>
        str.replace(/,/g, "")
      );

      const concatenatedNumbers = cleanedStrings.reduce((acc, str) => {
        const numbers = str.match(/\d+/g);
        if (numbers) {
          acc += numbers.join(" ");
        }
        return acc;
      }, "");

      const firstFourNumbers = concatenatedNumbers
        .split(" ")
        .slice(0, 4)
        .map(Number);
      const sumFirstFourNumbers = firstFourNumbers.reduce(
        (acc, num) => acc + num,
        0
      );

      const result = sumFirstFourNumbers;

      if (sumArrivalIndex !== -1) {
        if (playerName === player) {
          const dispatchData = {
            rph: result,
            player: playerName,
            serverDay: serverDay,
          };
          dispatch(updateResourcesPerHour(dispatchData));
        } else {
          toast.error(`You can only update your RPH`);
        }
      } else {
        console.log("Required data not found in report data.");
      }
      toggle();
      setReportText("");
    } catch (error) {
      alert(error);
    }
  };

  useEffect(() => {
    if (serverSettingsData && serverSettingsData.length > 0) {
      const data = serverSettingsData[0];
      const timeZone = data?.timezone;
      const startDateString = data?.startDate;

      const startDate = moment.tz(startDateString, "YYYY-MM-DD", timeZone);

      const now = moment.tz(timeZone);

      let daysPassed = now
        .startOf("day")
        .diff(startDate.startOf("day"), "days");

      if (daysPassed < 0) {
        daysPassed = 0;
      } else {
        daysPassed += 1;
      }

      setServerDay(daysPassed);
    }
  }, [serverSettingsData]);

  useEffect(() => {
    if (user) {
      setPlayer(user?.gameAccountName);
    }
  }, [user]);

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Paste Report</ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <textarea
                rows="15"
                className="form-control"
                value={reportText}
                onChange={(e) => setReportText(e.target.value)}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            size="sm"
            onClick={() => {
              toggle();
              setReportText("");
            }}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            size="sm"
            onClick={handleImport}
            disabled={!reportText || reportText.length === 0}
          >
            Update
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default RPHReportParse;
