const initialState = {
  users: [],
  loading: false,
};

export default function dashboardReducer(state = initialState, action) {
  switch (action.type) {
    case "GET_USERS":
      return {
        ...state,
        users: action.payload,
      };
    case "APPROVE_USER_SUCCESS":
      const updatedUsers = state.users.map((user) =>
        user._id === action.payload.userId
          ? { ...user, role: action.payload.role }
          : user
      );
      return {
        ...state,
        users: updatedUsers,
      };
      case "ALL_DISCORD_IDs":
      return {
        ...state,
        discordIDs: action.payload,
      };
    case "IS_FETCHING_USERS":
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
}
