import axios from "axios";
import firebase from "firebase/app";
import "firebase/firestore";
import typesenseServer from "../../config/typesenseConfig";
import { toast } from "react-toastify";

export const fetchMapData = async (visibleArea) => {
  try {
    const response = await fetch(
      "https://us-central1-theramalliance.cloudfunctions.net/getMap",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(visibleArea),
      }
    );

    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    }

    const blob = await response.blob();
    return blob;
  } catch (error) {
    console.error("Error fetching map data:", error);
    throw error;
  }
};

export const fetchMapCoordinates =
  ({ x, y }) =>
  async () => {
    try {
      const db = firebase.firestore();

      const querySnapshot = await db
        .collection("mapCoordinates")
        .where("x", "==", x)
        .where("y", "==", y)
        .orderBy("updatedAt", "desc")
        .limit(1)
        .get();

      let coordinate = null;
      querySnapshot.forEach((doc) => {
        coordinate = { id: doc.id, ...doc.data() };
      });

      return coordinate;
    } catch (error) {
      console.error("Error fetching map coordinates:", error);
    }
  };

export const checkIfMapNeedsUpdate = async () => {
  try {
    const db = firebase.firestore();

    const serverMapSnapshot = await db
      .collection("serverMapInformation")
      .orderBy("mapCreatedAt", "desc")
      .limit(1)
      .get();

    if (serverMapSnapshot.empty) {
      console.log("No documents found in serverMapInformation.");
      return;
    }

    let serverName = null;
    let mapCreatedAt = null;

    serverMapSnapshot.forEach((doc) => {
      serverName = doc.data().serverName;
      mapCreatedAt = doc.data().mapCreatedAt.toDate();
    });

    const mapCoordinatesSnapshot = await db
      .collection("mapCoordinates")
      .where("serverName", "==", serverName)
      .orderBy("updatedAt", "desc")
      .limit(1)
      .get();

    let updatedAt = null;

    mapCoordinatesSnapshot.forEach((doc) => {
      updatedAt = doc.data().updatedAt.toDate();
    });

    if (updatedAt.getTime() <= mapCreatedAt.getTime()) {
      return null;
    } else {
      return "Map needs to be updated";
    }
  } catch (error) {
    console.error("Error checking if map needs update:", error);
  }
};

export const getPlayerVillages =
  ({ owner }) =>
  async () => {
    try {
      const db = firebase.firestore();

      const querySnapshot = await db
        .collection("mapCoordinates")
        .where("data.details.owner", "==", owner)
        .orderBy("updatedAt", "desc")
        .get();

      const uniqueVillagesMap = new Map();
      const villages = [];

      querySnapshot.forEach((doc) => {
        const data = doc.data();
        const details = data?.data.details || {};

        if (details.owner === owner) {
          const { x, y, updatedAt } = data;

          const updatedAtDate = updatedAt.toDate();
          const key = `${x},${y}`;

          if (!uniqueVillagesMap.has(key)) {
            uniqueVillagesMap.set(key, {
              id: doc.id,
              ...data,
              updatedAt: updatedAtDate,
            });
            villages.push({ id: doc.id, ...data, updatedAt: updatedAtDate });
          } else {
            const existingVillage = uniqueVillagesMap.get(key);
            if (updatedAtDate > existingVillage.updatedAt) {
              uniqueVillagesMap.set(key, {
                id: doc.id,
                ...data,
                updatedAt: updatedAtDate,
              });
              const index = villages.findIndex(
                (v) => v.id === existingVillage.id
              );
              villages[index] = {
                id: doc.id,
                ...data,
                updatedAt: updatedAtDate,
              };
            }
          }
        }
      });

      return villages;
    } catch (error) {
      console.error("Error fetching villages:", error);
    }
  };

export const getAllianceVillages = (alliance, date) => async () => {
  try {
    const db = firebase.firestore();

    const querySnapshot = await db
      .collection("timeseriesMapInformation")
      .where("allianceTag", "==", alliance)
      .where("currentDate", "==", date)
      .get();

    const villages = [];
    querySnapshot.forEach((doc) => {
      villages.push({ id: doc.id, ...doc.data() });
    });

    return villages;
  } catch (error) {
    console.error("Error fetching map coordinates:", error);
    return [];
  }
};

export const getPlayersVillages = (playerName, date) => async () => {
  try {
    const db = firebase.firestore();

    const querySnapshot = await db
      .collection("timeseriesMapInformation")
      .where("playerName", "==", playerName)
      .where("currentDate", "==", date)
      .get();

    const villages = [];
    querySnapshot.forEach((doc) => {
      villages.push({ id: doc.id, ...doc.data() });
    });

    return villages;
  } catch (error) {
    console.error("Error fetching Villages:", error);
    return [];
  }
};

export const fetchMapVillagesData = () => () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(
        `https://${typesenseServer.nodes[0].host}:${typesenseServer.nodes[0].port}/collections/timeseriesMapInformation/documents/search`,
        {
          params: {
            q: "Test",
            // query_by: "allianceTag",
            query_by: "playerName",
            filter_by: `currentDate:>${1738022100}`,
            per_page: 250,
          },
          headers: {
            "X-TYPESENSE-API-KEY": typesenseServer.apiKey,
            "Content-Type": "application/json",
          },
        }
      );
      resolve(response.data.hits);
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
};

export const fetchAlliances =
  ({ searchQuery = "", currentDate, currentPage = 1, hitsPerPage = 10 }) =>
  (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.get(
          `https://${typesenseServer.nodes[0].host}:${typesenseServer.nodes[0].port}/collections/timeseriesMapInformation/documents/search`,
          {
            params: {
              q: searchQuery || "*",
              query_by: "allianceTag",
              filter_by: `currentDate:>${currentDate}`,
              page: currentPage,
              per_page: hitsPerPage,
              group_by: "allianceTag",
              group_limit: 1,
            },
            headers: {
              "X-TYPESENSE-API-KEY": typesenseServer.apiKey,
              "Content-Type": "application/json",
            },
          }
        );
        dispatch({
          type: "FETCH_ALLIANCES_SUCCESS",
          payload: response.data,
        });
        resolve(response.data);
      } catch (error) {
        console.error(error);
        reject(error);
      }
    });
  };

export const fetchPlayers =
  ({
    searchQuery = "",
    selectedAlliances = [],
    currentDate,
    currentPage = 1,
    hitsPerPage = 10,
  }) =>
  (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        const allianceFilter = selectedAlliances.length
          ? selectedAlliances
              .map((alliance) => `allianceID:${alliance.id}`)
              .join(" || ")
          : "";

        const response = await axios.get(
          `https://${typesenseServer.nodes[0].host}:${typesenseServer.nodes[0].port}/collections/timeseriesMapInformation/documents/search`,
          {
            params: {
              q: searchQuery || "*",
              query_by: "playerName",
              filter_by: `currentDate:>${currentDate}${
                allianceFilter ? ` && (${allianceFilter})` : ""
              }`,
              page: currentPage,
              per_page: hitsPerPage,
              group_by: "playerName",
              group_limit: 1,
            },
            headers: {
              "X-TYPESENSE-API-KEY": typesenseServer.apiKey,
              "Content-Type": "application/json",
            },
          }
        );
        dispatch({
          type: "FETCH_PLAYERS_SUCCESS",
          payload: response.data,
        });
        resolve(response.data);
      } catch (error) {
        console.error(error);
        reject(error);
      }
    });
  };

export const fetchVillages =
  ({
    searchQuery = "",
    selectedAlliances = [],
    selectedPlayers = [],
    currentDate,
    currentPage = 1,
    hitsPerPage = 10,
  }) =>
  (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        const allianceFilter = selectedAlliances?.length
          ? selectedAlliances
              .map((alliance) => `allianceID:${alliance.id}`)
              .join(" || ")
          : "";
        const playerFilter = selectedPlayers?.length
          ? selectedPlayers
              .map((player) => `playerID:${player.id}`)
              .join(" || ")
          : "";

        const response = await axios.get(
          `https://${typesenseServer.nodes[0].host}:${typesenseServer.nodes[0].port}/collections/timeseriesMapInformation/documents/search`,
          {
            params: {
              q: searchQuery || "*",
              query_by: "villageName",
              filter_by: `currentDate:>${currentDate}${
                allianceFilter ? ` && (${allianceFilter})` : ""
              }${playerFilter ? ` && (${playerFilter})` : ""}`,
              page: currentPage,
              per_page: hitsPerPage,
              group_by: "villageName",
              group_limit: 1,
            },
            headers: {
              "X-TYPESENSE-API-KEY": typesenseServer.apiKey,
              "Content-Type": "application/json",
            },
          }
        );
        dispatch({
          type: "FETCH_VILLAGES_SUCCESS",
          payload: response.data,
        });
        resolve(response.data);
      } catch (error) {
        console.error(error);
        reject(error);
      }
    });
  };

export const fetchFilteredVillages =
  ({
    searchQuery = "",
    selectedAlliances = [],
    selectedPlayers = [],
    selectedVillages = [],
    currentDate,
    hitsPerPage = 250,
  }) =>
  async (dispatch) => {
    try {
      let allHits = [];
      let currentPage = 1;
      let totalPages = 1;

      do {
        const allianceFilter = selectedAlliances?.length
          ? selectedAlliances
              .map((alliance) => `allianceID:${alliance.id}`)
              .join(" || ")
          : "";
        const playerFilter = selectedPlayers?.length
          ? selectedPlayers
              .map((player) => `playerID:${player.id}`)
              .join(" || ")
          : "";
        const villageFilter = selectedVillages?.length
          ? selectedVillages
              .map((village) => `villageID:${village.id}`)
              .join(" || ")
          : "";

        const response = await axios.get(
          `https://${typesenseServer.nodes[0].host}:${typesenseServer.nodes[0].port}/collections/timeseriesMapInformation/documents/search`,
          {
            params: {
              q: searchQuery || "*",
              query_by: "villageName",
              filter_by: `currentDate:>${currentDate}${
                allianceFilter ? ` && (${allianceFilter})` : ""
              }${playerFilter ? ` && (${playerFilter})` : ""}${
                villageFilter ? ` && (${villageFilter})` : ""
              }`,
              page: currentPage,
              per_page: hitsPerPage,
              group_by: "villageName",
              group_limit: 1,
            },
            headers: {
              "X-TYPESENSE-API-KEY": typesenseServer.apiKey,
              "Content-Type": "application/json",
            },
          }
        );

        allHits = [...allHits, ...response.data.grouped_hits];

        totalPages = Math.ceil(response.data.found / hitsPerPage);
        currentPage++;
      } while (currentPage <= totalPages);

      dispatch({
        type: "FETCH_FILTERED_VILLAGES_SUCCESS",
        payload: allHits,
      });
    } catch (error) {
      console.error(error);
    }
  };

export const fetchVillagesByPlayerName =
  ({ playerName, currentDate, hitsPerPage = 250 }) =>
  async (dispatch) => {
    try {
      let allHits = [];
      let currentPage = 1;
      let totalPages = 1;

      do {
        const response = await axios.get(
          `https://${typesenseServer.nodes[0].host}:${typesenseServer.nodes[0].port}/collections/timeseriesMapInformation/documents/search`,
          {
            params: {
              q: playerName || "*",
              query_by: "playerName",
              filter_by: `currentDate:>${currentDate}`,
              page: currentPage,
              per_page: hitsPerPage,
              group_by: "villageName",
              group_limit: 1,
            },
            headers: {
              "X-TYPESENSE-API-KEY": typesenseServer.apiKey,
              "Content-Type": "application/json",
            },
          }
        );

        allHits = [...allHits, ...response.data.grouped_hits];

        totalPages = Math.ceil(response.data.found / hitsPerPage);
        currentPage++;
      } while (currentPage <= totalPages);

      console.log("allHits: ", allHits);
      dispatch({
        type: "FETCH_VILLAGES_BY_PLAYER_SUCCESS",
        payload: allHits,
      });
    } catch (error) {
      console.error(error);
    }
  };
