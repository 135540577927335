import { toast } from "react-toastify";
import firebase from "../../config/firebase";

export const fetchBonnieVillages = () => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const querySnapshot = await firebase
        .firestore()
        .collection("bonnieVillages")
        .orderBy("createdAt", "desc")
        .get();
      const bonnieVillages = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      dispatch({
        type: "FETCH_BONNIE_VILLAGES_SUCCESS",
        payload: bonnieVillages,
      });
      resolve(bonnieVillages);
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
};

export const addBonnieVillage =
  ({ uid, payload }) =>
  (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        const querySnapshot = await firebase
          .firestore()
          .collection("bonnieVillages")
          .where("coordinates", "==", payload?.coordinates)
          .get();
        if (!querySnapshot.empty) {
          throw new Error(
            "Bonnie Village already present at the same coordinates"
          );
        }
        const favDocRef = firebase
          .firestore()
          .collection("bonnieVillages")
          .doc();
        const bonnieVillageData = {
          id: favDocRef.id,
          uid,
          ...payload,
          createdAt: firebase.firestore.Timestamp.now(),
        };
        await favDocRef.set(bonnieVillageData);
        toast.success("Bonnie Village added successfully!");
        dispatch({
          type: "ADD_BONNIE_VILLAGE_SUCCESS",
          payload: bonnieVillageData,
        });
        resolve(bonnieVillageData);
      } catch (error) {
        console.error(error);
        toast.error(error.message || "Unable to add bonnie village");
        reject(error);
      }
    });
  };

export const editBonnieVillage =
  ({ id, payload }) =>
  (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        const querySnapshot = await firebase
          .firestore()
          .collection("bonnieVillages")
          .where("coordinates", "==", payload?.coordinates)
          .get();
        if (!querySnapshot.empty) {
          throw new Error(
            "Bonnie Village already present at the same coordinates"
          );
        }
        const bonnieVillageData = {
          ...payload,
        };
        await firebase
          .firestore()
          .collection("bonnieVillages")
          .doc(id)
          .update(bonnieVillageData);
        toast.success("Bonnie Village updated successfully!");
        dispatch({
          type: "EDIT_BONNIE_VILLAGE_SUCCESS",
          payload: { id, bonnieVillage: bonnieVillageData },
        });
        resolve(bonnieVillageData);
      } catch (error) {
        console.error(error);
        toast.error(error.message || "Unable to update bonnie village");
        reject(error);
      }
    });
  };

export const deleteBonnieVillage =
  ({ id }) =>
  (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        await firebase
          .firestore()
          .collection("bonnieVillages")
          .doc(id)
          .delete();
        dispatch({
          type: "DELETE_BONNIE_VILLAGE_SUCCESS",
          payload: id,
        });
        toast.success("Bonnie Village deleted successfully!");
        resolve(id);
      } catch (error) {
        console.error(error);
        toast.error(error.message || "Unable to delete bonnie village");
        reject(error);
      }
    });
  };
