import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchVillages } from "../../../store/actions/mapAction";
import {
  Button,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import DebouncedSearch from "../../shared/DebouncedSearch";
import moment from "moment-timezone";

const VillagesFilter = ({
  isOpen,
  toggle,
  timezone = "Europe/London",
  activeItems,
  setActiveItems,
  handleActiveItems,
  setSelectedVillages,
  selectedAlliances,
  selectedPlayers,
}) => {
  const dispatch = useDispatch();
  const { villages } = useSelector((state) => state.map);

  const [debouncedSearch, setDebouncedSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [isFetching, setIsFetching] = useState(false);
  const [hitsPerPage] = useState(10);

  const todayUnix = moment.tz(timezone).startOf("day").unix();

  const handleSearch = (debouncedValue) => {
    setDebouncedSearch(debouncedValue);
  };

  const clearFilter = () => {
    setDebouncedSearch("");
    setSelectedVillages([]);
    setActiveItems([]);
  };

  useEffect(() => {
    setIsFetching(true);
    dispatch(
      fetchVillages({
        searchQuery: debouncedSearch,
        selectedAlliances,
        selectedPlayers,
        currentDate: todayUnix,
        currentPage,
        hitsPerPage,
      })
    ).then(() => setIsFetching(false));
  }, [debouncedSearch, hitsPerPage, currentPage]);

  useEffect(() => {
    setCurrentPage(0);
  }, [debouncedSearch]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>Filter Villages</ModalHeader>
      <ModalBody style={{ maxHeight: "400px", overflowY: "auto" }}>
        <DebouncedSearch
          placeholder="Search village..."
          onSearch={handleSearch}
        />
        <div className="py-4">
          {isFetching ? (
            <div className="text-center">
              <Spinner color="primary" />
            </div>
          ) : villages?.grouped_hits?.length ? (
            <ListGroup>
              {villages?.grouped_hits?.map((village, index) => {
                const { villageID, villageName } = village?.hits[0]?.document;
                return (
                  <ListGroupItem
                    key={index}
                    onClick={() =>
                      handleActiveItems({
                        id: villageID,
                        name: villageName,
                      })
                    }
                    active={activeItems?.some((item) => item.id === villageID)}
                    style={{ cursor: "pointer" }}
                  >
                    {villageName}
                  </ListGroupItem>
                );
              })}
            </ListGroup>
          ) : (
            <p style={{ fontSize: 18 }} className="text-center mb-0">
              No search results
            </p>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={clearFilter}>
          Clear Filter
        </Button>
        <Button
          color="primary"
          onClick={() => {
            setSelectedVillages(activeItems);
            toggle();
          }}
        >
          Apply
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default VillagesFilter;
