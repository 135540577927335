import firebase from "../../config/firebase";

export const getPlayerHistoryData = async (playerName) => {
  try {
    const snapshot = await firebase
      .firestore()
      .collection("playersDataHistory")
      .where("playerName", "==", playerName)
      .get();
    const rows = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    return rows;
  } catch (error) {
    console.error("Error fetching player history data:", error);
    return [];
  }
};

export const getAllianceHistoryData = async (allianceTag) => {
  try {
    const snapshot = await firebase
      .firestore()
      .collection("allianceDataHistory")
      .where("allianceTag", "==", allianceTag)
      .get();
    const rows = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    return rows;
  } catch (error) {
    console.error("Error fetching alliance history data:", error);
    return [];
  }
};

export const getStatsCount = async (todayUnix, yesterdayUnix) => {
  try {
    const snapshot = await firebase
      .firestore()
      .collection("villageStats")
      .where("currentDate", "in", [todayUnix, yesterdayUnix])
      .get();
    const rows = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    return rows;
  } catch (error) {
    console.error("Error fetching stats count:", error);
    return [];
  }
};

export const getAlliancePlayers = async (players) => {
  const flatPlayers = players.flat();
  try {
    const playersData = [];
    for (const player of flatPlayers) {
      const snapshot = await firebase
        .firestore()
        .collection("playersDataHistory")
        .where("playerName", "==", player)
        .get();
      const rows = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      playersData.push(...rows);
    }
    return playersData;
  } catch (error) {
    console.error("Error fetching player data:", error);
    return [];
  }
};

export const getPlayerRank = async (playerName, currentDate) => {
  const playerSnapshot = await firebase
    .firestore()
    .collection("playersDataHistory")
    .where("currentDate", "==", currentDate)
    .orderBy("totalPopulation", "desc")
    .get();

  const playerDocs = playerSnapshot.docs.map((doc) => doc.data());
  const playerRanks = playerDocs.map((doc) => doc.playerName);
  const playerRank = playerRanks.indexOf(playerName) + 1;

  return playerRank;
};

export const getAllianceRank = async (allianceTag, currentDate) => {
  const playerSnapshot = await firebase
    .firestore()
    .collection("allianceDataHistory")
    .where("currentDate", "==", currentDate)
    .orderBy("totalPopulation", "desc")
    .get();

  const allianceDocs = playerSnapshot.docs.map((doc) => doc.data());
  const allianceRanks = allianceDocs.map((doc) => doc.allianceTag);
  const allianceRank = allianceRanks.indexOf(allianceTag) + 1;

  return allianceRank;
};

export const getTop20Players =
  (currentDate, sortBy = "totalPopulation") =>
  async (dispatch) => {
    try {
      const playerSnapshot = await firebase
        .firestore()
        .collection("playersDataHistory")
        .where("currentDate", "==", currentDate)
        .orderBy(sortBy, "desc")
        .limit(20)
        .get();

      const topPlayers = playerSnapshot.docs.map((doc) => doc.data());
      return topPlayers;
    } catch (error) {
      console.error("Error fetching top 20 players:", error);
      return [];
    }
  };

export const getTop20Alliances =
  (currentDate, sortBy = "totalPopulation") =>
  async (dispatch) => {
    try {
      const allianceSnapshot = await firebase
        .firestore()
        .collection("allianceDataHistory")
        .where("currentDate", "==", currentDate)
        .orderBy(sortBy, "desc")
        .limit(20)
        .get();

      const topAlliances = allianceSnapshot.docs.map((doc) => doc.data());
      return topAlliances;
    } catch (error) {
      console.error("Error fetching top 20 players:", error);
      return [];
    }
  };

export const getPlayerPopulation = (data) => async (dispatch) => {
  try {
    const playerSnapshot = await firebase
      .firestore()
      .collection("playersDataHistory")
      .where("playerID", "==", data)
      .get();

    const playerData = playerSnapshot.docs.map((doc) => doc.data());
    return playerData;
  } catch (error) {
    console.error("Error fetching players population:", error);
    return [];
  }
};

export const getAlliancePopulation = (data) => async (dispatch) => {
  try {
    const allianceSnapshot = await firebase
      .firestore()
      .collection("allianceDataHistory")
      .where("allianceID", "==", data)
      .get();

    const allianceData = allianceSnapshot.docs.map((doc) => doc.data());
    return allianceData;
  } catch (error) {
    console.error("Error fetching alliance population:", error);
    return [];
  }
};

export const loader = (val) => async (dispatch) => {
  dispatch({
    type: "PLAYER_STATS_LOADING",
    payload: val,
  });
};
