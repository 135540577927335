import React from "react";
import { NavLink } from "react-router-dom";
import "../../assets/css/Navbar.css";
import { useSelector } from "react-redux";
import logo from "../../assets/Images/RAMTransparentLogo_black.png";

const Navbar = () => {
  const { uid } = useSelector((state) => state.auth);

  const isLoggedIn = localStorage.getItem("userId") == uid;
  return (
    <nav className="navbar navbar-expand-lg user-navbar">
      <div className="container-fluid px-4">
        <div className="d-flex align-items-center">
          <NavLink className="navbar-brand" to="/">
            <img src={logo} width={60} />
          </NavLink>
          <NavLink to="/" className="text-decoration-none">
            <h3 className="p-0 m-0 text-dark">RAM</h3>
          </NavLink>
        </div>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ms-auto">
            {!isLoggedIn && (
              <li className="nav-item cre">
                <NavLink
                  className="nav-link"
                  to="/login"
                  activeClassName="active"
                >
                  User Login
                </NavLink>
              </li>
            )}
            {!isLoggedIn && (
              <li className="nav-item cre">
                <NavLink
                  className="nav-link "
                  to="/register"
                  activeClassName="active"
                >
                  User Sign Up
                </NavLink>
              </li>
            )}
            {isLoggedIn && (
              <li className="nav-item cre">
                <NavLink
                  className="nav-link "
                  to="/"
                  activeClassName="active"
                >
                  Home
                </NavLink>
              </li>
            )}
            {isLoggedIn && (
              <li className="nav-item cre">
                <NavLink
                  className="nav-link "
                  onClick={() => {
                    localStorage.removeItem("userId");
                    window.location.href = "/login";
                  }}
                  activeClassName="active"
                >
                  Logout
                </NavLink>
              </li>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
