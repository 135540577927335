import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAlliances } from "../../../store/actions/mapAction";
import {
  Button,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import DebouncedSearch from "../../shared/DebouncedSearch";
import moment from "moment-timezone";

const AlliancesFilter = ({
  isOpen,
  toggle,
  timezone = "Europe/London",
  activeItems,
  setActiveItems,
  handleActiveItems,
  setSelectedAlliances,
}) => {
  const dispatch = useDispatch();
  const { alliances } = useSelector((state) => state.map);

  const [debouncedSearch, setDebouncedSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [isFetching, setIsFetching] = useState(false);
  const [hitsPerPage] = useState(10);

  const todayUnix = moment.tz(timezone).startOf("day").unix();

  const handleSearch = (debouncedValue) => {
    setDebouncedSearch(debouncedValue);
  };

  const clearFilter = () => {
    setDebouncedSearch("");
    setSelectedAlliances([]);
    setActiveItems([]);
  };

  useEffect(() => {
    setIsFetching(true);
    dispatch(
      fetchAlliances({
        searchQuery: debouncedSearch,
        currentDate: todayUnix,
        currentPage,
        hitsPerPage,
      })
    ).then(() => setIsFetching(false));
  }, [debouncedSearch, hitsPerPage, currentPage]);

  useEffect(() => {
    setCurrentPage(0);
  }, [debouncedSearch]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>Filter Alliances</ModalHeader>
      <ModalBody style={{ maxHeight: "400px", overflowY: "auto" }}>
        <DebouncedSearch
          placeholder="Search alliances..."
          onSearch={handleSearch}
        />
        <div className="py-4">
          {isFetching ? (
            <div className="text-center">
              <Spinner color="primary" />
            </div>
          ) : alliances?.grouped_hits?.length ? (
            <ListGroup>
              {alliances?.grouped_hits?.map((alliance, index) => {
                const { allianceID, allianceTag } = alliance?.hits[0]?.document;
                return (
                  <ListGroupItem
                    key={index}
                    onClick={() =>
                      handleActiveItems({
                        id: allianceID,
                        tag: allianceTag,
                      })
                    }
                    active={activeItems?.some((item) => item.id === allianceID)}
                    style={{ cursor: "pointer" }}
                  >
                    {allianceTag || "N/A"}
                  </ListGroupItem>
                );
              })}
            </ListGroup>
          ) : (
            <p style={{ fontSize: 18 }} className="text-center mb-0">
              No search results
            </p>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={clearFilter}>
          Clear Filter
        </Button>
        <Button
          color="primary"
          onClick={() => {
            setSelectedAlliances(activeItems);
            toggle();
          }}
        >
          Apply
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AlliancesFilter;
