const initialState = {
  TrackerData: [],
  TrackerTable: [],
  dateColumn: [],
  loading: false,
};

const DefenderTrackerReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_TRACKER_DATA":
      return {
        ...state,
        TrackerData: action.payload,
      };
    case "GET_TRACKER_TABLE_DATA":
      return {
        ...state,
        TrackerTable: action.payload,
      };
    case "GET_TRACKER_COLUMNS":
      return {
        ...state,
        dateColumn: action.payload,
      };
    case "TRACKER_DATA_LOADING":
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};

export default DefenderTrackerReducer;
