import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const AllianceHistoryModal = ({ playerHistory, toggle, isOpen }) => {
  const sortedPlayerHistory = playerHistory.sort(
    (a, b) => a.serverDay - b.serverDay
  );

  let currentAllianceTag = sortedPlayerHistory[0]?.allianceTag;
  let startServerDay = sortedPlayerHistory[0]?.serverDay;

  let extractedAlliances = [];

  for (let i = 1; i < sortedPlayerHistory.length; i++) {
    if (sortedPlayerHistory[i].allianceTag !== currentAllianceTag) {
      extractedAlliances.push({
        allianceTag: currentAllianceTag,
        startServerDay,
        endServerDay: sortedPlayerHistory[i - 1]?.serverDay,
      });
      currentAllianceTag = sortedPlayerHistory[i]?.allianceTag;
      startServerDay = sortedPlayerHistory[i]?.serverDay;
    }
  }

  extractedAlliances.push({
    allianceTag: currentAllianceTag,
    startServerDay,
    endServerDay:
      sortedPlayerHistory[sortedPlayerHistory.length - 1]?.serverDay,
  });

  const currentAlliance = extractedAlliances.pop();

  return (
    <Modal isOpen={isOpen} toggle={toggle} scrollable>
      <ModalHeader toggle={toggle}>Alliance History</ModalHeader>
      <ModalBody>
        <ul className="list-group">
          {/* Current Alliance */}
          {currentAlliance && (
            <li className="list-group-item list-group-item-info">
              <div className="fw-bold">Current Alliance</div>
              <div>
                <span className="fw-bold">Alliance:</span>{" "}
                {currentAlliance.allianceTag || "Not currently in any alliance"}
              </div>
              <div>
                <span className="fw-bold">Since Day:</span>{" "}
                {currentAlliance.startServerDay !== null
                  ? currentAlliance.startServerDay
                  : "Not found"}
              </div>
            </li>
          )}

          {/* Previous Alliances */}
          {extractedAlliances.length > 0 &&
            extractedAlliances.map((alliance, index) => (
              <li
                key={index}
                className="list-group-item list-group-item-warning"
              >
                <div className="fw-bold">Previous Alliance</div>
                <div>
                  <span className="fw-bold">Alliance:</span>{" "}
                  {alliance.allianceTag}
                </div>
                <div>
                  <span className="fw-bold">From Day:</span>{" "}
                  {alliance.startServerDay}
                </div>
                <div>
                  <span className="fw-bold">To Day:</span>{" "}
                  {alliance.endServerDay}
                </div>
              </li>
            ))}
        </ul>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AllianceHistoryModal;
