import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Label,
  Spinner,
  Table,
} from "reactstrap";
import FlagModal from "./FlagModal";
import { useDispatch, useSelector } from "react-redux";
import ConfirmationModal from "../../shared/ConfirmationModal";
import {
  allianceFlagRequestAction,
  deleteFlag,
  fetchAllianceFlagRequests,
  fetchAllianceFlags,
  fetchFlags,
  fetchPlayerAlliance,
  fetchPlayerVillages,
} from "../../../store/actions/flagActions";

const Flags = ({
  hoveredBox,
  updateOffsetWithCoordinates,
  updateHoveredCoordinateFromData,
}) => {
  const dispatch = useDispatch();
  const { uid, user } = useSelector((state) => state.auth);
  const { flags, allianceFlags, flagRequests } = useSelector(
    (state) => state.flag
  );

  const [isFetching, setIsFetching] = useState(false);
  const [isFetchingAlliance, setIsFetchingAlliance] = useState(false);
  const [flagModal, setFavouriteModal] = useState(false);
  const [activeFlag, setActiveFlag] = useState(null);
  const [editMode, setEditMode] = useState(false);

  const [actionType, setActionType] = useState(null);
  const [requestModal, setRequestModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const hasRequestsAccess =
    user?.role === "Administrator" || user?.role === "Senior Leader";

  const flagModalToggler = () => {
    if (flagModal) {
      setActiveFlag(null);
      setEditMode(false);
    }
    setFavouriteModal(!flagModal);
  };

  const confirmationModalToggler = () => {
    if (confirmationModal) {
      setActiveFlag(null);
    }
    setConfirmationModal(!confirmationModal);
  };

  const requestModalToggler = () => {
    if (requestModal) {
      setActionType(null);
      setActiveFlag(null);
    }
    setRequestModal(!requestModal);
  };

  const initiateView = (coordinates) => {
    updateOffsetWithCoordinates(coordinates);
  };

  const initiateHighlight = (coordinates) => {
    if (!coordinates || coordinates?.x === "" || coordinates?.y === "") {
      return;
    }
    updateHoveredCoordinateFromData(coordinates);
  };

  const removeHighlight = () => {
    updateHoveredCoordinateFromData({ x: "", y: "" });
  };

  const initiateEdit = (flag) => {
    setActiveFlag(flag);
    setEditMode(true);
    flagModalToggler();
  };

  const initiateDelete = (flag) => {
    setActiveFlag(flag);
    confirmationModalToggler();
  };

  const handleDeleteFlag = () => {
    if (activeFlag) {
      setIsLoading(true);
      dispatch(deleteFlag({ id: activeFlag?.id }))
        .then(() => confirmationModalToggler())
        .finally(() => setIsLoading(false));
    }
  };

  const initiateRequestAction = (flag, status) => {
    setActionType(status);
    setActiveFlag(flag);
    requestModalToggler();
  };

  const handleRequestAction = () => {
    if (activeFlag) {
      setIsLoading(true);
      const status = actionType === "accept" ? "active" : "rejected";
      dispatch(allianceFlagRequestAction({ id: activeFlag?.id, status }))
        .then(() => requestModalToggler())
        .finally(() => setIsLoading(false));
    }
  };

  useEffect(() => {
    if (uid) {
      setIsFetching(true);
      dispatch(fetchFlags({ uid })).finally(() => setIsFetching(false));
    }
  }, [uid]);

  useEffect(() => {
    if (user && !user?.gameAccountName) {
      setIsFetchingAlliance(true);
      dispatch(
        fetchPlayerAlliance({
          playerName: user?.gameAccountName || "",
        })
      )
        .then(async (allianceId) => {
          if (allianceId) {
            await Promise.all([
              dispatch(fetchAllianceFlags({ allianceId })),
              dispatch(
                fetchAllianceFlagRequests({
                  allianceId,
                  uid: hasRequestsAccess ? uid : null,
                })
              ),
            ]).finally(() => setIsFetchingAlliance(false));
          } else {
            setIsFetchingAlliance(false);
          }
        })
        .catch(() => setIsFetchingAlliance(false));
    }
  }, [user]);

  useEffect(() => {
    if (flags?.length) {
      const hasPlayerFlag = flags.some((flag) => flag?.markType === "player");
      if (hasPlayerFlag) {
        dispatch(
          fetchPlayerVillages({
            playerName: user?.gameAccountName || "",
          })
        );
      }
    }
  }, [flags]);

  return (
    <div className="ms-2">
      <Card className="shadow-sm overflow-hidden">
        <CardHeader className="d-flex justify-content-between align-items-center">
          <h5 className="mb-0">Flags</h5>
          <Button color="primary" onClick={flagModalToggler}>
            Add
          </Button>
        </CardHeader>
        <CardBody>
          <h5>Player Flags</h5>
          <Table responsive className="m-0 border-0">
            <thead>
              <th>Label</th>
              <th>Mark Type</th>
              <th>X Coordinate</th>
              <th>Y Coordinate</th>
              <th>Color</th>
              <th className="text-center">Actions</th>
            </thead>
            <tbody className="m-0 p-0">
              {isFetching ? (
                <tr>
                  <td colSpan={6} className="text-center py-3">
                    <Spinner color="primary" size="sm" />
                  </td>
                </tr>
              ) : flags?.length > 0 ? (
                flags?.map((flag) => (
                  <tr key={flag?.id}>
                    <td>{flag?.label}</td>
                    <td className="text-capitalize">{flag?.markType || "-"}</td>
                    <td>{flag?.coordinates ? flag?.coordinates?.x : "-"}</td>
                    <td>{flag?.coordinates ? flag?.coordinates?.y : "-"}</td>
                    <td
                      className="text-center"
                      style={{
                        backgroundColor:
                          hoveredBox &&
                          flag?.coordinates &&
                          hoveredBox.x == flag?.coordinates?.x &&
                          hoveredBox.y == flag?.coordinates?.y &&
                          "yellow",
                        cursor: "pointer",
                      }}
                      onMouseEnter={() => initiateHighlight(flag?.coordinates)}
                      onMouseLeave={removeHighlight}
                    >
                      <i
                        className="fas fa-flag fa-2x"
                        style={{ color: flag?.color }}
                      />
                    </td>
                    <td>
                      <div className="d-flex justify-content-between align-items-center">
                        <Button
                          color="primary"
                          style={{ width: "35px", height: "35px" }}
                          className="d-flex justify-content-center align-items-center me-2 overflow-hidden p-1"
                          onClick={() => initiateView(flag?.coordinates)}
                          disabled={
                            !flag?.coordinates &&
                            (!flag?.coordinates?.x || !flag?.coordinates?.y)
                          }
                        >
                          <i className="fas fa-eye" />
                        </Button>
                        <Button
                          color="warning"
                          onClick={() => initiateEdit(flag)}
                          className="d-flex justify-content-center align-items-center me-2 overflow-hidden p-1"
                          style={{ width: "35px", height: "35px" }}
                        >
                          <i className="fas fa-edit text-white" />
                        </Button>
                        <Button
                          color="danger"
                          onClick={() => initiateDelete(flag)}
                          className="d-flex justify-content-center align-items-center overflow-hidden p-1"
                          style={{ width: "35px", height: "35px" }}
                        >
                          <i className="fas fa-trash" />
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={6} className="text-center py-3">
                    No flag added
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          <h5 className="mt-4">Alliance Flags</h5>
          <Table responsive className="m-0 border-0">
            <thead>
              <th>Label</th>
              <th>X Coordinate</th>
              <th>Y Coordinate</th>
              <th>Color</th>
              <th className="text-center">Actions</th>
            </thead>
            <tbody className="m-0 p-0">
              {isFetchingAlliance ? (
                <tr>
                  <td colSpan={5} className="text-center py-3">
                    <Spinner color="primary" size="sm" />
                  </td>
                </tr>
              ) : allianceFlags?.length > 0 ? (
                allianceFlags?.map((flag) => (
                  <tr key={flag?.id}>
                    <td>{flag?.label}</td>
                    <td>{flag?.coordinates ? flag?.coordinates?.x : "-"}</td>
                    <td>{flag?.coordinates ? flag?.coordinates?.y : "-"}</td>
                    <td
                      className="text-center"
                      style={{
                        backgroundColor:
                          hoveredBox &&
                          flag?.coordinates &&
                          hoveredBox.x == flag?.coordinates?.x &&
                          hoveredBox.y == flag?.coordinates?.y &&
                          "yellow",
                        cursor: "pointer",
                      }}
                      onMouseEnter={() => initiateHighlight(flag?.coordinates)}
                      onMouseLeave={removeHighlight}
                    >
                      <i
                        className="fas fa-flag fa-2x"
                        style={{ color: flag?.color }}
                      />
                    </td>
                    <td>
                      <div className="d-flex justify-content-center align-items-center">
                        <Button
                          color="primary"
                          style={{ width: "35px", height: "35px" }}
                          className="d-flex justify-content-center align-items-center me-2 overflow-hidden p-1"
                          onClick={() => initiateView(flag?.coordinates)}
                          disabled={
                            !flag?.coordinates &&
                            (!flag?.coordinates?.x || !flag?.coordinates?.y)
                          }
                        >
                          <i className="fas fa-eye" />
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={5} className="text-center py-3">
                    No flag added
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          <h5 className="mt-4">Alliance Flags Requests</h5>
          <Table responsive className="m-0 border-0">
            <thead>
              <th>Label</th>
              <th>X Coordinate</th>
              <th>Y Coordinate</th>
              <th>Color</th>
              {hasRequestsAccess && <th className="text-center">Actions</th>}
            </thead>
            <tbody className="m-0 p-0">
              {!user || isFetchingAlliance ? (
                <tr>
                  <td colSpan={5} className="text-center py-3">
                    <Spinner color="primary" size="sm" />
                  </td>
                </tr>
              ) : flagRequests?.length > 0 ? (
                flagRequests?.map((flag) => (
                  <tr key={flag?.id}>
                    <td>{flag?.label}</td>
                    <td>{flag?.coordinates ? flag?.coordinates?.x : "-"}</td>
                    <td>{flag?.coordinates ? flag?.coordinates?.y : "-"}</td>
                    <td className="text-center">
                      <i
                        className="fas fa-flag fa-2x"
                        style={{ color: flag?.color }}
                      />
                    </td>
                    {hasRequestsAccess && (
                      <td>
                        <div className="d-flex justify-content-center align-items-center">
                          <Button
                            color="success"
                            className="px-2 d-flex justify-content-center align-items-center me-2 overflow-hidden p-1"
                            disabled={!hasRequestsAccess}
                            onClick={() =>
                              initiateRequestAction(flag, "accept")
                            }
                          >
                            Accept
                          </Button>
                          <Button
                            color="danger"
                            className="px-2 d-flex justify-content-center align-items-center me-2 overflow-hidden p-1"
                            disabled={!hasRequestsAccess}
                            onClick={() =>
                              initiateRequestAction(flag, "reject")
                            }
                          >
                            Reject
                          </Button>
                        </div>
                      </td>
                    )}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={5} className="text-center py-3">
                    No request
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </CardBody>
      </Card>
      <FlagModal
        isOpen={flagModal}
        toggle={flagModalToggler}
        editMode={editMode}
        activeFlag={activeFlag}
      />
      <ConfirmationModal
        title={`${actionType?.charAt(0).toUpperCase()}${actionType?.slice(
          1
        )} Flag Request`}
        text={`Are you sure you want to ${actionType} this flag request?`}
        isOpen={requestModal}
        toggle={requestModalToggler}
        isLoading={isLoading}
        onConfirmation={handleRequestAction}
      />
      <ConfirmationModal
        title="Delete Flag"
        text="Are you sure you want to delete this flag?"
        isOpen={confirmationModal}
        toggle={confirmationModalToggler}
        isLoading={isLoading}
        onConfirmation={handleDeleteFlag}
      />
    </div>
  );
};

export default Flags;
