import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  approveUser,
  getAllDiscordIDs,
  getUsers,
  userDelete,
} from "../store/actions/dashboardAction";
import { toast } from "react-toastify";
import { getPermissions } from "../store/actions/userpermissionsAction";
const AdminDashboard = () => {
  //--------useSelectors---------------//
  const { users, loading, discordIDs } = useSelector(
    (state) => state.dashboard
  );
  const { user } = useSelector((state) => state.auth);
  const { permission } = useSelector((state) => state.userPermissions);
  const dispatch = useDispatch();

  //-----------UseStates -------------//
  const [allUsers, setAllUsers] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState("");
  const [functionalRole, setFunctionalRole] = useState("");
  const [unsubmittedChanges, setUnsubmittedChanges] = useState({});
  const [discordId, setDiscordId] = useState("");
  const [gameAccountName, setGameAccountName] = useState("");
  const [discordIDAvailability, setDiscordIDAvailability] = useState("");
  const [discordIDAvailabilityColor, setDiscordIDAvailabilityColor] =
    useState("");
  const [editingUserId, setEditingUserId] = useState(null);
  const [condition, setCondition] = useState("");
  const [sortColumn, setSortColumn] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");

  //-------- Permissions ------- //

  const permissionData = permission?.userAccounts;
  const role = user?.role;
  const functionalrole = user?.functionalRole;
  const functionalPermission =
    permission && permissionData && permissionData[functionalRole];

  //-------- Functions ------- //

  const handleDiscordIdCheck = (value) => {
    console.log(value);
    if (value.trim() !== "") {
      const isDiscordIDAvailable = !discordIDs.includes(value);
      setDiscordIDAvailability(
        isDiscordIDAvailable
          ? "Discord ID is available"
          : "Discord ID is already taken"
      );
      setDiscordIDAvailabilityColor(isDiscordIDAvailable ? "green" : "red");
    } else {
      setDiscordIDAvailability("");
      setDiscordIDAvailabilityColor("");
    }
  };

  const handleDiscordIdChange = (value) => {
    setDiscordId(value);
  };
  const handleGameAccountNameChange = (value) => {
    setGameAccountName(value);
  };

  const handleApproveUser = async (
    userId,
    userrole,
    functionalRoles,
    discordUserId,
    discordId,
    gameAccountName
  ) => {
    const role = selectedRoles[userId] || userrole;
    const functionalrole = functionalRole[userId] || functionalRoles;
    try {
      if (discordId !== "" && discordIDs.includes(discordId)) {
        toast.error("Discord ID is already taken.");
        setSelectedRoles("");
        setDiscordIDAvailability("");
        dispatch(getUsers());
        setUnsubmittedChanges((prevChanges) => ({
          ...prevChanges,
          [userId]: false,
        }));
        return;
      }

      dispatch(
        approveUser(
          userId,
          role,
          functionalrole,
          discordUserId || "",
          gameAccountName || ""
        )
      );
      setUnsubmittedChanges((prevChanges) => ({
        ...prevChanges,
        [userId]: false,
      }));
      setSelectedRoles("");
      setDiscordId("");
      setDiscordIDAvailability("");
      setFunctionalRole("");
    } catch (error) {
      toast.error("Failed to approve user:", error.message);
    }
  };

  const handleDeleteUser = async (userId) => {
    dispatch(userDelete(userId));
  };

  const handleRoleChange = (userId, selectedRole) => {
    setSelectedRoles((prevRoles) => ({
      ...prevRoles,
      [userId]: selectedRole,
    }));
    setUnsubmittedChanges((prevChanges) => ({
      ...prevChanges,
      [userId]: true,
    }));
  };

  const handleFunctionalRoleChange = (userId, selectedRole) => {
    setFunctionalRole((prevRoles) => ({
      ...prevRoles,
      [userId]: selectedRole,
    }));
    setUnsubmittedChanges((prevChanges) => ({
      ...prevChanges,
      [userId]: true,
    }));
  };

  const handleDiscordUserIdChange = (userId, newDiscordUserId) => {
    let updatedUsers = [...allUsers];
    const userIndex = updatedUsers.findIndex((user) => user.id === userId);
    if (userIndex !== -1) {
      updatedUsers[userIndex].discordUserId = newDiscordUserId;
    }
    setAllUsers(updatedUsers);
    setUnsubmittedChanges((prevChanges) => ({
      ...prevChanges,
      [userId]: true,
    }));
  };

  const handleGameAccountChange = (userId, gameAccountName) => {
    let updatedUsers = [...allUsers];
    const userIndex = updatedUsers.findIndex((user) => user.id === userId);
    if (userIndex !== -1) {
      updatedUsers[userIndex].gameAccountName = gameAccountName;
    }
    setAllUsers(updatedUsers);
    setUnsubmittedChanges((prevChanges) => ({
      ...prevChanges,
      [userId]: true,
    }));
  };

  const clearFilters = () => {
    setSortColumn(null);
    setAllUsers(users);
  };

  // Function to handle sorting

  const handleSort = (column, option) => {
    let sortedUsers = [...allUsers];

    if (option === "asc") {
      sortedUsers.sort((a, b) => {
        const valueA = parseInt(a[column]);
        const valueB = parseInt(b[column]);
        return valueA - valueB;
      });
      setSortOrder("asc");
    } else if (option === "desc") {
      sortedUsers.sort((a, b) => {
        const valueA = parseInt(a[column]);
        const valueB = parseInt(b[column]);
        return valueB - valueA;
      });
      setSortOrder("desc");
    }

    setSortColumn(column);
    setAllUsers(sortedUsers);
  };

  const sortedUsers = [...allUsers].sort((a, b) => {
    if (sortColumn) {
      const valueA = a[sortColumn];
      const valueB = b[sortColumn];

      const isEmptyA = valueA === undefined || valueA === null || valueA === "";
      const isEmptyB = valueB === undefined || valueB === null || valueB === "";

      if (isEmptyA && !isEmptyB) {
        return 1;
      }
      if (!isEmptyA && isEmptyB) {
        return -1;
      }

      const numberValueA = parseInt(valueA);
      const numberValueB = parseInt(valueB);

      if (!isNaN(numberValueA) && !isNaN(numberValueB)) {
        return sortOrder === "asc"
          ? numberValueA - numberValueB
          : numberValueB - numberValueA;
      } else {
        if (valueA && valueB) {
          return sortOrder === "asc"
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        } else {
          return 0;
        }
      }
    }
    return 0;
  });

  //-----------Columns ------------ //

  const columns = [
    { key: "email", label: "Email" },
    { key: "discordname", label: "Discord Name" },
    { key: "discordUserId", label: "Discord User ID" },
    { key: "gameAccountName", label: "Game Account Name" },
    { key: "role", label: "Role" },
    { key: "functionalRole", label: "Functional Role" },
  ];

  //-------- useEffect---------//

  useEffect(() => {
    if (users) {
      setAllUsers(users);
    }
  }, [users]);

  useEffect(() => {
    if (permission && permissionData) {
      const rolePermission = permissionData[role];
      const functionalPermission = permissionData[functionalrole];
      if (
        functionalPermission !== undefined &&
        functionalPermission.trim() !== ""
      ) {
        setCondition(functionalPermission);
      } else if (rolePermission !== undefined && rolePermission.trim() !== "") {
        setCondition(rolePermission);
      }
    }
  }, [permission, permissionData, role, functionalRole]);

  useEffect(() => {
    dispatch(getUsers());
    dispatch(getPermissions());
    dispatch(getAllDiscordIDs());
  }, []);

  return (
    <div
      style={{
        padding: "20px",
        width: "100%",
        background: "white",
        overflow: loading ? "hidden" : "auto",
      }}
      className="container-fluid px-5"
    >
      <h2 className="text-center">User Accounts</h2>
      {condition == "Locked" ? (
        <div
          className="text-center d-flex justify-content-center align-items-center "
          style={{ height: "70vh" }}
        >
          <h2>
            This page is not available for{" "}
            {functionalPermission !== undefined &&
            functionalPermission.trim() !== ""
              ? functionalRole
              : role}
          </h2>{" "}
        </div>
      ) : (
        <div style={{ overflowX: "auto" }}>
          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
              marginTop: "20px",
              overflow: loading ? "hidden" : "auto",
            }}
          >
            {columns.map((column) => (
              <th key={column.key} className="p-3 bg-info text-center">
                {column.label}
                <i
                  style={{
                    cursor: "pointer",
                    color: sortColumn === column.key ? "red" : "",
                    border:
                      sortColumn === column.key ? "1px solid red" : "none",
                  }}
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  disabled={condition === "Read Only"}
                  className={"bi bi-filter ms-2 p-2"}
                ></i>
                <ul style={{ cursor: "pointer" }} className="dropdown-menu">
                  <li
                    style={{ cursor: "pointer" }}
                    className="dropdown-item nn"
                    onClick={() => handleSort(column.key, "desc")}
                  >
                    Sort By Highest Values
                  </li>
                  <li
                    style={{ cursor: "pointer" }}
                    className="dropdown-item nn"
                    onClick={() => handleSort(column.key, "asc")}
                  >
                    Sort By Lowest Values
                  </li>
                  <li
                    style={{ cursor: "pointer" }}
                    className="dropdown-item nn"
                    onClick={() => clearFilters()}
                  >
                    Clear filter
                  </li>
                </ul>
              </th>
            ))}
            <th className="p-3 bg-info text-center">
              Actions
            </th>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan="5" className="text-center">
                    <div
                      className="spinner-border"
                      role="status"
                      style={{ display: "inline-block", marginTop: "25px" }}
                    ></div>
                  </td>
                </tr>
              ) : (
                sortedUsers?.map((user) => {
                  return (
                    <>
                      {!user?.isDeleted && (
                        <tr
                          key={user?._id}
                          style={{
                            backgroundColor: unsubmittedChanges[user?.id]
                              ? "yellow"
                              : "inherit",
                          }}
                        >
                          <td className="text-center">{user?.email}</td>
                          <td className="text-center">{user?.discordname}</td>
                          <td className="text-center">
                            <input
                              type="number"
                              value={user?.discordUserId}
                              onBlur={() => setEditingUserId(user?.id)}
                              onChange={(e) => {
                                handleDiscordUserIdChange(
                                  user?.id,
                                  e.target.value
                                );
                                handleDiscordIdChange(e.target.value);
                                handleDiscordIdCheck(e.target.value);
                              }}
                              disabled={condition === "Read Only"}
                            />
                            {editingUserId === user?.id && (
                              <p
                                className={`discordName-availability ${discordIDAvailabilityColor}`}
                              >
                                {discordIDAvailability}
                              </p>
                            )}
                          </td>
                          <td className="text-center">
                            <input
                              type="text"
                              value={user?.gameAccountName}
                              onBlur={() => setEditingUserId(user?.id)}
                              onChange={(e) => {
                                handleGameAccountChange(
                                  user?.id,
                                  e.target.value
                                );
                                handleGameAccountNameChange(e.target.value);
                              }}
                              disabled={condition === "Read Only"}
                            />
                          </td>
                          <td className="text-center">
                            {
                              <div className="d-flex">
                                <select
                                  onChange={(e) =>
                                    handleRoleChange(user?.id, e.target.value)
                                  }
                                  value={
                                    selectedRoles[user?.id] || user?.role || ""
                                  }
                                  key={`role-${user?.id}`}
                                  disabled={condition === "Read Only"}
                                >
                                  <option value=" ">Select Role</option>
                                  <option value="Administrator">
                                    Administrator
                                  </option>
                                  <option value="Senior Leader">
                                    Senior Leader
                                  </option>
                                  <option value="Leader">Leader</option>
                                  <option value="Senior member">
                                    Senior Member
                                  </option>
                                  <option value="Member">Member</option>
                                  <option value="Recruit">Recruit</option>
                                </select>
                              </div>
                            }
                          </td>
                          <td className="text-center">
                            {
                              <div className="d-flex">
                                <select
                                  onChange={(e) =>
                                    handleFunctionalRoleChange(
                                      user?.id,
                                      e.target.value
                                    )
                                  }
                                  value={
                                    functionalRole[user?.id] ||
                                    user?.functionalRole ||
                                    " "
                                  }
                                  key={`role-${user?.id}`}
                                  disabled={condition === "Read Only"}
                                >
                                  <option value=" ">Select Role</option>
                                  <option value="Scout Team">Scout Team</option>
                                  <option value="Push Team">Push Team</option>
                                </select>
                              </div>
                            }
                          </td>
                          <td className="text-center">
                            <div className="d-flex justify-content-evenly">
                              <button
                                className=" me-1 btn btn-success"
                                disabled={loading || condition === "Read Only"}
                                onClick={() =>
                                  handleApproveUser(
                                    user?.id,
                                    user?.role,
                                    user?.functionRole,
                                    user?.discordUserId,
                                    discordId,
                                    gameAccountName || user?.gameAccountName
                                  )
                                }
                              >
                                {user?.role && user?.role !== "pending"
                                  ? "Update"
                                  : "Approve"}
                              </button>
                              <button
                                className="btn btn-danger"
                                disabled={loading || condition === "Read Only"}
                                onClick={() => handleDeleteUser(user?.id)}
                              >
                                Delete
                              </button>
                            </div>
                          </td>
                        </tr>
                      )}
                    </>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default AdminDashboard;