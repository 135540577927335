import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import { toast } from "react-toastify";
import {
  addBonnieVillage,
  editBonnieVillage,
} from "../../../store/actions/bonnieVillagesActions";

const BonnieVillageModal = ({
  isOpen,
  toggle,
  editMode = false,
  activeBonnieVillage = null,
  coordinates = null,
}) => {
  const dispatch = useDispatch();
  const { uid } = useSelector((state) => state.auth);

  const [data, setData] = useState({
    coordinates: {
      x: "",
      y: "",
    },
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleAddBonnieVillage = () => {
    setIsLoading(true);
    dispatch(
      addBonnieVillage({
        uid,
        payload: data,
      })
    )
      .then(() => toggle())
      .finally(() => setIsLoading(false));
  };

  const handleEditBonnieVillage = () => {
    setIsLoading(true);
    dispatch(
      editBonnieVillage({
        id: activeBonnieVillage?.id,
        payload: data,
      })
    )
      .then(() => toggle())
      .finally(() => setIsLoading(false));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!uid) {
      toast.error("Please login to perform this action");
      return;
    }
    if (editMode) {
      handleEditBonnieVillage();
    } else {
      handleAddBonnieVillage();
    }
  };

  useEffect(() => {
    if (isOpen) {
      if (editMode && activeBonnieVillage) {
        setData({
          coordinates: {
            x: activeBonnieVillage?.coordinates?.x,
            y: activeBonnieVillage?.coordinates?.y,
          },
        });
      } else {
        setData({
          coordinates: {
            x: coordinates ? coordinates?.x : "",
            y: coordinates ? coordinates?.y : "",
          },
        });
      }
    }
  }, [isOpen, editMode, activeBonnieVillage]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} className="modal-dialog-centered">
      <ModalHeader toggle={toggle}>
        {editMode ? "Update" : "Add"} Bonnie Village
      </ModalHeader>
      <form onSubmit={handleSubmit}>
        <ModalBody>
          <Row className="mb-3">
            <Col>
              <Label>X Coordinate</Label>
              <Input
                type="number"
                placeholder="Enter x-coordinate"
                required
                value={data?.coordinates?.x}
                onChange={(e) =>
                  setData({
                    ...data,
                    coordinates: {
                      ...data?.coordinates,
                      x: Number(e.target.value),
                    },
                  })
                }
              />
            </Col>
            <Col>
              <Label>Y Coordinate</Label>
              <Input
                type="number"
                placeholder="Enter y-coordinate"
                required
                value={data?.coordinates?.y}
                onChange={(e) =>
                  setData({
                    ...data,
                    coordinates: {
                      ...data?.coordinates,
                      y: Number(e.target.value),
                    },
                  })
                }
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle} disabled={isLoading}>
            Close
          </Button>
          <Button type="submit" color="primary" disabled={isLoading}>
            {editMode ? "Update" : "Add"}
            {isLoading && <Spinner size="sm" className="ms-2" />}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default BonnieVillageModal;
