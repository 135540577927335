import firebase from "../../config/firebase";

export const getUsers = () => async (dispatch) => {
  try {
    dispatch({ type: "IS_FETCHING_USERS", payload: true });
    firebase
      .firestore()
      .collection("users")
      .onSnapshot(async (data) => {
        let tempData = [];
        for (let doc of data.docs) {
          let id = doc.id;
          let data1 = doc.data();
          tempData.push({ id: id, ...data1 });
        }
        dispatch({ type: "GET_USERS", payload: tempData });
        dispatch({ type: "IS_FETCHING_USERS", payload: false });
      });
  } catch (error) {
    alert(error.message);
    dispatch({ type: "IS_FETCHING_USERS", payload: false });
  }
};

export const approveUser =
  (userId, role, functionalRole, discordUserId, gameAccountName) =>
  async (dispatch) => {
    console.log(userId, role, functionalRole, discordUserId);
    try {
      await firebase
        .firestore()
        .collection("users")
        .doc(userId)
        .update({
          role,
          functionalRole: functionalRole || "",
          discordUserId,
          gameAccountName: gameAccountName || "",
        });

      dispatch({
        type: "APPROVE_USER_SUCCESS",
        payload: { userId, role, discordUserId },
      });
    } catch (error) {
      console.error("Failed to approve user:", error.message);
    }
  };

export const userDelete = (userId) => async (dispatch) => {
  try {
    await firebase
      .firestore()
      .collection("users")
      .doc(userId)
      .update({ isDeleted: true });
  } catch (error) {
    console.log(error);
  }
};

export const getAllDiscordIDs = () => async (dispatch) => {
  try {
    firebase
      .firestore()
      .collection("users")
      .onSnapshot((query) => {
        let discordIDs = [];
        for (let doc of query.docs) {
          let data = doc.data();
          discordIDs.push(data.discordUserId);
        }
        dispatch({
          type: "ALL_DISCORD_IDs",
          payload: discordIDs,
        });
      });
  } catch (error) {
    console.log({ error });
  }
};
