import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import Papa from "papaparse";
import {
  getHammerTrackerData,
  updateHammerTrackerData,
} from "../../store/actions/hammerTrackerAction";
import { toast } from "react-toastify";

const HammerReportParse = ({ modal, toggle }) => {
  const dispatch = useDispatch();
  const { hammerData } = useSelector((state) => state.hammerTracker);
  const [hammersData, setHammersData] = useState([]);
  const [reportText, setReportText] = useState("");

  const handleImport = () => {
    try {
      const parsedReport = Papa.parse(reportText);

      if (
        !parsedReport ||
        !parsedReport.data ||
        parsedReport.data.length === 0
      ) {
        toast.error("Failed to parse report data.");
      }

      const reportData = parsedReport.data;
      const attackerIndex = reportData.findIndex(
        (row) => Array.isArray(row) && row.includes && row.includes("ATTACKER")
      );

      const defenderIndex = reportData.findIndex(
        (row) => Array.isArray(row) && row.includes && row.includes("DEFENDER")
      );

      if (attackerIndex !== -1 && defenderIndex !== -1) {
        const attackerData = reportData.slice(attackerIndex + 1, defenderIndex);
        const defenderData = reportData.slice(defenderIndex + 1);

        const attackerFirstRow = attackerData[0];
        const defenderFirstRow = defenderData[0];

        const attackerVillageName = attackerFirstRow[0]
          .substring(attackerFirstRow[0].indexOf("village") + 7)
          .trim();
        const defenderVillageName = defenderFirstRow[0]
          .substring(defenderFirstRow[0].indexOf("village") + 7)
          .trim();

        let attackerName;
        let defenderName;

        if (attackerFirstRow[0].includes("[")) {
          attackerName = attackerFirstRow[0]
            .substring(
              attackerFirstRow[0].indexOf("]") + 1,
              attackerFirstRow[0].indexOf("from")
            )
            .trim();
        } else {
          attackerName = attackerFirstRow[0].trim().split("from")[0].trim();
        }

        if (defenderFirstRow[0].includes("[")) {
          defenderName = defenderFirstRow[0]
            .substring(
              defenderFirstRow[0].indexOf("]") + 1,
              defenderFirstRow[0].indexOf("from")
            )
            .trim();
        } else {
          defenderName = defenderFirstRow[0].trim().split("from")[0].trim();
        }

        const matchingEntryAttacker = hammersData.find(
          (entry) =>
            entry.villageName === attackerVillageName &&
            entry.playerName === attackerName
        );
        const matchingEntryDefender = hammerData.find(
          (entry) =>
            entry.villageName === defenderVillageName &&
            entry.playerName === defenderName
        );

        if (!matchingEntryAttacker && !matchingEntryDefender) {
          toast.error("Player not found!");
          return;
        }

        const useAttackerData = matchingEntryAttacker;
        const dataToUse = useAttackerData ? attackerData : defenderData;
        const rowString = dataToUse[1];
        const rowString2 = dataToUse[2];
        const rowString3 = dataToUse[3];

        if (
          Array.isArray(rowString) &&
          rowString.length > 0 &&
          typeof rowString[0] === "string" &&
          Array.isArray(rowString2) &&
          rowString2.length > 0 &&
          Array.isArray(rowString3) &&
          rowString3.length > 0
        ) {
          const labels = rowString[0]
            .trim()
            .split("\t")
            .map((label) => label.trim());

          const labels2 = rowString2[0]
            .trim()
            .split("\t")
            .map((label) => label.trim());

          const labels3 = rowString3[0]
            .trim()
            .split("\t")
            .map((label) => label.trim());

          const resultObject = {};

          labels.forEach((label, index) => {
            const value = parseInt(labels2[index]) - parseInt(labels3[index]);
            resultObject[label] = value;
          });

          let matchingEntry = matchingEntryAttacker || matchingEntryDefender;

          if (matchingEntry) {
            let firstLabel = labels[0];

            if (firstLabel.toLowerCase() in matchingEntry) {
              const dispatchData = {
                data: resultObject,
                id: matchingEntry.id,
              };
              dispatch(updateHammerTrackerData(dispatchData));
            } else {
              alert("Fields does not match with matching entry");
            }
          } else {
            alert("No matching entry found");
          }
          toggle();
          setReportText("");
        } else {
          toast.error("Row data is not a valid string array.");
        }
      } else {
        toast.error("Attacker or defender not found in report data.");
      }
    } catch (error) {
      toast.error(error);
    }
  };

  useEffect(() => {
    if (modal) {
      dispatch(getHammerTrackerData());
    }
  }, [modal]);

  useEffect(() => {
    if (hammerData) {
      setHammersData(hammerData);
    }
  }, [hammerData]);

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Paste Report</ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <textarea
                rows="15"
                className="form-control"
                value={reportText}
                onChange={(e) => setReportText(e.target.value)}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            size="sm"
            onClick={() => {
              toggle();
              setReportText("");
            }}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            size="sm"
            onClick={handleImport}
            disabled={!reportText || reportText.length === 0}
          >
            Update
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default HammerReportParse;
