import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCJD4bJfqKT_wptITh6jLP4PtLmisS0Xac",
  authDomain: "theramalliance.firebaseapp.com",
  projectId: "theramalliance",
  storageBucket: "theramalliance.appspot.com",
  messagingSenderId: "22882923247",
  appId: "1:22882923247:web:c0c664d57f059dc1d3211a",
  measurementId: "G-07PXCYLP13",
};

firebase.initializeApp(firebaseConfig);

export default firebase;
