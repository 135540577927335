import { toast } from "react-toastify";
import firebase from "../../config/firebase";

export const addNewRow =
  (payload, onSuccess = () => {}) =>
  async () => {
    try {
      const docRef = await firebase
        .firestore()
        .collection("hammerTracker")
        .add({ ...payload });

      await docRef.update({ id: docRef.id });

      onSuccess(docRef.id);
      toast.success("New Row added successfully");
    } catch (error) {
      toast.error("Error adding new Row");
    }
  };

export const getHammerTrackerData = () => async (dispatch) => {
  try {
    const snapshot = await firebase
      .firestore()
      .collection("hammerTracker")
      .get();
    const rows = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    dispatch({ type: "GET_HAMMER_ROWS", payload: rows });
  } catch (error) {
    console.error("Error fetching rows:", error);
  }
};

export const updateHammerTrackerData =
  (payload, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      const updateObject = {};
      Object.entries(payload.data).forEach(([key, value]) => {
        const formattedKey = key.replace(/\s+/g, "_").toLowerCase();
        updateObject[formattedKey] = value;
      });
      updateObject.timestamp = firebase.firestore.FieldValue.serverTimestamp();

      await firebase
        .firestore()
        .collection("hammerTracker")
        .doc(payload.id)
        .update(updateObject)
        .then(onSuccess());

      dispatch(getTeutonRows());
      dispatch(getRomanRows());
      dispatch(getGualRows());

      toast.success("Data updated successfully");
    } catch (error) {
      console.error(error);
    }
  };

export const getTeutonRows = () => async (dispatch) => {
  try {
    const snapshot = await firebase
      .firestore()
      .collection("hammerTracker")
      .where("type", "==", "Teuton")
      .get();
    const rows = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    dispatch({ type: "GET_TEUTON_ROWS", payload: rows });
  } catch (error) {
    console.error("Error fetching rows:", error);
  }
};

export const getGualRows = () => async (dispatch) => {
  try {
    const snapshot = await firebase
      .firestore()
      .collection("hammerTracker")
      .where("type", "==", "Gual")
      .get();

    const rows = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    dispatch({ type: "GET_GUAL_ROWS", payload: rows });
  } catch (error) {
    console.error("Error fetching rows:", error);
  }
};

export const getRomanRows = () => async (dispatch) => {
  try {
    const snapshot = await firebase
      .firestore()
      .collection("hammerTracker")
      .where("type", "==", "Roman")
      .get();

    const rows = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    dispatch({ type: "GET_ROMAN_ROWS", payload: rows });
  } catch (error) {
    console.error("Error fetching rows:", error);
  }
};

export const deleteRow =
  (id, onSuccess = () => {}) =>
  async () => {
    try {
      firebase
        .firestore()
        .collection("hammerTracker")
        .doc(id)
        .delete()
        .then(onSuccess());
      toast.success("Row deleted successfully");
    } catch (error) {
      toast.success("Failed to delete row");
    }
  };

export const saveData = (units) => {
  return async () => {
    try {
      const firestore = firebase.firestore();
      const batch = firestore.batch();

      units.forEach((unit) => {
        const docRef = firestore.collection("hammerTracker").doc(unit.id);
        const timestamp = new Date();
        const updatedUnit = { ...unit, timestamp };
        batch.update(docRef, updatedUnit);
      });

      await batch.commit();
    } catch (error) {
      console.error("Error updating unit data: ", error);
      throw error;
    }
  };
};

export const saveModiefiers = (data, documentId, onSuccess = () => {}) => {
  return async (dispatch) => {
    try {
      dispatch(loader(true));

      if (documentId) {
        await firebase
          .firestore()
          .collection("modifiers")
          .doc(documentId)
          .set(data);
        toast.success("Modifiers data updated successfully");
      } else {
        await firebase.firestore().collection("modifiers").add(data);
        toast.success("Modifiers data saved successfully");
      }

      onSuccess();
      dispatch(loader(false));
    } catch (error) {
      console.error("Error saving modifiers data:", error);
      dispatch(loader(false));
    }
  };
};

export const getModifiersData = () => async (dispatch) => {
  try {
    dispatch(loader(true));
    const snapshot = await firebase
      .firestore()
      .collection("modifiers")
      .limit(1)
      .get();
    const firstDocument = snapshot.docs[0];
    if (firstDocument) {
      const modifiersData = {
        id: firstDocument.id,
        ...firstDocument.data(),
      };
      dispatch({
        type: "GET_MODIFIERS_DATA",
        payload: modifiersData,
      });
    } else {
      console.log("No documents found in the Modifiers collection.");
    }
    dispatch(loader(false));
  } catch (error) {
    console.error("Error fetching and storing Modifiers data:", error);
    dispatch(loader(false));
  }
};

export const columnResize = (key, newSize, type) => async () => {
  try {
    const columnRef = firebase
      .firestore()
      .collection("hammerTrackerColumns")
      .where("key", "==", key)
      .where("type", "==", type);

    const querySnapshot = await columnRef.get();

    if (!querySnapshot.empty) {
      querySnapshot.forEach((doc) => {
        doc.ref.update({ columnSize: newSize });
      });
    } else {
      console.log("Column with the provided key and type does not exist.");
    }
  } catch (error) {
    console.error("Error updating column size:", error);
  }
};

export const getAllColumnData = () => async (dispatch) => {
  try {
    const columnRef = firebase.firestore().collection("hammerTrackerColumns");
    const querySnapshot = await columnRef.get();

    const allColumnData = [];

    if (!querySnapshot.empty) {
      querySnapshot.forEach((doc) => {
        allColumnData.push({ id: doc.id, ...doc.data() });
      });

      dispatch({ type: "GET_ALL_COLUMN_DATA_SUCCESS", payload: allColumnData });
    } else {
      console.log("No columns found in the collection.");
    }
  } catch (error) {
    console.error("Error fetching column data:", error);
    dispatch({ type: "GET_ALL_COLUMN_DATA_ERROR", payload: error });
  }
};

export const loader = (val) => async (dispatch) => {
  dispatch({
    type: "HAMMER_TRACKER_LOADING",
    payload: val,
  });
};
