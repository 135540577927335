import React, { useEffect, useState } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Input,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { getServerSettingsData } from "../../store/actions/serverSettingsAction";
import RPHReportParse from "./rphReportParse";
import { getRPHData } from "../../store/actions/playerTroopAction";
import RPHGraph from "./rphGraph";
import { getUsers } from "../../store/actions/dashboardAction";
import { getPermissions } from "../../store/actions/userpermissionsAction";

const RphTracker = () => {
  const dispatch = useDispatch();
  const { users } = useSelector((state) => state.dashboard);
  const { user } = useSelector((state) => state.auth);
  const { RPH, loading } = useSelector((state) => state.playerTroop);
  const { permission } = useSelector((state) => state.userPermissions);
  const [player, setPlayer] = useState();
  const [allUsers, setAllUsers] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [modalReport, setModalReport] = useState(false);
  const [resourcePerHour, setResourcePerHour] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const [condition, setCondition] = useState("");
  const toggleModal = () => setModalReport(!modalReport);

  //-------- Permissions ------- //

  const permissionData = permission?.playerTroop;
  const role = user?.role;
  const functionalRole = user?.functionalRole;

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setDropdownOpen(false);
    setPlayer(option);
  };

  const filteredData =
    resourcePerHour && resourcePerHour.find((data) => data?.player === player);
  let labels = [];
  let data = [];

  if (filteredData) {
    const rawData = filteredData.ResourcePerHour;
    if (rawData.length > 75) {
      for (let i = 0; i < rawData.length; i += 5) {
        labels.push(rawData[i].serverDay);
        data.push(rawData[i].rph);
      }
    } else if (rawData.length > 30) {
      for (let i = 0; i < rawData.length; i += 3) {
        labels.push(rawData[i].serverDay);
        data.push(rawData[i].rph);
      }
    } else {
      labels = rawData.map((data) => data.serverDay);
      data = rawData.map((data) => data.rph);
    }
  }

  const filteredUsers =
    allUsers &&
    allUsers.filter((user) =>
      user.toLowerCase().includes(searchQuery.toLowerCase())
    );

  useEffect(() => {
    if (RPH && RPH.length > 0) {
      setResourcePerHour(RPH);
    }
  }, [RPH]);

  useEffect(() => {
    if (user) {
      setPlayer(user?.gameAccountName);
    }
  }, [user]);

  useEffect(() => {
    if (users) {
      const uniqueUserNames = [
        ...new Set(users.map((user) => user?.gameAccountName)),
      ];
      setAllUsers(uniqueUserNames);
    }
  }, [users]);

  useEffect(() => {
    if (permission && permissionData) {
      const rolePermission = permissionData[role];
      const functionalPermission = permissionData[functionalRole];
      if (
        functionalPermission !== undefined &&
        functionalPermission.trim() !== ""
      ) {
        setCondition(functionalPermission);
      } else if (rolePermission !== undefined && rolePermission.trim() !== "") {
        setCondition(rolePermission);
      }
    }
  }, [permission, permissionData, role, functionalRole]);

  useEffect(() => {
    dispatch(getServerSettingsData());
    dispatch(getRPHData());
    dispatch(getUsers());
    dispatch(getPermissions());
  }, []);

  return (
    <>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center mt-5">
          <div className="text-center mt-5">
            <div className="spinner-border d-inline-block" role="status"></div>
          </div>
        </div>
      ) : (
        <>
          <div className=" justify-content-center align-items-center">
            <h3 className="text-secondary text-center">RPH Tracker</h3>
            <div className="d-flex justify-content-between">
              <Button
                color="info"
                className="py-2 text-white"
                onClick={toggleModal}
              >
                Update RPH
              </Button>
              {condition === "Full Access" && (
                <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                  <DropdownToggle
                    caret
                    color="info"
                    className="py-2 text-white"
                  >
                    {selectedOption || "Select Option"}
                  </DropdownToggle>
                  <DropdownMenu className="mt-1">
                    <div className="mx-1">
                      <Input
                        type="text"
                        placeholder="Search..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                      />
                    </div>
                    {filteredUsers.map((user, index) => (
                      <DropdownItem
                        key={index}
                        onClick={() => handleOptionSelect(user)}
                      >
                        {user}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </Dropdown>
              )}
            </div>
            <div className="mt-3 mb-5">
              {labels && data && <RPHGraph labels={labels} data={data} />}
            </div>
            <RPHReportParse toggle={toggleModal} modal={modalReport} />
          </div>
        </>
      )}
    </>
  );
};

export default RphTracker;
