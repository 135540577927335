import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Register from "./views/auth/Register";
import Login from "./views/auth/Login";
import Error from "./components/Error/Error";
import Logout from "./views/auth/Logout";
import HomeUser from "./views/HomeUser";
import UserAccounts from "./views/UserAccounts";
import { useDispatch } from "react-redux";
import {
  fetchUserData,
  getUidFromLocalStorage,
} from "./store/actions/authActions";
import UserPermissions from "./views/UserPermissions";
import PlayerRoaster from "./views/PlayerRoster";
import PermanentRoaster from "./views/PermanentRoaster";
import TroopGlossary from "./views/TroopGlossary";
import HammerTracker from "./views/HammerTracker";
import DefenderTracker from "./views/DefenderTracker";
import ServerSettings from "./views/ServerSettings";
import AllianceBonus from "./views/AllianceBonus";
import AlliancePushReports from "./views/AlliancePushReports";
import ResourcePush from "./views/ResourcePush";
import Trackers from "./views/Trackers";
import Pushes from "./views/Pushes";
import Admin from "./views/Admin";
import KnowledgeBase from "./views/KnowledgeBase";
import PlayerTroops from "./views/PlayerTroops";
import PlayerTools from "./views/PlayerTools";
import FarmSearch from "./views/FarmSearch";
import PlayerStatistics from "./views/PlayerStatistics";
import Navbar from "./components/Navbar/Navbar";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import PrivateRoute from "./layouts/PrivateRoute";
import InteractiveMap from "./views/InteractiveMap";

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const userId = localStorage.getItem("userId");
    if (userId) {
      dispatch(fetchUserData(userId));
      dispatch(getUidFromLocalStorage());
    }
  }, [dispatch]);

  return (
    <BrowserRouter>
      <Navbar />
      <DndProvider backend={HTML5Backend}>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route
            path="/"
            element={
              <PrivateRoute>
                <HomeUser />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin"
            element={
              <PrivateRoute>
                <Admin />
              </PrivateRoute>
            }
          />
          <Route
            path="/trackers"
            element={
              <PrivateRoute>
                <Trackers />
              </PrivateRoute>
            }
          />
          <Route
            path="/pushes"
            element={
              <PrivateRoute>
                <Pushes />
              </PrivateRoute>
            }
          />
          <Route
            path="/knowledge-base"
            element={
              <PrivateRoute>
                <KnowledgeBase />
              </PrivateRoute>
            }
          />
          <Route
            path="/user-accounts"
            element={
              <PrivateRoute>
                <UserAccounts />
              </PrivateRoute>
            }
          />
          <Route
            path="/user-permissions"
            element={
              <PrivateRoute>
                <UserPermissions />
              </PrivateRoute>
            }
          />
          <Route
            path="/server-settings"
            element={
              <PrivateRoute>
                <ServerSettings />
              </PrivateRoute>
            }
          />
          <Route
            path="/permanent-roster"
            element={
              <PrivateRoute>
                <PermanentRoaster />
              </PrivateRoute>
            }
          />
          <Route
            path="/player-roster"
            element={
              <PrivateRoute>
                <PlayerRoaster />
              </PrivateRoute>
            }
          />
          <Route
            path="/player-troops"
            element={
              <PrivateRoute>
                <PlayerTroops />
              </PrivateRoute>
            }
          />
          <Route
            path="/troop-glossary"
            element={
              <PrivateRoute>
                <TroopGlossary />
              </PrivateRoute>
            }
          />
          <Route
            path="/hammer-tracker"
            element={
              <PrivateRoute>
                <HammerTracker />
              </PrivateRoute>
            }
          />
          <Route
            path="/defender-tracker"
            element={
              <PrivateRoute>
                <DefenderTracker />
              </PrivateRoute>
            }
          />
          <Route
            path="/resource-push"
            element={
              <PrivateRoute>
                <ResourcePush />
              </PrivateRoute>
            }
          />
          <Route
            path="/alliance-bonus"
            element={
              <PrivateRoute>
                <AllianceBonus />
              </PrivateRoute>
            }
          />
          <Route
            path="/push-reports"
            element={
              <PrivateRoute>
                <AlliancePushReports />
              </PrivateRoute>
            }
          />
          <Route
            path="/player-tools"
            element={
              <PrivateRoute>
                <PlayerTools />
              </PrivateRoute>
            }
          />
          <Route
            path="/farm-search"
            element={
              <PrivateRoute>
                <FarmSearch />
              </PrivateRoute>
            }
          />
          <Route
            path="/player-statistics"
            element={
              <PrivateRoute>
                <PlayerStatistics />
              </PrivateRoute>
            }
          />
          <Route
            path="/interactive-map"
            element={
              <PrivateRoute>
                <InteractiveMap />
              </PrivateRoute>
            }
          />
          <Route path="/logout" element={<Logout />} />
          <Route path="*" element={<Error />} />
        </Routes>
      </DndProvider>
    </BrowserRouter>
  );
};

export default App;
