import React, { useRef, useEffect, useState } from "react";
import {
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Spinner,
  Row,
} from "reactstrap";
import { getServerSettingsData } from "../../store/actions/serverSettingsAction";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchFilteredVillages,
  fetchMapCoordinates,
  fetchMapData,
  fetchVillagesByPlayerName,
} from "../../store/actions/mapAction";
import TileDetailModal from "./TileDetailModal";
import FavoriteCoordinates from "./FavoriteCoordinates";
import Flags from "./Flags";
import BonnieVillages from "./BonnieVillages";
import moment from "moment-timezone";

const CustomMap = ({
  timezone = "Europe/London",
  selectedAlliances,
  selectedPlayers,
  selectedVillages,
}) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const { serverSettingsData } = useSelector((state) => state.serverSettings);
  const { favoriteCoordinates } = useSelector(
    (state) => state.favoriteCoordinate
  );
  const { flags, villages, allianceFlags } = useSelector((state) => state.flag);
  const { bonnieVillages } = useSelector((state) => state.bonnieVillage);
  const { allianceControlZone } = useSelector((state) => state.controlZone);
  const { filteredVillages, playerVillages } = useSelector(
    (state) => state.map
  );

  const canvasRef = useRef(null);
  const [scale, setScale] = useState(1);
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [hasDragged, setHasDragged] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [hoveredBox, setHoveredBox] = useState(null);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [serverUrl, setServerUrl] = useState(null);
  const [mapImage, setMapImage] = useState(null);
  const [mapChunks, setMapChunks] = useState([]);
  const [mapCoordinatesData, setMapCoordinatesData] = useState(null);
  const [clickedBox, setClickedBox] = useState({ x: 0, y: 0 });
  const [xCoordinate, setXCoordinate] = useState(null);
  const [yCoordinate, setYCoordinate] = useState(null);
  const [chunkSize, setChunkSize] = useState(11);

  const [hoveredCoordinateFromData, setHoveredCoordinateFromData] = useState({
    x: "",
    y: "",
  });

  const CANVAS_WIDTH = 440;
  const CANVAS_HEIGHT = 440;
  const CELL_SIZE = CANVAS_WIDTH / chunkSize;
  // const { largestX, largestY } = serverSettingsData;
  // const GRID_SIZE = largestX + largestY + 1;
  const GRID_SIZE = 401;

  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleZoomChange = (zoomLevel) => {
    setScale(zoomLevel);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const clamp = (value, min, max) => Math.max(min, Math.min(value, max));

  const handleWheel = (event) => {
    event.preventDefault();
    const scaleAmount = 0.1;
    const newScale = clamp(
      scale + (event.deltaY > 0 ? -scaleAmount : scaleAmount),
      0.1,
      1
    );
    setScale(newScale);
    const canvas = canvasRef.current;
    const mapWidth = GRID_SIZE * 40 * newScale;
    const mapHeight = GRID_SIZE * 40 * newScale;
    const rect = canvas.getBoundingClientRect();
    const maxOffsetX = mapWidth / 2 - rect.width / 2;
    const maxOffsetY = mapHeight / 2 - rect.height / 2;
    setOffset({
      x: clamp(offset.x, -maxOffsetX, maxOffsetX),
      y: clamp(offset.y, -maxOffsetY, maxOffsetY),
    });
  };

  const handleMouseDown = (event) => {
    setIsDragging(true);
    setDragStart({ x: event.clientX - offset.x, y: event.clientY - offset.y });
    setHasDragged(false);
  };

  const handleMouseMove = (event) => {
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();

    if (isDragging) {
      const mapWidth = GRID_SIZE * 40 * scale;
      const mapHeight = GRID_SIZE * 40 * scale;
      const maxOffsetX = mapWidth / 2 - rect.width / 2;
      const maxOffsetY = mapHeight / 2 - rect.height / 2;
      const newOffsetX = event.clientX - dragStart.x;
      const newOffsetY = event.clientY - dragStart.y;
      setOffset({
        x: clamp(newOffsetX, -maxOffsetX, maxOffsetX),
        y: clamp(newOffsetY, -maxOffsetY, maxOffsetY),
      });
      setHasDragged(true);
    }
    const x =
      event.clientX -
      rect.left -
      canvas.width / 2 -
      offset.x +
      chunkSize +
      Math.ceil(chunkSize / 2);
    const y =
      event.clientY -
      rect.top -
      canvas.height / 2 -
      offset.y +
      chunkSize +
      Math.ceil(chunkSize / 2);
    const gridSize = Math.round(CANVAS_WIDTH / chunkSize);
    const boxX = Math.floor(x / gridSize / scale) * gridSize;
    const boxY = Math.floor(y / gridSize / scale) * gridSize;
    setHoveredBox({
      x: Math.min(boxX / gridSize, 200),
      y: Math.min(-boxY / gridSize, 200),
    });
    setMousePosition({ x: event.clientX, y: event.clientY });
  };

  const handleMouseLeave = () => {
    setHoveredBox(null);
  };

  const handleClick = async (event) => {
    if (hasDragged) return;
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const x = event.clientX - rect.left - canvas.width / 2 - offset.x;
    const y = event.clientY - rect.top - canvas.height / 2 - offset.y;
    const gridSize = 40;
    const boxX = Math.floor(x / gridSize / scale) * gridSize;
    const boxY = Math.floor(y / gridSize / scale) * gridSize;
    const coordinates = await dispatch(
      fetchMapCoordinates({ x: boxX / gridSize, y: -boxY / gridSize })
    );
    setMapCoordinatesData(coordinates);
    setClickedBox({ x: boxX / gridSize, y: -boxY / gridSize });
    setModalIsOpen(true);
  };

  const handleZoomIn = () => {
    setScale((prevScale) => {
      if (prevScale === 0.25) return 0.5;
      if (prevScale === 0.5) return 1;
      return prevScale;
    });
  };

  const handleZoomOut = () => {
    setScale((prevScale) => {
      if (prevScale === 1) return 0.5;
      if (prevScale === 0.5) return 0.25;
      return prevScale;
    });
  };

  const getVisibleAreaCoordinates = () => {
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const topLeftX = (-offset.x - rect.width / 2) / scale;
    const topLeftY = (-offset.y - rect.height / 2) / scale;
    const bottomRightX = (-offset.x + rect.width / 2) / scale;
    const bottomRightY = (-offset.y + rect.height / 2) / scale;
    const topLeft = {
      x: topLeftX / (CANVAS_WIDTH / chunkSize),
      y: -topLeftY / (CANVAS_WIDTH / chunkSize),
    };
    const bottomRight = {
      x: bottomRightX / (CANVAS_WIDTH / chunkSize),
      y: -bottomRightY / (CANVAS_WIDTH / chunkSize),
    };
    return { topLeft, bottomRight };
  };

  const convertToVisibleArea = (visibleArea) => {
    let xMin = visibleArea.topLeft.x;
    let yMin = visibleArea.bottomRight.y;
    let xMax = visibleArea.bottomRight.x;
    let yMax = visibleArea.topLeft.y;
    if (xMin > 0) {
      xMin += 1;
    }
    if (yMin > 0) {
      yMin += 1;
    }
    if (xMax < 0) {
      xMax -= 1;
    }
    if (yMax < 0) {
      yMax -= 1;
    }
    const limit = Math.floor(GRID_SIZE / 2);
    return {
      xMin: mapImage
        ? Math.min(limit, parseInt(xMin))
        : Math.min(limit, parseInt(visibleArea.topLeft.x)),
      yMin: mapImage
        ? Math.min(limit, parseInt(yMin))
        : Math.min(limit, parseInt(visibleArea.bottomRight.y)),
      xMax: mapImage
        ? Math.min(limit, parseInt(xMax))
        : Math.min(limit, parseInt(visibleArea.bottomRight.x)),
      yMax: mapImage
        ? Math.min(limit, parseInt(yMax))
        : Math.min(limit, parseInt(visibleArea.topLeft.y)),
    };
  };

  const getVisibleChunks = () => {
    const visibleArea = getVisibleAreaCoordinates();
    const { topLeft, bottomRight } = visibleArea;
    console.log("visibleArea: ", visibleArea);

    const filteredChunks = mapChunks.filter((chunk) => {
      const { topLeft: chunkTopLeft, bottomRight: chunkBottomRight } =
        chunk.offset;
      return (
        chunkTopLeft.x < bottomRight.x &&
        chunkBottomRight.x > topLeft.x &&
        chunkTopLeft.y > bottomRight.y &&
        chunkBottomRight.y < topLeft.y
      );
    });

    const sortedChunks = filteredChunks.sort((a, b) => {
      const center = { x: 0, y: 0 };

      const chunkACenter = {
        x: (a.offset.topLeft.x + a.offset.bottomRight.x) / 2,
        y: (a.offset.topLeft.y + a.offset.bottomRight.y) / 2,
      };

      const chunkBCenter = {
        x: (b.offset.topLeft.x + b.offset.bottomRight.x) / 2,
        y: (b.offset.topLeft.y + b.offset.bottomRight.y) / 2,
      };

      const distanceA = Math.sqrt(
        Math.pow(chunkACenter.x - center.x, 2) +
          Math.pow(chunkACenter.y - center.y, 2)
      );
      const distanceB = Math.sqrt(
        Math.pow(chunkBCenter.x - center.x, 2) +
          Math.pow(chunkBCenter.y - center.y, 2)
      );

      return distanceA - distanceB;
    });

    return sortedChunks;
  };

  const fetchVisibleArea = async () => {
    try {
      const visibleArea = getVisibleAreaCoordinates();
      const visibleChunks = getVisibleChunks(visibleArea);
      console.log("visibleChunks: ", visibleChunks);

      for (const chunk of visibleChunks) {
        if (!chunk.image) {
          const blob = await fetchMapData(convertToVisibleArea(chunk?.offset));

          const image = new Image();
          const objectURL = URL.createObjectURL(blob);
          image.src = objectURL;

          image.onload = () => {
            if (!mapImage) {
              setMapImage(image);
            }
            setMapChunks((prevMapChunks) => {
              const updatedMap = prevMapChunks.map((existingChunk) =>
                existingChunk.offset.topLeft.x === chunk.offset.topLeft.x &&
                existingChunk.offset.topLeft.y === chunk.offset.topLeft.y
                  ? { ...existingChunk, image }
                  : existingChunk
              );
              URL.revokeObjectURL(objectURL);
              return updatedMap;
            });
          };
        }
      }
    } catch (error) {
      console.error("Error fetching map data:", error);
    }
  };

  const calculateDrawPosition = (topLeft, bottomRight) => {
    const range = CANVAS_WIDTH / chunkSize;
    const width = bottomRight.x - topLeft.x;
    const height = bottomRight.y - topLeft.y;

    let scaleX = CANVAS_WIDTH / width;
    let scaleY = CANVAS_HEIGHT / height;
    scaleX = Math.max(Math.min(scaleX, range), -range);
    scaleY = Math.max(Math.min(scaleY, range), -range);

    const centerX = (bottomRight.x + topLeft.x) / 2;
    const centerY = (bottomRight.y + topLeft.y) / 2;

    const dx = centerX * scaleX;
    const dy = centerY * scaleY;

    return { dx, dy, width, height };
  };

  const generateChunks = (gridSize, chunkSize) => {
    const chunks = [];
    const halfGridSize = gridSize / 2;

    const clamp = (value, min, max) => Math.max(min, Math.min(value, max));

    for (
      let row = -Math.floor(gridSize / (2 * chunkSize));
      row <= Math.floor(gridSize / (2 * chunkSize));
      row++
    ) {
      for (
        let col = -Math.floor(gridSize / (2 * chunkSize));
        col <= Math.floor(gridSize / (2 * chunkSize));
        col++
      ) {
        const topLeft = {
          x: clamp(
            col * chunkSize - chunkSize / 2,
            -halfGridSize,
            halfGridSize
          ),
          y: clamp(
            row * chunkSize + chunkSize / 2,
            -halfGridSize,
            halfGridSize
          ),
        };
        const bottomRight = {
          x: clamp(
            col * chunkSize + chunkSize / 2,
            -halfGridSize,
            halfGridSize
          ),
          y: clamp(
            row * chunkSize - chunkSize / 2,
            -halfGridSize,
            halfGridSize
          ),
        };

        chunks.push({
          offset: {
            topLeft,
            bottomRight,
          },
          image: null,
        });
      }
    }

    return chunks;
  };

  const updateOffsetWithCoordinates = (coordinate) => {
    const mapWidth = GRID_SIZE * 40 * scale;
    const mapHeight = GRID_SIZE * 40 * scale;

    const maxOffsetX = mapWidth / 2 - CANVAS_WIDTH / 2;
    const maxOffsetY = mapHeight / 2 - CANVAS_HEIGHT / 2;

    const newOffsetX = -coordinate?.x * scale;
    const newOffsetY = coordinate?.y * scale;
    setOffset({
      x: clamp(newOffsetX * CELL_SIZE, -maxOffsetX, maxOffsetX),
      y: clamp(newOffsetY * CELL_SIZE, -maxOffsetY, maxOffsetY),
    });
  };

  const updateHoveredCoordinateFromData = (coordinate) => {
    setHoveredCoordinateFromData({
      x: coordinate.x,
      y: coordinate.y,
    });
  };

  if (!mapChunks?.length) {
    const chunks = generateChunks(GRID_SIZE, chunkSize);
    setMapChunks(chunks);
  }

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    const drawGrid = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      const scaleTranslateMap = {
        0.25: 1.5,
        0.5: 0.5,
        1: 0,
      };

      ctx.save();
      ctx.translate(canvas.width + offset.x, canvas.height + offset.y);
      ctx.scale(scale, scale);
      ctx.translate(
        -canvas.width * scaleTranslateMap[scale],
        -canvas.height * scaleTranslateMap[scale]
      );

      mapChunks.forEach((chunk) => {
        const { offset, image } = chunk;

        if (image) {
          const topLeft = offset.topLeft;
          const bottomRight = offset.bottomRight;

          let imageWidth = canvas.width;
          let imageHeight = canvas.height;
          let { dx, dy, width, height } = calculateDrawPosition(
            topLeft,
            bottomRight
          );
          // If chunk is less then chunk size (in edges)
          if (Math.abs(width) < 11) {
            const diff = 11 - Math.abs(width);
            const variation = (CELL_SIZE / 2) * diff;
            dx = dx - variation;
            imageWidth = width * CELL_SIZE;
          }
          if (Math.abs(height) < 11) {
            const diff = 11 - Math.abs(height);
            const variation = (CELL_SIZE / 2) * diff;
            dy = dy - (CANVAS_HEIGHT - variation);
            imageHeight = height * CELL_SIZE;
          }
          ctx.drawImage(image, dx, dy, -imageWidth, -imageHeight);
        }
      });

      if (mapImage) {
        if (
          playerVillages?.length &&
          flags.some((flag) => flag?.markType === "player")
        ) {
          console.log("playerVillages: ", playerVillages);
          const flagColor = flags.find(
            (flag) => flag?.markType === "player"
          )?.color;
          playerVillages.forEach((village) => {
            const { x, y } = village?.hits[0]?.document;
            if (!x || !y) {
              return;
            }
            const fontSize = 16;
            const position = 0;

            ctx.font = `${fontSize}px FontAwesome`;
            ctx.fillStyle = flagColor || "black";
            ctx.fillText(
              "\uf024",
              -canvas.width / 2 - CELL_SIZE / 2 + x * 40 + 1,
              -canvas.height / 2 -
                CELL_SIZE / 2 -
                y * 40 +
                position +
                (fontSize - 2)
            );
          });
        }

        [...villages, ...flags, ...allianceFlags].forEach((flag) => {
          if (!flag.coordinates) {
            return;
          }
          const fontSize = flag?.type === "alliance" ? 20 : 16;
          const position = flag?.type === "alliance" ? 18 : 0;

          ctx.font = `${fontSize}px FontAwesome`;
          ctx.fillStyle = flag?.color || "black";
          ctx.fillText(
            "\uf024",
            -canvas.width / 2 - CELL_SIZE / 2 + flag.coordinates.x * 40 + 1,
            -canvas.height / 2 -
              CELL_SIZE / 2 -
              flag.coordinates.y * 40 +
              position +
              (fontSize - 2)
          );
        });

        bonnieVillages.forEach((village) => {
          const tileRadius = 19;
          const positionX =
            -canvas.width / 2 -
            CELL_SIZE / 2 +
            village.coordinates.x * CELL_SIZE;
          const positionY =
            -canvas.height / 2 -
            CELL_SIZE / 2 -
            village.coordinates.y * CELL_SIZE;

          ctx.globalAlpha = 0.25;
          ctx.fillStyle = "red";
          ctx.fillRect(positionX, positionY, CELL_SIZE, CELL_SIZE);
          ctx.globalAlpha = 1;
          ctx.strokeStyle = "red";
          ctx.lineWidth = 2;
          ctx.strokeRect(
            positionX - (CELL_SIZE / 2) * (tileRadius - 1),
            positionY - (CELL_SIZE / 2) * (tileRadius - 1),
            tileRadius * CELL_SIZE,
            tileRadius * CELL_SIZE
          );
          // For circle
          // ctx.strokeStyle = "red";
          // ctx.lineWidth = 2;
          // ctx.beginPath();
          // ctx.arc(
          //   positionX,
          //   positionY,
          //   (CELL_SIZE / 2) * tileRadius,
          //   0,
          //   2 * Math.PI
          // );
          // ctx.stroke();
        });

        favoriteCoordinates.forEach((favorite) => {
          const fontSize = 15;
          ctx.font = `${fontSize}px FontAwesome`;
          ctx.fillStyle = "green";
          ctx.fillText(
            "\uf004",
            -canvas.width / 2 -
              CELL_SIZE / 2 +
              favorite.coordinates.x * CELL_SIZE +
              25,
            -canvas.height / 2 -
              CELL_SIZE / 2 -
              favorite.coordinates.y * CELL_SIZE +
              (fontSize - 2)
          );
        });

        if (allianceControlZone) {
          const { coordinates, color } = allianceControlZone;
          const centerX = canvas.width / 2;
          const centerY = canvas.height / 2;

          const canvasPoints = coordinates.map(({ x, y }) => {
            x = x < 0 ? (x = x - 0.5) : (x = x + 0.5);
            y = y < 0 ? (y = y - 0.5) : (y = y + 0.5);
            return {
              x: -centerX + x * CELL_SIZE,
              y: -centerY - y * CELL_SIZE,
            };
          });

          ctx.beginPath();
          ctx.moveTo(canvasPoints[0].x, canvasPoints[0].y);
          for (let i = 1; i < canvasPoints.length; i++) {
            ctx.lineTo(canvasPoints[i].x, canvasPoints[i].y);
          }
          ctx.closePath();

          ctx.globalAlpha = 0.25;
          ctx.fillStyle = color || "orange";
          ctx.fill();
          ctx.globalAlpha = 1;

          ctx.strokeStyle = color || "orange";
          ctx.lineWidth = 2;
          ctx.stroke();
        }

        if (filteredVillages?.length) {
          filteredVillages?.forEach((village) => {
            const { x, y } = village?.hits[0]?.document || {};
            if (!x || !y) return;
            const positionX = -canvas.width / 2 - CELL_SIZE / 2 + x * CELL_SIZE;
            const positionY =
              -canvas.height / 2 - CELL_SIZE / 2 - y * CELL_SIZE;

            let borderColor = "blue";

            ctx.strokeStyle = borderColor;
            ctx.lineWidth = 2;
            ctx.strokeRect(positionX, positionY, CELL_SIZE, CELL_SIZE);
          });
        }
      }

      const gridSize = CANVAS_WIDTH / chunkSize;
      ctx.globalAlpha = 0.25;
      ctx.strokeStyle = "gray";
      ctx.fillStyle = "transparent";
      ctx.lineWidth = 1;

      for (
        let x = -GRID_SIZE * gridSize;
        x <= GRID_SIZE * gridSize;
        x += gridSize
      ) {
        for (
          let y = -GRID_SIZE * gridSize;
          y <= GRID_SIZE * gridSize;
          y += gridSize
        ) {
          ctx.beginPath();
          ctx.rect(x, y, gridSize, gridSize);
          ctx.stroke();
          ctx.fill();
        }
      }
      ctx.globalAlpha = 1;

      if (
        hoveredCoordinateFromData.x !== "" &&
        hoveredCoordinateFromData.y !== ""
      ) {
        ctx.globalAlpha = 0.5;
        const positionX =
          -canvas.width / 2 -
          CELL_SIZE / 2 +
          hoveredCoordinateFromData.x * CELL_SIZE;
        const positionY =
          -canvas.height / 2 -
          CELL_SIZE / 2 -
          hoveredCoordinateFromData.y * CELL_SIZE;

        ctx.fillStyle = "yellow";
        ctx.fillRect(positionX, positionY, CELL_SIZE, CELL_SIZE);
        ctx.globalAlpha = 1;
      }
      ctx.restore();
    };

    drawGrid();
  }, [
    offset,
    scale,
    mapChunks,
    flags,
    allianceFlags,
    favoriteCoordinates,
    bonnieVillages,
    hoveredCoordinateFromData,
    allianceControlZone,
    filteredVillages,
  ]);

  useEffect(() => {
    if (!isDragging) {
      const debounceTimeout = setTimeout(() => {
        fetchVisibleArea();
      }, 3000);
      return () => clearTimeout(debounceTimeout);
    }
  }, [scale, offset, isDragging]);

  useEffect(() => {
    if (
      selectedAlliances?.length ||
      selectedPlayers?.length ||
      selectedVillages?.length
    ) {
      const todayUnix = moment.tz(timezone).startOf("day").unix();

      dispatch(
        fetchFilteredVillages({
          selectedAlliances,
          selectedPlayers,
          selectedVillages,
          currentDate: todayUnix,
        })
      );
    }
  }, [selectedAlliances, selectedPlayers, selectedVillages]);

  useEffect(() => {
    if (user?.gameAccountName) {
      const todayUnix = moment.tz(timezone).startOf("day").unix();
      dispatch(
        fetchVillagesByPlayerName({
          playerName: user?.gameAccountName,
          currentDate: todayUnix,
        })
      );
    }
  }, [user]);

  useEffect(() => {
    if (serverSettingsData && serverSettingsData.length > 0) {
      const data = serverSettingsData[0];
      setServerUrl(data?.serverUrl);
    }
  }, [serverSettingsData]);

  useEffect(() => {
    dispatch(getServerSettingsData());
  }, []);

  return (
    <>
      <div className="d-flex flex-column justify-content-center align-items-start align-self-start">
        <div className="mb-2 d-flex gap-2">
          <Button
            color="primary"
            onClick={handleZoomIn}
            className="px-3"
            disabled={scale === 1}
          >
            +
          </Button>
          <Button
            color="primary"
            onClick={handleZoomOut}
            className="px-3"
            disabled={scale === 0.25}
          >
            -
          </Button>
          <Dropdown
            isOpen={dropdownOpen}
            toggle={toggleDropdown}
            className="mx-2"
          >
            <DropdownToggle caret color="primary">
              Zoom
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => handleZoomChange(0.25)}>
                25%
              </DropdownItem>
              <DropdownItem onClick={() => handleZoomChange(0.5)}>
                50%
              </DropdownItem>
              <DropdownItem onClick={() => handleZoomChange(1)}>
                100%
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
        <div className="d-flex">
          <div className="position-relative">
            {!mapImage && (
              <div className="position-absolute top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center bg-white bg-opacity-75 z-10">
                <Spinner color="primary" />
              </div>
            )}
            <div
              style={{
                width: CANVAS_WIDTH,
                height: CANVAS_HEIGHT,
                boxSizing: "content-box",
                border: "2px solid black",
              }}
            >
              <canvas
                ref={canvasRef}
                width={CANVAS_WIDTH}
                height={CANVAS_HEIGHT}
                style={{
                  cursor: isDragging ? "grabbing" : "grab",
                }}
                onWheel={handleWheel}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseLeave={handleMouseLeave}
                onMouseUp={handleMouseUp}
                onClick={handleClick}
              />
            </div>
            <div
              className="position-absolute bg-black text-white p-2 rounded"
              style={{ bottom: "20px", right: "15px" }}
            >
              <div>
                <label htmlFor="x-coordinate">X:</label>
                <input
                  id="x-coordinate"
                  type="number"
                  value={xCoordinate}
                  onChange={(e) => setXCoordinate(Number(e.target.value))}
                  style={{ width: "50px", marginRight: "5px" }}
                />
                <label htmlFor="y-coordinate">Y:</label>
                <input
                  id="y-coordinate"
                  type="number"
                  value={yCoordinate}
                  onChange={(e) => setYCoordinate(Number(e.target.value))}
                  style={{ width: "50px", marginRight: "5px" }}
                />
                <Button
                  color="success"
                  onClick={() =>
                    updateOffsetWithCoordinates({
                      x: xCoordinate,
                      y: yCoordinate,
                    })
                  }
                >
                  OK
                </Button>
              </div>
            </div>
          </div>
        </div>

        {/* Hovered Box  */}
        {hoveredBox && (
          <div
            className="py-2 px-4 position-absolute text-white rounded"
            style={{
              top: mousePosition.y + 10 + "px",
              left: mousePosition.x + 10 + "px",
              backgroundColor: "rgba(0, 0, 0, 0.7)",
              zIndex: 1000,
            }}
          >
            <div>X: {hoveredBox.x}</div>
            <div>Y: {hoveredBox.y}</div>
            <div>
              {flags?.find(
                (flag) =>
                  flag.coordinates &&
                  flag?.coordinates?.x === hoveredBox.x &&
                  flag?.coordinates?.y === hoveredBox.y
              ) && (
                <>
                  Note:{" "}
                  {
                    flags.find(
                      (flag) =>
                        flag.coordinates &&
                        flag.coordinates.x === hoveredBox.x &&
                        flag.coordinates.y === hoveredBox.y
                    ).label
                  }
                </>
              )}
            </div>
          </div>
        )}

        <TileDetailModal
          isOpen={modalIsOpen}
          closeModal={closeModal}
          clickedBox={clickedBox}
          mapCoordinatesData={mapCoordinatesData}
          serverUrl={serverUrl}
        />
      </div>
      <div className="d-flex flex-column align-self-start">
        <FavoriteCoordinates
          updateOffsetWithCoordinates={updateOffsetWithCoordinates}
        />
        <br />
        <Flags
          hoveredBox={hoveredBox}
          updateOffsetWithCoordinates={updateOffsetWithCoordinates}
          updateHoveredCoordinateFromData={updateHoveredCoordinateFromData}
        />
        <br />
        <BonnieVillages
          updateOffsetWithCoordinates={updateOffsetWithCoordinates}
        />
      </div>
    </>
  );
};

export default CustomMap;
