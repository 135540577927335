import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "../../assets/css/defenderTracker.css";
import {
  addNewDateColumn,
  addNewRow,
  deleteDateColumn,
  deleteTrackerRow,
  getDateColumns,
  getTrackerData,
  saveTrackerData,
} from "../../store/actions/defenderTrackerAction";
import LineChart from "./Graph";
import { getPermissions } from "../../store/actions/userpermissionsAction";

const TrackerMain = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { permission } = useSelector((state) => state.userPermissions);
  const { TrackerData, dateColumn, loading } = useSelector(
    (state) => state.defenderTracker
  );
  const [editedIndex, setEditedIndex] = useState(null);
  const [editedFieldName, setEditedFieldName] = useState(null);
  const [editedValue, setEditedValue] = useState(null);
  const [trackerData, setTrackerData] = useState([]);
  const [sortColumn, setSortColumn] = useState(null);
  const [selectedDate, setSelectedDate] = useState("");
  const [sortedDateColumns, setSortedDateColumns] = useState([]);
  const [predictionStartIndex, setPredictionStartIndex] = useState(null);
  const [condition, setCondition] = useState("");
  const [formData, setFormData] = useState({
    allianceName: "",
    lineColor: "",
  });

  //-------------Roles--------------//

  const permissionData = permission?.defenderTracker;
  const role = user?.role;
  const functionalRole = user?.functionalRole;

  //--------- Functions---------//

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value,
    });
  };

  const handleSubmit = async () => {
    try {
      await dispatch(addNewRow(formData));
      setFormData({
        allianceName: "",
        lineColor: "",
      });
      dispatch(getTrackerData());
    } catch (error) {
      toast.error("Error adding new row");
    }
  };

  const handleDelete = async (id) => {
    try {
      await dispatch(deleteTrackerRow(id));
      dispatch(getTrackerData());
    } catch (error) {
      toast.error("Failed to delete row");
    }
  };

  const handleSave = async (rowId) => {
    try {
      const updatedRow = TrackerData.find((row) => row.id === rowId);
      if (updatedRow) {
        const updatedData = { ...updatedRow, [editedFieldName]: editedValue };
        await dispatch(saveTrackerData([updatedData]));
        setEditedValue(null);
        setEditedIndex(null);
        setEditedFieldName(null);
        toast.success("Data updated successfully")
      } else {
        toast.error(`Row with id ${rowId} not found`);
      }
    } catch (error) {
      toast.error("Failed to update data");
    }
  };

  const handleKeyDown = (e, rowId) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSave(rowId);
      dispatch(getTrackerData());
    }
  };

  const handleClickOutside = (e) => {
    if (
      !e.target.closest("input") &&
      !e.target.closest(".tracker-table-cell")
    ) {
      setEditedIndex(null);
      setEditedValue(null);
      setEditedFieldName(null);
    }
  };

  //---------Add/Remove Column ------------//

  const formatDateFieldName = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = new Intl.DateTimeFormat("en-US", { month: "short" }).format(
      date
    );
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const handleAddColumn = () => {
    if (!selectedDate) {
      toast.error("Please select a date for the new column.");
      return;
    }

    const formattedDateFieldName = formatDateFieldName(selectedDate);

    if (dateColumn.some((col) => col.fieldName === formattedDateFieldName)) {
      toast.error("A column with this date already exists.");
      return;
    }

    const payload = {
      name: selectedDate,
      fieldName: formattedDateFieldName,
      isDynamic: true,
      type: "number",
    };

    dispatch(addNewDateColumn(payload));
    dispatch(getDateColumns());
    dispatch(getTrackerData());
    setSelectedDate("");
  };

  const handleRemoveColumn = async (column) => {
    try {
      await dispatch(deleteDateColumn(column.id));
      dispatch(getDateColumns());
    } catch (error) {
      toast.error("Failed to remove column");
    }
  };

  //--------- Functions to handle Sorting-------- //

  const handleSort = (column, option) => {
    let sortedUsers = [...trackerData];

    if (option === "asc") {
      sortedUsers.sort((a, b) => compareValues(a[column], b[column]));
    } else if (option === "desc") {
      sortedUsers.sort((a, b) => compareValues(b[column], a[column]));
    }

    setSortColumn(column);
    setTrackerData(sortedUsers);
  };

  const compareValues = (valueA, valueB) => {
    if (valueA === valueB) {
      return 0;
    }
    return valueA < valueB ? -1 : 1;
  };

  const clearFilters = () => {
    setSortColumn(null);
    setTrackerData(TrackerData);
  };

  //--------------- Prediction/Graph Calculations ----------------//

  const applyCalculations = (labels, data) => {
    const numericData = data.map((value) => parseFloat(value));

    const n = labels.length;
    const meanX = (n - 1) / 2;
    const meanY = numericData.reduce((acc, val) => acc + val, 0) / n;

    let numerator = 0;
    let denominator = 0;
    for (let i = 0; i < n; i++) {
      numerator += (i - meanX) * (numericData[i] - meanY);
      denominator += (i - meanX) ** 2;
    }
    const slope = numerator / denominator;
    const yIntercept = meanY - slope * meanX;

    const numValuesToPredict = Math.ceil(0.3 * n);

    const lastDate = new Date(labels[labels.length - 1]);

    const differenceInDays = new Date(labels[1]) - new Date(labels[0]);

    const nextPredictionsLabels = Array.from(
      { length: numValuesToPredict },
      (_, index) => {
        const nextDate = new Date(
          lastDate.getTime() + differenceInDays * (index + 1)
        );
        return nextDate;
      }
    );

    const formattedNextLabels = nextPredictionsLabels.map((date) => {
      const day = date.getDate();
      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      const monthIndex = date.getMonth();
      const year = date.getFullYear();
      return `${day}-${monthNames[monthIndex]}-${year}`;
    });

    const combinedLabels = [...labels, ...formattedNextLabels];

    const nextPredictions = Array.from(
      { length: numValuesToPredict },
      (_, index) => {
        const nextIndex = n + index;
        return slope * nextIndex + yIntercept;
      }
    );

    const combinedData = [...numericData, ...nextPredictions];

    return { labels: combinedLabels, data: combinedData };
  };

  const datasets = trackerData.map((entry) => {
    const labels = sortedDateColumns.map((column) => column.fieldName);
    const data = labels.map((label) => entry[label]);
    const { labels: calculatedLabels, data: calculatedData } =
      applyCalculations(labels, data);

    return {
      label: entry.allianceName,
      labels: calculatedLabels,
      data: calculatedData,
      borderColor: entry.lineColor,
      borderWidth: 1,
    };
  });

  //------------ UseEffects---------//

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (TrackerData) {
      setTrackerData(TrackerData);
    }
  }, [TrackerData, dateColumn]);

  useEffect(() => {
    if (editedIndex !== null && editedFieldName !== null) {
      const updatedRow = TrackerData.find((row) => row.id === editedIndex);
      if (updatedRow) {
        const updatedData = { ...updatedRow, [editedFieldName]: editedValue };
        dispatch(saveTrackerData([updatedData]));
      }
    }
  }, [editedIndex, editedFieldName, editedValue, TrackerData, dispatch]);

  useEffect(() => {
    const dateColumns = dateColumn.filter(
      (column) => column.type === "number" && column.isDynamic
    );

    dateColumns.sort((a, b) => new Date(a.fieldName) - new Date(b.fieldName));

    setSortedDateColumns(dateColumns);
  }, [dateColumn, dispatch]);

  useEffect(() => {
    if (sortedDateColumns.length > 0) {
      const lastIndex = sortedDateColumns.length - 1;
      setPredictionStartIndex(lastIndex);
    }
  }, [sortedDateColumns]);

  useEffect(() => {
    if (permission && permissionData) {
      const rolePermission = permissionData[role];
      const functionalPermission = permissionData[functionalRole];
      if (
        functionalPermission !== undefined &&
        functionalPermission.trim() !== ""
      ) {
        setCondition(functionalPermission);
      } else if (rolePermission !== undefined && rolePermission.trim() !== "") {
        setCondition(rolePermission);
      }
    }
  }, [permission, permissionData, role, functionalRole]);

  useEffect(() => {
    dispatch(getDateColumns());
    dispatch(getTrackerData());
    dispatch(getPermissions());
  }, []);

  return (
    <>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center mt-5">
          <div className="text-center mt-5">
            <div className="spinner-border d-inline-block" role="status"></div>
          </div>
        </div>
      ) : (
        <>
          <div className="table-responsive">
            <table className="table">
              <thead>
                <th
                  className="tracker-table-header p-3"
                  style={{ width: "40px" }}
                >
                  Action
                </th>
                <th
                  className="tracker-table-header p-3"
                  style={{ width: "40px" }}
                >
                  <i
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal6"
                    class="bi bi-plus-square"
                    disabled={condition === "Read Only"}
                  ></i>
                </th>
                {dateColumn.map((column) => {
                  if (
                    !column.isDynamic &&
                    (column.fieldName === "allianceName" ||
                      column.fieldName === "lineColor")
                  ) {
                    return (
                      <th
                        key={column.key}
                        className="tracker-table-header p-3 text-center"
                      >
                        {column.name}
                        <i
                          style={{
                            cursor: "pointer",
                            color: sortColumn === column.fieldName ? "red" : "",
                            border:
                              sortColumn === column.fieldName
                                ? "2px solid red"
                                : "none",
                          }}
                          disabled={condition === "Read Only"}
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          className={"bi bi-filter ms-1"}
                        ></i>
                        <ul
                          style={{ cursor: "pointer" }}
                          className="dropdown-menu"
                        >
                          <li
                            style={{ cursor: "pointer" }}
                            className="dropdown-item nn"
                            onClick={() => handleSort(column.fieldName, "desc")}
                          >
                            Sort By Highest Values
                          </li>
                          <li
                            style={{ cursor: "pointer" }}
                            className="dropdown-item nn"
                            onClick={() => handleSort(column.fieldName, "asc")}
                          >
                            Sort By Lowest Values
                          </li>
                          <li
                            style={{ cursor: "pointer" }}
                            className="dropdown-item nn"
                            onClick={() => clearFilters()}
                          >
                            Clear filter
                          </li>
                        </ul>
                      </th>
                    );
                  } else {
                    return null;
                  }
                })}
                {sortedDateColumns.map((column) => {
                  if (column.isDynamic) {
                    return (
                      <th
                        key={column.key}
                        className="tracker-table-header p-3 text-center"
                      >
                        {column.fieldName}
                        <i
                          style={{
                            cursor: "pointer",
                            color: sortColumn === column.fieldName ? "red" : "",
                            border:
                              sortColumn === column.fieldName
                                ? "2px solid red"
                                : "none",
                          }}
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          className={"bi bi-filter ms-1"}
                          disabled={condition === "Read Only"}
                        ></i>
                        <ul
                          style={{ cursor: "pointer" }}
                          className="dropdown-menu"
                        >
                          <li
                            style={{ cursor: "pointer" }}
                            className="dropdown-item nn"
                            onClick={() => handleSort(column.fieldName, "desc")}
                          >
                            Sort By Highest Values
                          </li>
                          <li
                            style={{ cursor: "pointer" }}
                            className="dropdown-item nn"
                            onClick={() => handleSort(column.fieldName, "asc")}
                          >
                            Sort By Lowest Values
                          </li>
                          <li
                            style={{ cursor: "pointer" }}
                            className="dropdown-item nn"
                            onClick={() => clearFilters()}
                          >
                            Clear filter
                          </li>
                          {column.isDynamic && (
                            <li
                              style={{ cursor: "pointer" }}
                              className="dropdown-item nn"
                              onClick={() => handleRemoveColumn(column)}
                            >
                              Delete Column
                            </li>
                          )}
                        </ul>
                      </th>
                    );
                  } else {
                    return null;
                  }
                })}
              </thead>
              <tbody>
                {trackerData?.map((row, index) => (
                  <tr key={row?.id}>
                    <td className="tracker-table-cell text-center">
                      <button
                        className="btn btn-danger px-3"
                        onClick={() => handleDelete(row?.id)}
                        disabled={condition === "Read Only"}
                      >
                        -
                      </button>
                    </td>
                    <td className="text-center">{index + 1}</td>
                    {dateColumn.map((col) => {
                      if (
                        !col.isDynamic &&
                        (col.fieldName === "allianceName" ||
                          col.fieldName === "lineColor")
                      ) {
                        return (
                          <>
                            <td
                              key={col}
                              className="tracker-table-cell text-center"
                            >
                              {editedIndex === row.id &&
                              editedFieldName === col.fieldName ? (
                                col === "villageCoords" ? (
                                  <div className="d-flex">
                                    <input
                                      type="number"
                                      className="w-50"
                                      value={editedValue.x || ""}
                                      onChange={(e) =>
                                        setEditedValue({
                                          ...editedValue,
                                          x: e.target.value,
                                        })
                                      }
                                      onBlur={() => handleSave(row.id)}
                                      onKeyDown={(e) =>
                                        handleKeyDown(e, row.id)
                                      }
                                    />
                                    <input
                                      type="number"
                                      className="w-50"
                                      value={editedValue.y || ""}
                                      onChange={(e) =>
                                        setEditedValue({
                                          ...editedValue,
                                          y: e.target.value,
                                        })
                                      }
                                      onBlur={() => handleSave(row.id)}
                                      onKeyDown={(e) =>
                                        handleKeyDown(e, row.id)
                                      }
                                      disabled={condition === "Read Only"}
                                    />
                                  </div>
                                ) : (
                                  <input
                                    type={
                                      typeof row[col.fieldName] === "number"
                                        ? "number"
                                        : "text"
                                    }
                                    value={editedValue}
                                    onChange={(e) =>
                                      setEditedValue(e.target.value)
                                    }
                                    onBlur={() => handleSave(row.id)}
                                    onKeyDown={(e) => handleKeyDown(e, row.id)}
                                    disabled={condition === "Read Only"}
                                  />
                                )
                              ) : (
                                <div
                                  onClick={() => {
                                    if (condition !== "Read Only") {
                                      setEditedIndex(row.id);
                                      setEditedFieldName(col.fieldName);
                                      setEditedValue(
                                        row[col.fieldName] || null
                                      );
                                    }
                                  }}
                                >
                                  {col.fieldName === "villageCoords"
                                    ? `${row?.villageCoords?.x || null}, ${
                                        row?.villageCoords?.y || null
                                      }`
                                    : !isNaN(parseFloat(row[col.fieldName]))
                                    ? parseFloat(
                                        row[col.fieldName]
                                      ).toLocaleString()
                                    : row[col.fieldName] || "\u00A0"}
                                </div>
                              )}
                            </td>
                          </>
                        );
                      } else {
                        return null;
                      }
                    })}
                    {sortedDateColumns.map((col) => {
                      if (col.isDynamic && col.type === "number") {
                        return (
                          <td
                            key={col.fieldName}
                            className="tracker-table-cell text-center"
                          >
                            {editedIndex === row.id &&
                            editedFieldName === col.fieldName ? (
                              <input
                                type="number"
                                value={editedValue}
                                onChange={(e) => setEditedValue(e.target.value)}
                                onBlur={() => handleSave(row.id)}
                                onKeyDown={(e) => handleKeyDown(e, row.id)}
                                disabled={condition === "Read Only"}
                              />
                            ) : (
                              <div
                                onClick={() => {
                                  setEditedIndex(row.id);
                                  setEditedFieldName(col.fieldName);
                                  setEditedValue(row[col.fieldName] || null);
                                }}
                              >
                                {isNaN(row[col.fieldName])
                                  ? row[col.fieldName] || "\u00A0"
                                  : parseFloat(
                                      row[col.fieldName]
                                    ).toLocaleString()}
                              </div>
                            )}
                          </td>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-start mt-2">
            <button
              className="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal5"
              disabled={condition === "Read Only"}
            >
              Add New Row
            </button>
          </div>
          <div className="mt-3 mb-5">
            {datasets.length > 0 && (
              <LineChart
                labels={datasets[0].labels}
                datasets={datasets}
                predictionStartIndex={predictionStartIndex}
              />
            )}
          </div>
        </>
      )}

      {/*Add Row Modal */}

      <div
        class="modal fade"
        id="exampleModal5"
        tabindex="-2"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add New Entry</h5>
              <button
                type="button"
                className="close bg-transparent border-0"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal5"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <label htmlFor="allianceName">Alliance Name:</label>
                <input
                  type="text"
                  className="form-control"
                  id="allianceName"
                  value={formData.allianceName}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal5"
              >
                Close
              </button>
              <button
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal5"
                className="btn btn-primary"
                onClick={handleSubmit}
              >
                Add Row
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Add Column Modal  */}

      <div
        class="modal fade"
        id="exampleModal6"
        tabindex="-2"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add New Column</h5>
              <button
                type="button"
                className="close bg-transparent border-0"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal6"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="d-flex gap-3">
                <label htmlFor="newColumnDate">Select Date:</label>
                <input
                  type="date"
                  id="newColumnDate"
                  value={selectedDate}
                  onChange={(e) => setSelectedDate(e.target.value)}
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal6"
              >
                Close
              </button>
              <button
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal6"
                className="btn btn-primary"
                onClick={handleAddColumn}
                disabled={condition === "Read Only"}
              >
                Add Column
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TrackerMain;
