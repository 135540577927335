import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import Papa from "papaparse";
import { toast } from "react-toastify";
import moment from "moment-timezone";
import {
  createPushReportData,
  getReportPushData,
} from "../../store/actions/resourcePushAction";
import { getServerSettingsData } from "../../store/actions/serverSettingsAction";

const ResourcePushReportParse = ({ modal, toggle }) => {
  const dispatch = useDispatch();
  const { resourcePush } = useSelector((state) => state.resourcePush);
  const { serverSettingsData } = useSelector((state) => state.serverSettings);
  const [reportText, setReportText] = useState("");
  const [resourceData, setResourceData] = useState([]);
  const [timezone, setTimeZone] = useState();
  const [multiplier, setMultiplier] = useState("1x");

  const handleImport = () => {
    try {
      const parsedReport = Papa.parse(reportText);

      if (
        !parsedReport ||
        !parsedReport.data ||
        parsedReport.data.length === 0
      ) {
        toast.error("Failed to parse report data.");
      }
      const reportData = parsedReport.data;
      const firstArrivalIndex = reportData.findIndex(
        (row) => Array.isArray(row) && row.includes("Arrival")
      );

      const secondArrivalIndex = reportData.findIndex(
        (row, index) =>
          index > firstArrivalIndex &&
          Array.isArray(row) &&
          row.includes("Arrival")
      );

      if (secondArrivalIndex !== -1) {
        const arrivalData = reportData.slice(secondArrivalIndex + 1);

        const arrivalFirstRow = arrivalData[0];

        const colonIndex = arrivalFirstRow[0].indexOf(":");

        const villageName = arrivalFirstRow[0]
          .substring(
            arrivalFirstRow[0].indexOf("Transport to") + "Transport to".length,
            colonIndex
          )
          .trim();

        const playerName = arrivalFirstRow[0].substring(colonIndex + 1).trim();

        const firstNumber = parseFloat(
          (arrivalData[1][0].match(/\d+(\.\d+)?/) || [])[0] || 0
        );

        const secondNumber = parseFloat(
          (arrivalData[2][0].match(/\d+(\.\d+)?/) || [])[0] || 0
        );

        const thirdNumber = parseFloat(
          (arrivalData[3][0].match(/\d+(\.\d+)?/) || [])[0] || 0
        );

        const fourthNumber = parseFloat(
          (arrivalData[4][0].match(/\d+(\.\d+)?/) || [])[0] || 0
        );

        const fifthNumber = parseFloat(
          (arrivalData[5][0].match(/\d+(\.\d+)?/) || [])[0] || 0
        );

        const sum = secondNumber + thirdNumber + fourthNumber + fifthNumber;

        const resources = multiplier ? sum * firstNumber : sum * 1;

        const time = arrivalData[6][0].match(/In (\d+:\d+:\d+) at (\d+:\d+)/);

        const arrivalTime = time ? time[1] + " on " + time[2] : "Unknown";

        const fromPlayer = arrivalData[7][0];
        const fromVillage = arrivalData[8][0];

        const matchingData = resourceData.find(
          (data) =>
            data?.playerName === playerName && data?.villageName === villageName
        );

        const arrivalTimeParts = arrivalTime.split("on")[1].trim().split(":");
        const arrivalHour = parseInt(arrivalTimeParts[0]);
        const arrivalMinute = parseInt(arrivalTimeParts[1]);

        const arrivalDate = new Date();
        arrivalDate.setHours(arrivalHour, arrivalMinute, 0, 0);

        const timeZone = timezone;
        const currentDatee = moment().format("YYYY-MM-DD");
        const timestamp = moment(`${currentDatee}`, "YYYY-MM-DD").tz(timeZone);
        const currentDateString = timestamp.format("DD MMMM YYYY");

        const adjustedArrivalTime = `${arrivalDate.getHours()}:${
          arrivalDate.getMinutes() < 10 ? "0" : ""
        }${arrivalDate.getMinutes()}:${
          arrivalDate.getSeconds() < 10 ? "0" : ""
        }${arrivalDate.getSeconds()}`;

        const combinedDateAndTime = `${adjustedArrivalTime}, ${currentDateString}`;

        if (matchingData) {
          const dispatchData = {
            playerName: playerName,
            villageName: villageName,
            resources: resources,
            arrivalTime: combinedDateAndTime,
            fromPlayer: fromPlayer,
            fromVillage: fromVillage,
          };
          dispatch(createPushReportData(dispatchData));
        } else {
          toast.error(`Player ${playerName} Not Found`);
        }
      } else {
        console.log("Required data not found in report data.");
      }
      toggle();
      setReportText("");
    } catch (error) {
      alert(error);
    }
  };

  useEffect(() => {
    if (modal) {
      dispatch(getReportPushData());
    }
  }, [modal]);

  useEffect(() => {
    if (resourcePush) {
      setResourceData(resourcePush);
    }
  }, [resourcePush]);

  useEffect(() => {
    if (serverSettingsData && serverSettingsData.length > 0) {
      const data = serverSettingsData[0];
      setTimeZone(data.timezone);
    }
  }, [serverSettingsData]);

  useEffect(() => {
    if (resourcePush && resourcePush.length > 0) {
      const multiplierDoc = resourcePush.find((doc) => doc?.id === "multiplier");
      if (multiplierDoc) {
        setMultiplier(multiplierDoc.multiplier);
      }
    }
  }, [resourcePush]);

  useEffect(() => {
    dispatch(getServerSettingsData());
  }, []);

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Paste Report</ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <textarea
                rows="15"
                className="form-control"
                value={reportText}
                onChange={(e) => setReportText(e.target.value)}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            size="sm"
            onClick={() => {
              toggle();
              setReportText("");
            }}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            size="sm"
            onClick={handleImport}
            disabled={!reportText || reportText.length === 0}
          >
            Update
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ResourcePushReportParse;
