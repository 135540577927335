import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";

const DonateResourcesModal = ({
  isOpen,
  toggle,
  donateResources,
  handleDonateResources,
  submitDonation,
  isSubmitting,
}) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered scrollable>
      <ModalHeader toggle={toggle}>Donate Resources</ModalHeader>
      <ModalBody>
        <Form onSubmit={submitDonation}>
          <FormGroup>
            <Label for="donatedResources">Donate Resource:</Label>
            <Input
              type="number"
              id="donatedResources"
              required
              value={donateResources.donatedResources}
              onChange={handleDonateResources}
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle} disabled={isSubmitting}>
          Close
        </Button>
        <Button
          color="primary"
          type="submit"
          onClick={submitDonation}
          disabled={!donateResources.donatedResources || isSubmitting}
        >
          {isSubmitting ? "Submitting..." : "Donate"}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default DonateResourcesModal;
