import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, CardHeader, Spinner, Table } from "reactstrap";
import BonnieVillageModal from "./BonnieVillageModal";
import { useDispatch, useSelector } from "react-redux";
import ConfirmationModal from "../../shared/ConfirmationModal";
import {
  deleteBonnieVillage,
  fetchBonnieVillages,
} from "../../../store/actions/bonnieVillagesActions";

const BonnieVillages = ({ updateOffsetWithCoordinates }) => {
  const dispatch = useDispatch();
  const { bonnieVillages } = useSelector((state) => state.bonnieVillage);

  const [isFetching, setIsFetching] = useState(false);
  const [modal, setModal] = useState(false);
  const [activeBonnieVillage, setActiveBonnieVillage] = useState(null);
  const [editMode, setEditMode] = useState(false);

  const [confirmationModal, setConfirmationModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const modalToggler = () => {
    if (modal) {
      setActiveBonnieVillage(null);
      setEditMode(false);
    }
    setModal(!modal);
  };

  const confirmationModalToggler = () => {
    if (confirmationModal) {
      setActiveBonnieVillage(null);
    }
    setConfirmationModal(!confirmationModal);
  };

  const initiateView = (village) => {
    updateOffsetWithCoordinates(village?.coordinates);
  };

  const initiateEdit = (village) => {
    setActiveBonnieVillage(village);
    setEditMode(true);
    modalToggler();
  };

  const initiateDelete = (village) => {
    setActiveBonnieVillage(village);
    confirmationModalToggler();
  };

  const handleDeleteFavoriteCoordinate = () => {
    setIsDeleting(true);
    dispatch(deleteBonnieVillage({ id: activeBonnieVillage?.id }))
      .then(() => confirmationModalToggler())
      .finally(() => setIsDeleting(false));
  };

  useEffect(() => {
    setIsFetching(true);
    dispatch(fetchBonnieVillages()).finally(() => setIsFetching(false));
  }, []);

  return (
    <div className="ms-2">
      <Card className="shadow-sm overflow-hidden">
        <CardHeader className="d-flex justify-content-between align-items-center">
          <h5 className="mb-0">Bonnie Villages</h5>
          <Button color="primary" onClick={modalToggler}>
            Add
          </Button>
        </CardHeader>
        <CardBody className="p-0">
          <Table responsive className="m-0 border-0">
            <thead>
              <th>X Coordinate</th>
              <th>Y Coordinate</th>
              <th className="text-center">Actions</th>
            </thead>
            <tbody className="m-0 p-0">
              {isFetching ? (
                <tr>
                  <td colSpan={3} className="text-center py-3">
                    <Spinner color="primary" size="sm" />
                  </td>
                </tr>
              ) : bonnieVillages?.length > 0 ? (
                bonnieVillages?.map((village) => (
                  <tr key={village?.id}>
                    <td>{village?.coordinates?.x}</td>
                    <td>{village?.coordinates?.y}</td>
                    <td>
                      <div className="d-flex justify-content-center align-items-center">
                        <Button
                          color="primary"
                          onClick={() => initiateView(village)}
                          className="d-flex justify-content-center align-items-center me-2 overflow-hidden p-1"
                          style={{ width: "35px", height: "35px" }}
                        >
                          <i className="fas fa-eye" />
                        </Button>
                        <Button
                          color="warning"
                          onClick={() => initiateEdit(village)}
                          className="d-flex justify-content-center align-items-center me-2 overflow-hidden p-1"
                          style={{ width: "35px", height: "35px" }}
                        >
                          <i className="fas fa-edit text-white" />
                        </Button>
                        <Button
                          color="danger"
                          onClick={() => initiateDelete(village)}
                          className="d-flex justify-content-center align-items-center overflow-hidden p-1"
                          style={{ width: "35px", height: "35px" }}
                        >
                          <i className="fas fa-trash" />
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={3} className="text-center py-3">
                    No bonnie village
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </CardBody>
      </Card>
      <BonnieVillageModal
        isOpen={modal}
        toggle={modalToggler}
        editMode={editMode}
        activeBonnieVillage={activeBonnieVillage}
      />
      <ConfirmationModal
        title="Delete Favorite"
        text="Are you sure you want to delete this favorite?"
        isOpen={confirmationModal}
        toggle={confirmationModalToggler}
        isLoading={isDeleting}
        onConfirmation={handleDeleteFavoriteCoordinate}
      />
    </div>
  );
};

export default BonnieVillages;
