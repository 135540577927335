import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import {
  addFavoriteCoordinate,
  editFavoriteCoordinate,
} from "../../../store/actions/favoriteCoordinatesActions";
import { toast } from "react-toastify";

const FavoriteModal = ({
  isOpen,
  toggle,
  editMode = false,
  activeFavorite = null,
  coordinates = null,
}) => {
  const dispatch = useDispatch();
  const { uid } = useSelector((state) => state.auth);

  const [data, setData] = useState({
    name: "",
    coordinates: {
      x: "",
      y: "",
    },
  });
  const [previousCoordinates, setPreviousCoordinates] = useState({
    x: "",
    y: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleAddFavoriteCoordinate = () => {
    setIsLoading(true);
    dispatch(
      addFavoriteCoordinate({
        uid,
        payload: data,
      })
    )
      .then(() => toggle())
      .finally(() => setIsLoading(false));
  };

  const handleEditFavoriteCoordinate = () => {
    let checkForDuplicates = true;
    if (
      previousCoordinates?.x === data?.coordinates?.x &&
      previousCoordinates?.y === data?.coordinates?.y
    ) {
      checkForDuplicates = false;
    }
    setIsLoading(true);
    dispatch(
      editFavoriteCoordinate({
        id: activeFavorite?.id,
        payload: data,
        checkForDuplicates,
      })
    )
      .then(() => toggle())
      .finally(() => setIsLoading(false));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!uid) {
      toast.error("Please login to perform this action");
      return;
    }
    if (editMode) {
      handleEditFavoriteCoordinate();
    } else {
      handleAddFavoriteCoordinate();
    }
  };

  useEffect(() => {
    if (isOpen) {
      if (editMode && activeFavorite) {
        setData({
          name: activeFavorite?.name || "",
          coordinates: {
            x: activeFavorite?.coordinates?.x,
            y: activeFavorite?.coordinates?.y,
          },
        });
        setPreviousCoordinates({
          x: activeFavorite?.coordinates?.x,
          y: activeFavorite?.coordinates?.y,
        });
      } else {
        setData({
          name: "",
          coordinates: {
            x: coordinates ? coordinates?.x : "",
            y: coordinates ? coordinates?.y : "",
          },
        });
        setPreviousCoordinates({
          x: "",
          y: "",
        });
      }
    }
  }, [isOpen, editMode, activeFavorite]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} className="modal-dialog-centered">
      <ModalHeader toggle={toggle}>
        {editMode ? "Update" : "Add"} Favorite
      </ModalHeader>
      <form onSubmit={handleSubmit}>
        <ModalBody>
          <div className="mb-3">
            <Label>Name</Label>
            <Input
              type="text"
              placeholder="Enter coordinate name"
              required
              value={data?.name}
              onChange={(e) => setData({ ...data, name: e.target.value })}
            />
          </div>
          <Row className="mb-3">
            <Col>
              <Label>X Coordinate</Label>
              <Input
                type="number"
                placeholder="Enter x-coordinate"
                required
                value={data?.coordinates?.x}
                onChange={(e) =>
                  setData({
                    ...data,
                    coordinates: {
                      ...data?.coordinates,
                      x: Number(e.target.value),
                    },
                  })
                }
              />
            </Col>
            <Col>
              <Label>Y Coordinate</Label>
              <Input
                type="number"
                placeholder="Enter y-coordinate"
                required
                value={data?.coordinates?.y}
                onChange={(e) =>
                  setData({
                    ...data,
                    coordinates: {
                      ...data?.coordinates,
                      y: Number(e.target.value),
                    },
                  })
                }
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle} disabled={isLoading}>
            Close
          </Button>
          <Button type="submit" color="primary" disabled={isLoading}>
            {editMode ? "Update" : "Add"}
            {isLoading && <Spinner size="sm" className="ms-2" />}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default FavoriteModal;
