const initialState = {
  alliances: [],
  players: [],
  villages: [],
  filteredVillages: [],
  playerVillages: [],
};

const mapReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_ALLIANCES_SUCCESS":
      return {
        ...state,
        alliances: action.payload,
      };
    case "FETCH_PLAYERS_SUCCESS":
      return {
        ...state,
        players: action.payload,
      };
    case "FETCH_VILLAGES_SUCCESS":
      return {
        ...state,
        villages: action.payload,
      };
    case "FETCH_FILTERED_VILLAGES_SUCCESS":
      return {
        ...state,
        filteredVillages: action.payload,
      };
    case "FETCH_VILLAGES_BY_PLAYER_SUCCESS":
      return {
        ...state,
        playerVillages: action.payload,
      };
    default:
      return state;
  }
};

export default mapReducer;
