import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  FormGroup,
} from "reactstrap";
import { getCurrentMapData } from "../../store/actions/resourcePushAction";
import { addNewVillage } from "../../store/actions/farmSearchAction";

const AddVillageModal = ({ isOpen, toggle, player }) => {
  const dispatch = useDispatch();
  const { latestMapInformation } = useSelector((state) => state.resourcePush);
  const [x, setX] = useState("");
  const [y, setY] = useState("");
  const [villageOptions, setVillageOptions] = useState([]);
  const [selectedVillage, setSelectedVillage] = useState(null);
  const [isFormValid, setIsFormValid] = useState(false);

  const handleXChange = (e) => {
    setX(e.target.value);
  };

  const handleYChange = (e) => {
    setY(e.target.value);
  };

  const handleSubmit = () => {
    if (x && y) {
      const targetedVillage = latestMapInformation.find(
        (data) => data.x === parseInt(x) && data.y === parseInt(y)
      );

      if (targetedVillage) {
        dispatch(addNewVillage(player, selectedVillage.value, targetedVillage));
      } else {
        alert("No village found with the given coordinates.");
      }
    } else {
      alert("Please enter both X and Y coordinates.");
    }
    toggle();
    setY("");
    setX("");
  };

  useEffect(() => {
    if (x && y && selectedVillage) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [x, y, selectedVillage]);

  useEffect(() => {
    if (latestMapInformation && player) {
      const filteredVillages = latestMapInformation.filter(
        (data) => data.playerName === player
      );
      const options = filteredVillages.map((village) => ({
        value: village.villageName,
        label: village.villageName,
      }));
      setVillageOptions(options);
    }
  }, [latestMapInformation, player]);

  useEffect(() => {
    dispatch(getCurrentMapData());
  }, [dispatch]);

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Add New Village</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label for="villageSelect">Village:</Label>
          <Select
            id="villageSelect"
            isClearable
            options={villageOptions}
            onChange={(option) => setSelectedVillage(option)}
          />
        </FormGroup>
        <FormGroup>
          <Label for="xInput">Targeted Village X Coordinate:</Label>
          <Input type="text" id="xInput" value={x} onChange={handleXChange} />
        </FormGroup>
        <FormGroup>
          <Label for="yInput">Targeted Village Y Coordinate:</Label>
          <Input type="text" id="yInput" value={y} onChange={handleYChange} />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleSubmit} disabled={!isFormValid}>
          Submit
        </Button>{" "}
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddVillageModal;
