const initialState = {
  flags: [],
  allianceFlags: [],
  flagRequests: [],
  villages: [],
};

const flagReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_PLAYER_FLAGS_SUCCESS":
      return {
        ...state,
        flags: action.payload,
      };
    case "FETCH_ALLIANCE_FLAGS_SUCCESS":
      return {
        ...state,
        allianceFlags: action.payload,
      };
    case "FETCH_ALLIANCE_FLAG_REQUESTS_SUCCESS":
      return {
        ...state,
        flagRequests: action.payload,
      };
    case "SEND_FLAG_REQUEST_SUCCESS":
      return {
        ...state,
        flagRequests: [action.payload, ...state.flagRequests],
      };
    case "FETCH_PLAYER_VILLAGES_SUCCESS":
      return {
        ...state,
        villages: action.payload,
      };
    case "ADD_FLAG_SUCCESS":
      return {
        ...state,
        flags: [action.payload, ...state.flags],
      };
    case "EDIT_FLAG_SUCCESS":
      return {
        ...state,
        flags: state.flags.map((flag) =>
          flag.id === action.payload.id
            ? { ...flag, ...action.payload.flag }
            : flag
        ),
      };
    case "DELETE_FLAG_SUCCESS":
      return {
        ...state,
        flags: state.flags.filter((fav) => fav.id !== action.payload),
      };
    default:
      return state;
  }
};

export default flagReducer;
