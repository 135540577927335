import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import { toast } from "react-toastify";
import {
  addFlag,
  editFlag,
  fetchPlayerAlliance,
  sendAllianceFlagRequest,
} from "../../../store/actions/flagActions";

const FlagModal = ({ isOpen, toggle, editMode, activeFlag }) => {
  const dispatch = useDispatch();
  const { uid, user } = useSelector((state) => state.auth);

  const [data, setData] = useState({
    label: "",
    coordinates: {
      x: "",
      y: "",
    },
    type: "personal",
    markType: "tile",
    color: "#000000",
  });
  const [previousCoordinates, setPreviousCoordinates] = useState({
    x: "",
    y: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const allianceFlagAccess =
    user?.role === "Administrator" || user?.role === "Senior Leader";

  const addAllianceFlag = (payload) => {
    dispatch(
      fetchPlayerAlliance({
        playerName: user?.gameAccountName,
      })
    )
      .then((allianceId) => {
        if (!allianceId) {
          toast.error(
            "You are not in any alliance so you can not add alliance flag."
          );
          return;
        }
        const action = allianceFlagAccess ? addFlag : sendAllianceFlagRequest;
        dispatch(
          action({
            uid,
            payload: {
              ...payload,
              allianceId,
            },
          })
        )
          .then(() => toggle())
          .finally(() => setIsLoading(false));
      })
      .catch(() => setIsLoading(false));
  };

  const handleAddFlag = () => {
    setIsLoading(true);
    const { coordinates, markType, ...rest } = data;

    const payload = {
      ...rest,
      ...(markType !== "player" && { coordinates: data?.coordinates }),
      ...(data?.type === "personal" && { markType: data?.markType }),
    };

    if (data?.type === "alliance") {
      addAllianceFlag(payload);
    } else {
      dispatch(
        addFlag({
          uid,
          payload,
        })
      )
        .then(() => toggle())
        .finally(() => setIsLoading(false));
    }
  };

  const handleEditFlag = () => {
    setIsLoading(true);
    let checkForDuplicates = true;
    if (
      previousCoordinates?.x === data?.coordinates?.x &&
      previousCoordinates?.y === data?.coordinates?.y
    ) {
      checkForDuplicates = false;
    }
    dispatch(
      editFlag({
        id: activeFlag?.id,
        payload: data,
        checkForDuplicates,
      })
    )
      .then(() => toggle())
      .finally(() => setIsLoading(false));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!uid) {
      toast.error("Please login to perform this action");
      return;
    }
    if (editMode) {
      handleEditFlag();
    } else {
      handleAddFlag();
    }
  };

  useEffect(() => {
    if (isOpen) {
      if (editMode && activeFlag) {
        setData({
          label: activeFlag?.label || "",
          coordinates: {
            x: activeFlag?.coordinates?.x,
            y: activeFlag?.coordinates?.y,
          },
          type: activeFlag?.type || "personal",
          markType: activeFlag?.markType || "tile",
          color: activeFlag?.color || "",
        });
        setPreviousCoordinates({
          x: activeFlag?.coordinates?.x,
          y: activeFlag?.coordinates?.y,
        });
      } else {
        setData({
          label: "",
          coordinates: {
            x: "",
            y: "",
          },
          type: "personal",
          markType: "tile",
          color: "#000000",
        });
        setPreviousCoordinates({
          x: "",
          y: "",
        });
      }
    }
  }, [isOpen, editMode, activeFlag]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} className="modal-dialog-centered">
      <ModalHeader toggle={toggle}>
        {editMode ? "Update" : "Add"} Flag
      </ModalHeader>
      <form onSubmit={handleSubmit}>
        <ModalBody>
          <div className="mb-3">
            <Label>Label</Label>
            <Input
              type="text"
              placeholder="Enter flag label"
              required
              value={data?.label}
              onChange={(e) => setData({ ...data, label: e.target.value })}
            />
          </div>
          <div className="mb-3">
            <Label>Type</Label>
            <Input
              type="select"
              placeholder="Enter flag npte"
              required
              value={data?.type}
              onChange={(e) =>
                setData({ ...data, type: e.target.value, markType: "tile" })
              }
            >
              <option value="personal">Personal</option>
              <option value="alliance">Alliance</option>
            </Input>
          </div>
          {data?.type === "personal" && (
            <div className="mb-3">
              <Label>Mark As</Label>
              <Input
                type="select"
                placeholder="Enter flag npte"
                required
                value={data?.markType}
                onChange={(e) => setData({ ...data, markType: e.target.value })}
              >
                <option value="tile">Field Tile</option>
                <option value="player">Player</option>
              </Input>
            </div>
          )}
          {data?.markType !== "player" && (
            <Row className="mb-3">
              <Col>
                <Label>X Coordinate</Label>
                <Input
                  type="number"
                  placeholder="Enter x-coordinate"
                  required
                  value={data?.coordinates?.x}
                  onChange={(e) =>
                    setData({
                      ...data,
                      coordinates: {
                        ...data?.coordinates,
                        x: Number(e.target.value),
                      },
                    })
                  }
                />
              </Col>
              <Col>
                <Label>Y Coordinate</Label>
                <Input
                  type="number"
                  placeholder="Enter y-coordinate"
                  required
                  value={data?.coordinates?.y}
                  onChange={(e) =>
                    setData({
                      ...data,
                      coordinates: {
                        ...data?.coordinates,
                        y: Number(e.target.value),
                      },
                    })
                  }
                />
              </Col>
            </Row>
          )}
          <div className="mb-3">
            <Label>Color</Label>
            <Input
              type="color"
              placeholder="Select flag color"
              required
              value={data?.color}
              onChange={(e) => setData({ ...data, color: e.target.value })}
              style={{ height: "37.33px" }}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle} disabled={isLoading}>
            Close
          </Button>
          <Button type="submit" color="primary" disabled={isLoading}>
            {editMode ? "Update" : "Add"}
            {isLoading && <Spinner size="sm" className="ms-2" />}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default FlagModal;
