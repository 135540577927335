import { toast } from "react-toastify";
import firebase from "../../config/firebase";

export const addPermissions = (data) => async (dispatch) => {
  try {
    dispatch(IsLoader(true));
    const {
      admin,
      trackers,
      pushes,
      knowledgeBase,
      Permissions,
      permanentRoster,
      playerRoster,
      userAccounts,
      defenderTracker,
      hammerTracker,
      serverSetting,
      troopGlossary,
      allianceBonus,
      resourcePush,
      pushReport,
      playerTroop,
      playerTools,
      farmSearch,
      playerStatistics,
      interactiveMap,
    } = data;
    const permissionsCollectionRef = firebase
      .firestore()
      .collection("permissions");
    const querySnapshot = await permissionsCollectionRef.limit(1).get();
    if (!querySnapshot.empty) {
      const docId = querySnapshot.docs[0].id;
      await permissionsCollectionRef.doc(docId).update({
        admin,
        trackers,
        pushes,
        knowledgeBase,
        Permissions,
        permanentRoster,
        playerRoster,
        userAccounts,
        defenderTracker,
        hammerTracker,
        serverSetting,
        troopGlossary,
        allianceBonus,
        resourcePush,
        pushReport,
        playerTroop,
        playerTools,
        farmSearch,
        playerStatistics,
        interactiveMap,
      });
      toast.success("Permissions updated successfully");
      dispatch(IsLoader(false));
    } else {
      console.error("No documents found in 'permissions' collection.");
      dispatch(IsLoader(false));
    }
  } catch (error) {
    console.error("Error updating data:", error);
    dispatch(IsLoader(false));
  }
};

export const getPermissions = () => (dispatch) => {
  try {
    dispatch(IsLoader(true));
    const permissionsCollectionRef = firebase
      .firestore()
      .collection("permissions");

    permissionsCollectionRef.onSnapshot((querySnapshot) => {
      if (!querySnapshot.empty) {
        const documentData = querySnapshot.docs[0].data();

        dispatch({ type: "SET_PERMISSIONS", payload: documentData });
        dispatch(IsLoader(false));
      } else {
        console.error("No documents found in 'permissions' collection.");
        dispatch(IsLoader(false));
      }
    });
  } catch (error) {
    console.error("Error retrieving data:", error);
    dispatch(IsLoader(false));
  }
};

export const IsLoader = (val) => async (dispatch) => {
  dispatch({
    type: "IS_PERMISSIONS_LOADING",
    payload: val,
  });
};
