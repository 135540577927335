import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  Table,
} from "reactstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getPlayerVillages } from "../../store/actions/mapAction";
import FavoriteModal from "./FavoriteCoordinates/FavoriteModal";
import BonnieVillageModal from "./BonnieVillages/BonnieVillageModal";

const TileDetailModal = ({
  isOpen,
  closeModal,
  clickedBox,
  mapCoordinatesData,
  serverUrl,
}) => {
  const dispatch = useDispatch();
  const { favoriteCoordinates } = useSelector(
    (state) => state.favoriteCoordinate
  );
  const { bonnieVillages } = useSelector((state) => state.bonnieVillage);

  const [distanceModalOpen, setDistanceModalOpen] = useState(false);
  const [tableModalIsOpen, setTableModalIsOpen] = useState(false);
  const [tournamentSquareLevel, setTournamentSquareLevel] = useState(1);
  const [x, setX] = useState("");
  const [y, setY] = useState("");
  const [distance, setDistance] = useState(null);
  const [villages, setVillages] = useState([]);
  const [troopSpeed, setTroopSpeed] = useState(1);
  const [villageAttackTypes, setVillageAttackTypes] = useState({});
  const [favouriteModal, setFavouriteModal] = useState(false);
  const [villageModal, setVillageModal] = useState(false);

  const isFavorite = favoriteCoordinates?.find(
    (fav) =>
      fav?.coordinates?.x === clickedBox?.x &&
      fav?.coordinates?.y === clickedBox?.y
  );
  const isVillagePresent = bonnieVillages?.find(
    (village) =>
      village?.coordinates?.x === clickedBox?.x &&
      village?.coordinates?.y === clickedBox?.y
  );

  const favouriteModalToggler = () => setFavouriteModal(!favouriteModal);
  const villageModalToggler = () => setVillageModal(!villageModal);

  const handleAttackTypeChange = (villageId, value) => {
    setVillageAttackTypes((prev) => ({
      ...prev,
      [villageId]: value,
    }));
  };

  const toggleDistanceModal = () => {
    setDistanceModalOpen(!distanceModalOpen);
    setDistance(null);
    setX("");
    setY("");
    setTournamentSquareLevel(1);
    setTroopSpeed(1);
  };

  const toggleTableModal = () => {
    setTableModalIsOpen(!tableModalIsOpen);
    setVillages([]);
  };

  const calculateDistance = (x1, y1, x2, y2) => {
    const deltaX = x2 - x1;
    const deltaY = y2 - y1;
    return Math.sqrt(deltaX * deltaX + deltaY * deltaY).toFixed(2);
  };

  const handleCalculateDistance = () => {
    if (x && y) {
      const calculatedDistance = calculateDistance(
        clickedBox?.x,
        clickedBox?.y,
        parseFloat(x),
        parseFloat(y)
      );
      setDistance(calculatedDistance);
    }
  };

  const calculateTravelTime = (villageDistance) => {
    const totalTime = villageDistance / troopSpeed;

    const first20TilesTime = 20 / troopSpeed;

    const timeWithTS =
      (totalTime - first20TilesTime) / tournamentSquareLevel + first20TilesTime;

    return timeWithTS;
  };

  const fetchPlayerVillages = async () => {
    if (mapCoordinatesData && mapCoordinatesData?.data.details.owner) {
      const villages = await dispatch(
        getPlayerVillages({ owner: mapCoordinatesData.data.details.owner })
      );
      setVillages(villages);
    }
  };

  const coordinatesToMapID = (x, y) => {
    const adjustedX = x + 200;
    const adjustedY = 200 - y;
    const mapID = adjustedY * 401 + adjustedX + 1;
    return mapID;
  };

  const mapIDToCoordinates = (mapID) => {
    mapID -= 1;
    const adjustedY = Math.floor(mapID / 401);
    const y = 200 - adjustedY;
    const adjustedX = mapID % 401;
    const x = adjustedX - 200;
    return { x, y };
  };

  const handleGo = (village) => {
    const mapId = coordinatesToMapID(clickedBox?.x, clickedBox?.y);
    const attackType = villageAttackTypes[village.id];

    if (!attackType) {
      alert("Please select an attack type before proceeding.");
      return;
    }

    const url = `${serverUrl}/build.php?newdid=${village.id}&tt=2&targetMapId=${mapId}&eventType=${attackType}&gid=16`;
    window.open(url, "_blank");
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={closeModal}
        className="modal-dialog-centered"
      >
        <ModalHeader toggle={closeModal}>Coordinates Details</ModalHeader>
        <ModalBody>
          <div className="d-flex justify-content-center">
            <Link
              className="text-decoration-none mb-2 d-inline-block pb-2 border-bottom border-2 text-center"
              to={`${serverUrl}/karte.php?x=${clickedBox?.x}&y=${clickedBox?.y}`}
              target="_blank"
            >
              Game Link
            </Link>
          </div>
          <div className="d-flex justify-content-center">
            <Link
              className="text-decoration-none mb-2 d-inline-block pb-2 border-bottom border-2 text-center"
              onClick={toggleDistanceModal}
            >
              Measure Distance
            </Link>
          </div>
          <div className="d-flex justify-content-center">
            <Link
              className="text-decoration-none mb-2 d-inline-block pb-2 border-bottom border-2 text-center"
              onClick={() => {
                toggleTableModal();
                fetchPlayerVillages();
              }}
            >
              Show Travel Times
            </Link>
          </div>
          <p>
            <strong>X:</strong> {clickedBox?.x}
          </p>
          <p>
            <strong>Y:</strong> {clickedBox?.y}
          </p>
          {mapCoordinatesData?.data?.title && (
            <p>
              <strong>Title:</strong> {mapCoordinatesData?.data.title}
            </p>
          )}
          {mapCoordinatesData?.data?.details && (
            <div>
              <p>
                <strong>Owner:</strong>{" "}
                {mapCoordinatesData.data.details.owner || "N/A"}
              </p>
              <p>
                <strong>Village:</strong>{" "}
                {mapCoordinatesData.data.details.village || "N/A"}
              </p>
              <p>
                <strong>Tribe:</strong>{" "}
                {mapCoordinatesData.data.details.tribe || "N/A"}
              </p>
              <p>
                <strong>Alliance:</strong>{" "}
                {mapCoordinatesData.data.details.alliance || "N/A"}
              </p>
            </div>
          )}
          {mapCoordinatesData?.data?.bonuses?.length > 0 && (
            <div>
              <p>
                <strong>Bonuses:</strong>
              </p>
              <ul>
                {mapCoordinatesData.data.bonuses.map((bonus, index) => (
                  <li key={index}>
                    {bonus.type}: {bonus.value}
                  </li>
                ))}
              </ul>
            </div>
          )}
          {mapCoordinatesData?.data?.resources && (
            <div>
              <p>
                <strong>Resources:</strong>
              </p>
              <ul>
                {Object.entries(mapCoordinatesData.data.resources).map(
                  ([key, value], index) => (
                    <li key={index}>
                      {key}: {value || "N/A"}
                    </li>
                  )
                )}
              </ul>
            </div>
          )}
          <div>
            <p>
              <strong>Favorite: </strong>
              <span>
                {isFavorite ? (
                  "Done"
                ) : (
                  <button
                    className="border-0 bg-transparent text-primary"
                    style={{ textDecoration: "none" }}
                    onMouseEnter={(e) =>
                      (e.target.style.textDecoration = "underline")
                    }
                    onMouseLeave={(e) =>
                      (e.target.style.textDecoration = "none")
                    }
                    onClick={favouriteModalToggler}
                  >
                    Add to favorites
                  </button>
                )}
              </span>
            </p>
          </div>
          <div>
            <p>
              <strong>Bonnie Village: </strong>
              <span>
                {isVillagePresent ? (
                  "Present"
                ) : (
                  <button
                    className="border-0 bg-transparent text-primary"
                    style={{ textDecoration: "none" }}
                    onMouseEnter={(e) =>
                      (e.target.style.textDecoration = "underline")
                    }
                    onMouseLeave={(e) =>
                      (e.target.style.textDecoration = "none")
                    }
                    onClick={villageModalToggler}
                  >
                    Add bonnie village
                  </button>
                )}
              </span>
            </p>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={closeModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
      {/* Distance Modal */}
      <Modal
        isOpen={distanceModalOpen}
        toggle={toggleDistanceModal}
        className="modal-dialog-centered"
      >
        <ModalHeader toggle={toggleDistanceModal}>Measure Distance</ModalHeader>
        <ModalBody>
          <div>
            <label htmlFor="xCoord">X Coordinate:</label>
            <Input
              id="xCoord"
              type="number"
              value={x}
              onChange={(e) => setX(e.target.value)}
              placeholder="Enter X coordinate"
            />
          </div>
          <div className="mt-3">
            <label htmlFor="yCoord">Y Coordinate:</label>
            <Input
              id="yCoord"
              type="number"
              value={y}
              onChange={(e) => setY(e.target.value)}
              placeholder="Enter Y coordinate"
            />
          </div>
          {distance && (
            <p className="mt-3">
              <strong>Calculated Distance:</strong> {distance}
            </p>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleCalculateDistance}>
            Calculate
          </Button>
          <Button color="secondary" onClick={toggleDistanceModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
      {/* Player's Villages Modal  */}
      <Modal
        isOpen={tableModalIsOpen}
        toggle={toggleTableModal}
        className="modal-dialog-centered"
        size="lg"
      >
        <ModalHeader toggle={toggleTableModal}>Village Information</ModalHeader>
        <ModalBody>
          <div className="me-3 d-flex align-items-center mb-3 gap-3">
            <label htmlFor="troopType" className="form-label">
              Troop Type
            </label>
            <select
              id="troopType"
              className="form-select w-25"
              onChange={(e) => setTroopSpeed(e.target.value)}
            >
              <option value="7">Clubswinger</option>
              <option value="7">Spearman</option>
              <option value="6">Axeman</option>
              <option value="9">Scout</option>
              <option value="10">Paladin</option>
            </select>
            <label htmlFor="troopType" className="form-label">
              Select Troop Type
            </label>
            <select
              id="tournamentSquareLevel"
              className="form-select w-25"
              onChange={(e) => setTournamentSquareLevel(e.target.value)}
            >
              {[...Array(21).keys()].map((level) => (
                <option key={level} value={level}>
                  {level}
                </option>
              ))}
            </select>
          </div>

          <Table borderless>
            <thead>
              <tr>
                <th>Village</th>
                <th>X | Y</th>
                <th>Village Link</th>
                <th>Distance</th>
                <th>Travel Time</th>
                <th style={{ width: "200px" }}>Action</th>
              </tr>
            </thead>
            <tbody>
              {villages?.map((village) => {
                const distance = calculateDistance(
                  clickedBox?.x,
                  clickedBox?.y,
                  village.x,
                  village.y
                );
                const travelTime = calculateTravelTime(distance);
                return (
                  <tr key={village.id}>
                    <td>{village.data?.title}</td>
                    <td>
                      {village.x} | {village.y}
                    </td>
                    <td>
                      <Link
                        to={`${serverUrl}/karte.php?x=${village.x}&y=${village.y}`}
                        target="_blank"
                      >
                        Village Link
                      </Link>
                    </td>
                    <td>{distance}</td>
                    <td>{travelTime.toFixed(2)}</td>
                    <td>
                      <select
                        className="form-select"
                        value={villageAttackTypes[village.id]}
                        onChange={(e) =>
                          handleAttackTypeChange(village.id, e.target.value)
                        }
                      >
                        <option value="5">Reinforcement</option>
                        <option value="4">Attack: Raid</option>
                        <option value="3">Attack: Normal</option>
                      </select>
                    </td>
                    <td>
                      <Button color="primary" onClick={() => handleGo(village)}>
                        Go
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleTableModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
      <FavoriteModal
        isOpen={favouriteModal}
        toggle={favouriteModalToggler}
        coordinates={clickedBox}
      />
      <BonnieVillageModal
        isOpen={villageModal}
        toggle={villageModalToggler}
        coordinates={clickedBox}
      />
    </>
  );
};

export default TileDetailModal;
