import React, { useEffect, useState } from "react";
import TabSelection from "../components/TabSelection/TabSelection";
import Statistics from "../components/PlayerStatistics/Statistics";
import TopTwenty from "../components/PlayerStatistics/TopTwenty";
import { useDispatch, useSelector } from "react-redux";
import { getPermissions } from "../store/actions/userpermissionsAction";

const PlayerStatistics = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { permission } = useSelector((state) => state.userPermissions);
  const statisticsSections = ["Statistics", "Top 20"];
  const [statisticSection, setStatisticSection] = useState("Statistics");
  const [condition, setCondition] = useState();

  //---------------- Roles/Permissions --------------//
  const permissionData = permission?.playerStatistics;
  const role = user?.role;
  const functionalRole = user?.functionalRole;
  const functionalPermission =
    permission && permissionData && permissionData[functionalRole];

  useEffect(() => {
    if (permission && permissionData) {
      const rolePermission = permissionData[role];
      const functionalPermission = permissionData[functionalRole];
      if (
        functionalPermission !== undefined &&
        functionalPermission.trim() !== ""
      ) {
        setCondition(functionalPermission);
      } else if (rolePermission !== undefined && rolePermission.trim() !== "") {
        setCondition(rolePermission);
      }
    }
  }, [permission, permissionData, role, functionalRole]);

  useEffect(() => {
    dispatch(getPermissions());
  }, []);

  return (
    <>
      {condition == "Locked" ? (
        <div
          className="text-center d-flex justify-content-center align-items-center "
          style={{ height: "70vh" }}
        >
          <h2>
            This page is not available for{" "}
            {functionalPermission !== undefined &&
            functionalPermission.trim() !== ""
              ? functionalRole
              : role}
          </h2>
        </div>
      ) : (
        <div className="px-5 container-fluid">
          <div className="d-flex justify-content-between align-items-center flex-wrap mt-3 mb-3">
            <div></div>
            <TabSelection
              options={statisticsSections}
              onChange={(value) => setStatisticSection(value)}
            />
          </div>
          {statisticSection === "Statistics" ? (
            <Statistics condition={condition} />
          ) : (
            <TopTwenty condition={condition} />
          )}
        </div>
      )}
    </>
  );
};

export default PlayerStatistics;
