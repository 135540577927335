const initialState = {
  allianceControlZone: null,
};

export const allianceControlZoneReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_ALLIANCE_CONTROL_ZONE_SUCCESS":
      return {
        ...state,
        allianceControlZone: action.payload,
      };
    case "ADD_ALLIANCE_CONTROL_ZONE_SUCCESS":
      return {
        ...state,
        allianceControlZone: action.payload,
      };
    case "DELETE_ALLIANCE_CONTROL_ZONE_SUCCESS":
      return {
        ...state,
        allianceControlZone: null,
      };
    default:
      return state;
  }
};
