import { Segmented } from "antd";
import React from "react";

export const TabSelection = ({ options, onChange }) => {
  return (
    <Segmented
      className="p-2 bg-secondary text-white"
      onChange={onChange}
      options={options.map((option) => ({
        label: (
          <div>
            <div>{option}</div>
          </div>
        ),
        value: option,
      }))}
    />
  );
};

export default TabSelection;
