import React, { useEffect, useMemo, useState } from "react";
import "../assets/css/PermanentRoaster.css"
import { useDrag } from "react-dnd";
import { useRef, useCallback } from "react";
import { Navigation } from "@progress/kendo-react-common";
import { cellAt } from "../utils/utilitis";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewColumn,
  addNewRow,
  deleteColumn,
  deleteRow,
  getColumns,
  swapColumns,
  updateRow,
} from "../store/actions/playroasterAction";
import { toast } from "react-toastify";
import { getPermissions } from "../store/actions/userpermissionsAction";
const PermanentRoaster = () => {
  //-------------useSelectors ----------- //
  const dispatch = useDispatch();
  const root = useRef(null);
  const { column, rowData, columnLoading } = useSelector(
    (state) => state.column
  );
  const { user } = useSelector((state) => state.auth);
  const { permission } = useSelector((state) => state.userPermissions);

  //-------------- useStates -------------- //

  const [editedCellValue, setEditedCellValue] = useState("");
  const [tableRows, setTableRows] = useState([]);
  const [newColumnName, setNewColumnName] = useState("");
  const [newColumnType, setNewColumnType] = useState("text");
  const [newColumnFormat, setNewColumnFormat] = useState("open");
  const [newRowData, setNewRowData] = useState({});
  const [activeCell, setActiveCell] = useState(null);
  const [selectedFilterValues, setSelectedFilterValues] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredcols, setfilteredcols] = useState("");
  const [columnFilters, setColumnFilters] = useState({});
  const [draggedColumn, setDraggedColumn] = useState(null);
  const [activeIndex1, setActiveindex1] = useState(null);
  const [isSorting, setIsSorting] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [condition, setCondition] = useState("");

  //---------- Roles/Permission--------------//

  const permissionData = permission?.permanentRoster;
  const role = user?.role;
  const functionalRole = user?.functionalRole;
  const functionalPermission =
    permission && permissionData && permissionData[functionalRole];

  //-----------Add/Remove Columns --------//
  const addColumn = () => {
    const formatColumnName = (columnName) => {
      return columnName
        .replace(/\s+/g, "")
        .replace(/[^a-zA-Z0-9]/g, (match, offset, str) => {
          if (offset === 0) return "";
          return match.toUpperCase();
        });
    };
    let payload = {
      name: newColumnName,
      format: newColumnFormat,
      type: newColumnType,
      index: column?.length + 1,
      fieldName: formatColumnName(newColumnName),
    };
    dispatch(
      addNewColumn(payload, () => {
        setNewColumnFormat("");
        setNewColumnName("");
        setNewColumnType("");
      })
    );
  };

  const handleRemoveColumn = (column) => {
    dispatch(
      deleteColumn(column.id, () => {
        getColumns();
      })
    );
  };

  //-----------Add/Remove Rows --------//

  const addRow = (e) => {
    e.preventDefault();
    if (column?.length <= 0) {
      toast.error("Please Add Column First to insert Data.");
      return;
    } else if (Object.keys(newRowData).length === 0) {
      toast.error("Please fill in the data before adding a new row.");
      return;
    }
    dispatch(
      addNewRow(newRowData, () => {
        setNewRowData({});
        dispatch(getColumns());
      })
    );
  };

  const removeRow = (id) => {
    dispatch(
      deleteRow(id, () => {
        dispatch(getColumns());
      })
    );
  };

  //------------Functions ------------//

  const handleSaveEditedCell = (row, column) => {
    dispatch(
      updateRow(row.rowId, column, editedCellValue, () => {
        setTableRows((prevRows) => {
          return prevRows.map((prevRow) => {
            if (prevRow.rowId === row.rowId) {
              const updatedRow = { ...prevRow };
              updatedRow[column] = editedCellValue;
              return updatedRow;
            }
            return prevRow;
          });
        });
        setActiveCell(null);
      })
    );
  };

  const uniqueValuesForColumn = (columnName) => {
    const uniqueValues = new Set();

    if (tableRows && tableRows.length > 0) {
      tableRows.forEach((row) => {
        if (row && row[columnName] !== undefined) {
          const value = String(row[columnName]);
          uniqueValues.add(value);
        }
      });
    }

    return Array.from(uniqueValues);
  };

  const handleApplyFilter = (columnName) => {
    const selectedValues = selectedFilterValues[columnName];

    if (!selectedValues || selectedValues.length === 0) {
      return;
    }

    const filteredRows = tableRows.filter((row) => {
      return (
        row &&
        row[columnName] !== undefined &&
        selectedValues.includes(String(row[columnName]))
      );
    });
    setTableRows(filteredRows);
    setSelectedFilter(columnName);
    setIsSorting(true);
  };

  const clearFilters = () => {
    setTableRows(rowData);
    setSelectedFilterValues({});
    setColumnFilters({});
    setIsSorting(false);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleFilterChange = (columnName, filterValue) => {
    setColumnFilters({ ...columnFilters, [columnName]: filterValue });
  };

  const queryPlayers = () => {
    const filteredData = rowData?.filter((row) => {
      return Object.keys(columnFilters).every((column) => {
        const filterValue = columnFilters[column];
        if (row && typeof row[column] === "string") {
          return row[column].toLowerCase().includes(filterValue.toLowerCase());
        } else if (row && typeof row[column] === "number") {
          return row[column].toString().includes(filterValue);
        } else if (row && typeof row[column] === "boolean") {
          return row[column] === (filterValue.toLowerCase() === "true");
        }

        return true;
      });
    });
    setTableRows(filteredData);
  };

  const handleSort = (column, order) => {
    const sortedRows = [...tableRows];
    sortedRows.sort((a, b) => {
      const valueA = a[column.fieldName];
      const valueB = b[column.fieldName];

      if (column.type === "text") {
        return order === "asc"
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      } else if (column.type === "number") {
        return order === "asc"
          ? Number(valueA) - Number(valueB)
          : Number(valueB) - Number(valueA);
      }
      return 0;
    });
    setTableRows(sortedRows);
    setSelectedFilter(column.fieldName);
    setIsSorting(true);
  };

  const navigation = useMemo(() => {
    const tabIndex = 0;
    return new Navigation({
      root,
      selectors: ["th", "td"],
      keyboardEvents: {
        keydown: {
          ArrowRight: (target, nav, ev) => {
            ev.preventDefault();
            const next = cellAt(root.current, target, [0, 1]);
            nav.focusElement(next, target);
          },
          ArrowLeft: (target, nav, ev) => {
            ev.preventDefault();
            const next = cellAt(root.current, target, [0, -1]);
            nav.focusElement(next, target);
          },
          ArrowUp: (target, nav, ev) => {
            ev.preventDefault();
            const next = cellAt(root.current, target, [-1, 0]);
            nav.focusElement(next, target);
          },
          ArrowDown: (target, nav, ev) => {
            ev.preventDefault();
            const next = cellAt(root.current, target, [1, 0]);
            nav.focusElement(next, target);
          },
        },
      },
      tabIndex,
    });
  }, []);

  const onKeyDown = useCallback(
    (event) => {
      setActiveindex1(null);

      navigation.triggerKeyboardEvent(event);
    },
    [navigation]
  );

  const isFilterApplied = (column) => {
    return filteredcols.includes(column.name);
  };

  const handleColumnDrag = (index, name) => {
    setDraggedColumn(index);
  };

  const handleColumnDrop = async (index, name) => {
    dispatch(swapColumns(draggedColumn, index));
  };
  const [{ isDragging }, drag] = useDrag({
    type: "COLUMN",
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const handleKeyDown = (e) => {
    if (e.key === "Escape") {
      setActiveCell(null);
    }
  };

  //----------- useEffects ----------//

  useEffect(() => {
    navigation.first?.setAttribute("tabindex", String(navigation.tabIndex));
  }, [navigation]);

  useEffect(() => {
    if (rowData?.length > 0) {
      setTableRows(rowData);
    } else {
      setTableRows([]);
    }
  }, [rowData]);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (permission && permissionData) {
      const rolePermission = permissionData[role];
      const functionalPermission = permissionData[functionalRole];
      if (
        functionalPermission !== undefined &&
        functionalPermission.trim() !== ""
      ) {
        setCondition(functionalPermission);
      } else if (rolePermission !== undefined && rolePermission.trim() !== "") {
        setCondition(rolePermission);
      }
    }
  }, [permission, permissionData, role, functionalRole]);

  useEffect(() => {
    dispatch(getPermissions());
    dispatch(getColumns());
  }, []);

  return (
    <div className="container-fluid mt-4">
      <h2 className="text-center">Permanent Roster</h2>
      {condition == "Locked" ? (
        <div
          className="text-center d-flex justify-content-center align-items-center "
          style={{ height: "70vh" }}
        >
          <h2>
            This page is not available for{" "}
            {functionalPermission !== undefined &&
            functionalPermission.trim() !== ""
              ? functionalRole
              : role}
          </h2>{" "}
        </div>
      ) : (
        <>
          <div className="container-fluid px-5 mt-2">
            <div
              class="modal fade"
              id="exampleModal1"
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <h1 class="modal-title fs-5" id="exampleModalLabel">
                      Filter
                    </h1>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body">
                    {Array.isArray(column) &&
                      column.map((column, index) => (
                        <div key={index} className="mb-3">
                          <input
                            type="text"
                            className="form-control me-2"
                            disabled={condition === "Read Only"}
                            placeholder={`Filter ${column.name}`}
                            value={columnFilters[column.fieldName] || ""}
                            onChange={(e) =>
                              handleFilterChange(
                                column.fieldName,
                                e.target.value
                              )
                            }
                          />
                        </div>
                      ))}
                  </div>
                  <div class="modal-footer">
                    <button
                      className="btn btn-info me-2"
                      data-bs-dismiss="modal"
                      onClick={queryPlayers}
                      disabled={
                        condition === "Read Only" ||
                        Object.values(columnFilters).every(
                          (value) => value === ""
                        )
                      }
                    >
                      Query
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <button
              className="btn btn-info me-2"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal1"
              disabled={condition === "Read Only"}
            >
              Query
            </button>
            <button
              className="btn btn-secondary"
              onClick={clearFilters}
              disabled={condition === "Read Only"}
            >
              Reset Query
            </button>
            <div className="table-responsive">
              <table
                className="table  table-excel mt-4"
                onKeyDown={onKeyDown}
                ref={root}
              >
                <thead>
                  <tr>
                    <th>Action</th>
                    <th>
                      <i
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        class="bi bi-plus-square"
                      ></i>
                    </th>
                    {Array.isArray(column) &&
                      column.map((column, index) => (
                        <th
                          key={index}
                          onMouseDown={() =>
                            handleColumnDrag(column?.index, column?.name)
                          }
                          onMouseUp={() =>
                            handleColumnDrop(column?.index, column?.name)
                          }
                          style={{
                            cursor: "move",
                            opacity:
                              isDragging && index === draggedColumn ? 0.5 : 1,
                            whiteSpace: "nowrap",
                            margin: 0,
                            padding: 0,
                          }}
                          className={` ${
                            isFilterApplied(column) ? "filtered-column" : ""
                          }`}
                        >
                          <div className="resizable_div">
                            <span
                              style={{ userSelect: "none" }}
                              className="text-capitalize"
                            >
                              {column.name}
                            </span>
                            <i
                              style={{
                                cursor: "pointer",
                                color:
                                  isSorting &&
                                  selectedFilter === column.fieldName
                                    ? "red"
                                    : "black",
                                border:
                                  isSorting &&
                                  selectedFilter === column.fieldName
                                    ? "1px solid red"
                                    : "none",
                              }}
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              disabled={condition === "Read Only"}
                              className={
                                isFilterApplied(column)
                                  ? "bi bi-filter-square text-danger ms-3"
                                  : "bi bi-filter ms-3"
                              }
                            ></i>
                            <ul
                              style={{ cursor: "pointer" }}
                              className="dropdown-menu"
                            >
                              <li
                                style={{ cursor: "pointer" }}
                                onClick={() => handleRemoveColumn(column)}
                                className="dropdown-item nn"
                              >
                                Delete Column
                              </li>
                              <li
                                style={{ cursor: "pointer" }}
                                className="dropdown-item nn"
                                onClick={() => handleSort(column, "desc")}
                              >
                                Sort By Highest Values
                              </li>
                              <li
                                style={{ cursor: "pointer" }}
                                className="dropdown-item nn"
                                onClick={() => handleSort(column, "asc")}
                              >
                                Sort By Lowest Values
                              </li>
                              <li
                                style={{ cursor: "pointer" }}
                                className="dropdown-item nn"
                                onClick={() => clearFilters()}
                              >
                                Clear filter
                              </li>
                              <li
                                style={{ cursor: "pointer" }}
                                className="dropdown-item "
                              >
                                Search
                                <input
                                  className="mx-2"
                                  placeholder="Search Something"
                                  style={{ width: "70%" }}
                                  type="text"
                                  onChange={handleSearchChange}
                                />
                              </li>
                              <div className="">
                                <p>Selected Values:</p>
                                {selectedFilterValues[column.fieldName]?.map(
                                  (value, i) => (
                                    <span key={i}>{value},</span>
                                  )
                                )}
                              </div>
                              <select
                                style={{ width: "100%" }}
                                onChange={(e) => {
                                  const selectedValues = Array.from(
                                    e.target.selectedOptions,
                                    (option) => option.value
                                  );
                                  setSelectedFilterValues((prevValues) => {
                                    const currentValues =
                                      prevValues[column.fieldName] || [];
                                    const newValues = currentValues.includes(
                                      selectedValues[0]
                                    )
                                      ? currentValues.filter(
                                          (value) => value !== selectedValues[0]
                                        )
                                      : [...currentValues, ...selectedValues];
                                    return {
                                      ...prevValues,
                                      [column.fieldName]: newValues,
                                    };
                                  });
                                }}
                                multiple
                              >
                                <option
                                  value="selectAll"
                                  className="hhh"
                                  onClick={() => {
                                    const allValues = uniqueValuesForColumn(
                                      column.fieldName
                                    ).filter((value) =>
                                      value
                                        .toLowerCase()
                                        .includes(searchTerm.toLowerCase())
                                    );
                                    setSelectedFilterValues((prevValues) => ({
                                      ...prevValues,
                                      [column.fieldName]: allValues,
                                    }));
                                  }}
                                >
                                  Select All
                                </option>
                                value && typeof value === 'string' ?
                                value.toLowerCase() : value
                                {uniqueValuesForColumn(column.fieldName)
                                  ?.filter((value) =>
                                    value
                                      .toLowerCase()
                                      .includes(searchTerm?.toLowerCase())
                                  )
                                  .map((value, i) => (
                                    <option
                                      className="hhh"
                                      key={i}
                                      value={value}
                                    >
                                      {`${value} ${
                                        selectedFilterValues[
                                          column.fieldName
                                        ]?.includes(value)
                                          ? "✓"
                                          : ""
                                      }`}
                                    </option>
                                  ))}
                              </select>
                              <div className="d-flex justify-content-around">
                                <button
                                  className="btn btn-primary"
                                  disabled={condition === "Read Only"}
                                  onClick={() =>
                                    handleApplyFilter(column.fieldName)
                                  }
                                >
                                  Apply Filter
                                </button>
                                <button
                                  className="btn btn-danger"
                                  disabled={condition === "Read Only"}
                                  onClick={() => setSelectedFilterValues("")}
                                >
                                  Cancel
                                </button>
                              </div>
                            </ul>
                          </div>
                        </th>
                      ))}
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(tableRows) &&
                    tableRows.map((row, rowIndex) => {
                      return (
                        <>
                          <tr key={rowIndex}>
                            <td className="nn1">
                              <button
                                className="btn btn-danger"
                                onClick={() => removeRow(row?.rowId)}
                                disabled={condition === "Read Only"}
                              >
                                <i className="bi bi-dash"></i>
                              </button>
                            </td>
                            <td className="nn1">{rowIndex + 1}</td>
                            {Array.isArray(column) &&
                              column.map((column, colIndex) => {
                                return (
                                  <td
                                    onKeyDown={(e) => {
                                      if (e.key === "Enter") {
                                        e.preventDefault();
                                        if (activeCell == null) {
                                          setActiveCell(
                                            `${rowIndex},${colIndex}`
                                          );
                                          setEditedCellValue(
                                            row?.[column.fieldName]
                                          );
                                        } else {
                                          handleSaveEditedCell(
                                            row,
                                            column.fieldName
                                          );
                                        }
                                      }
                                      if (e.key === "Escape") {
                                        e.preventDefault();
                                        setActiveCell(null);
                                      }
                                    }}
                                    onMouseDown={() => {
                                      setActiveCell(`${rowIndex},${colIndex}`);
                                      setEditedCellValue(
                                        row?.[column.fieldName]
                                      );
                                    }}
                                    className={
                                      activeIndex1 ===
                                        rowIndex * column.length + colIndex &&
                                      activeCell === `${rowIndex},${colIndex}`
                                        ? "active1"
                                        : ""
                                    }
                                    onDoubleClick={() => {
                                      if (condition !== "Read Only")
                                        setActiveCell(
                                          `${rowIndex},${colIndex}`
                                        );
                                      setEditedCellValue(
                                        row?.[column.fieldName]
                                      );
                                    }}
                                  >
                                    {activeCell == `${rowIndex},${colIndex}` &&
                                    column.type !== "boolean" ? (
                                      <input
                                        type={column?.type}
                                        value={editedCellValue}
                                        disabled={
                                          activeCell === null ||
                                          condition === "Read Only"
                                        }
                                        onChange={(e) =>
                                          setEditedCellValue(e.target.value)
                                        }
                                        onBlur={() =>
                                          handleSaveEditedCell(
                                            row,
                                            column.fieldName
                                          )
                                        }
                                      />
                                    ) : activeCell ==
                                        `${rowIndex},${colIndex}` &&
                                      column.type == "boolean" ? (
                                      <select
                                        onChange={(e) =>
                                          setEditedCellValue(e.target.value)
                                        }
                                        disabled={
                                          activeCell === null ||
                                          condition === "Read Only"
                                        }
                                        onBlur={() =>
                                          handleSaveEditedCell(
                                            row,
                                            column.fieldName
                                          )
                                        }
                                        class="form-select text-capitalize"
                                        value={
                                          editedCellValue ||
                                          row[column.fieldName]
                                        }
                                        aria-label="Default select example"
                                      >
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                      </select>
                                    ) : (
                                      row[column.fieldName]
                                    )}
                                  </td>
                                );
                              })}
                          </tr>
                        </>
                      );
                    })}
                  <tr>
                    <td className="nn1"></td>
                    <td className="nn1"></td>
                    {Array.isArray(column) &&
                      column.map((column, index) => (
                        <td key={index}>
                          <div className="mb-3 mt-3 mx-1">
                            {column.type !== "boolean" && (
                              <input
                                type="text"
                                disabled={condition === "Read Only"}
                                className="form-control me-2"
                                id={`newRow-${column.name}`}
                                placeholder={`Enter ${column.name}`}
                                value={newRowData[column.fieldName] || ""}
                                onChange={(e) =>
                                  setNewRowData((prevData) => ({
                                    ...prevData,
                                    [column.fieldName]: e.target.value,
                                  }))
                                }
                              />
                            )}
                            {column.type === "boolean" && (
                              <select
                                value={newRowData[column.fieldName] || ""}
                                onChange={(e) =>
                                  setNewRowData((prevData) => ({
                                    ...prevData,
                                    [column.fieldName]: e.target.value,
                                  }))
                                }
                                class="form-select"
                                aria-label="Default select example"
                                disabled={condition === "Read Only"}
                              >
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </select>
                            )}
                          </div>
                        </td>
                      ))}
                  </tr>
                </tbody>
              </table>
            </div>
            {columnLoading && (
              <div
                className=" d-block mx-auto spinner-border"
                role="status"
              ></div>
            )}
            <div className="d-flex mt-2">
              <button
                className="btn btn-success me-2"
                onClick={addRow}
                disabled={condition === "Read Only"}
              >
                Add Row
              </button>
            </div>

            {/* Modal  */}

            <div
              class="modal fade"
              id="exampleModal"
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content">
                  <div class="modal-header">
                    <h1 class="modal-title fs-5" id="exampleModalLabel">
                      Add Column
                    </h1>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body">
                    <div className="mb-3">
                      <label htmlFor="newColumnName" className="form-label">
                        Column Name
                      </label>
                      <input
                        type="text"
                        className="form-control me-2"
                        id="newColumnName"
                        placeholder="Enter column name"
                        value={newColumnName}
                        onChange={(e) => setNewColumnName(e.target.value)}
                        disabled={condition === "Read Only"}
                      />
                      <label htmlFor="newColumnType" className="form-label">
                        Column Type
                      </label>
                      <select
                        className="form-select me-2"
                        id="newColumnType"
                        value={newColumnType}
                        onChange={(e) => setNewColumnType(e.target.value)}
                        disabled={condition === "Read Only"}
                      >
                        <option value="text">Open Text Field</option>
                        <option value="number">Number Field</option>
                        <option value="boolean">Y/N Field</option>
                      </select>
                      {newColumnType === "number" && (
                        <div>
                          <label
                            htmlFor="newColumnFormat"
                            className="form-label"
                          >
                            Column Format
                          </label>
                          <select
                            className="form-select me-2"
                            id="newColumnFormat"
                            value={newColumnFormat}
                            onChange={(e) => setNewColumnFormat(e.target.value)}
                            disabled={condition === "Read Only"}
                          >
                            <option value="number">Number (No Decimal)</option>
                            <option value="decimal">Number (Decimal)</option>
                          </select>
                        </div>
                      )}
                      {newColumnType === "boolean" && (
                        <div>
                          <label
                            htmlFor="newColumnFormat"
                            className="form-label"
                          >
                            Column Format
                          </label>
                          <select
                            className="form-select me-2"
                            id="newColumnFormat"
                            value={newColumnFormat}
                            onChange={(e) => setNewColumnFormat(e.target.value)}
                            disabled={condition === "Read Only"}
                          >
                            <option value="yesNo">Yes/No Field</option>
                          </select>
                        </div>
                      )}
                      <button
                        className="btn btn-primary mt-4"
                        data-bs-dismiss="modal"
                        onClick={() => addColumn()}
                        disabled={condition === "Read Only"}
                      >
                        Add Column
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PermanentRoaster;
