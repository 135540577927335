const initialState = {
  myFarmlist: [],
};

const FarmSearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_FARMLIST_DATA":
      return {
        ...state,
        myFarmlist: action.payload,
      };
    case "FARM_SEARCH_LOADING":
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};

export default FarmSearchReducer;
