import React from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";

const ConfirmationModal = ({
  title,
  text,
  isOpen,
  toggle,
  isLoading,
  onConfirmation,
}) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle} className="align-items-center border-bottom">
        {title || "Confirmation Modal"}
      </ModalHeader>
      <ModalBody>
        {text || "Are you sure you want to perform this action?"}
      </ModalBody>
      <ModalFooter className="border-top">
        <Button color="primary" onClick={onConfirmation} disabled={isLoading}>
          Confirm
          {isLoading && <Spinner size="sm" className="ms-2" />}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmationModal;
