import { toast } from "react-toastify";
import firebase from "../../config/firebase";

const db = firebase.firestore().collection("flags");

export const fetchFlags =
  ({ uid }) =>
  (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        const querySnapshot = await db
          .where("uid", "==", uid)
          .where("type", "==", "personal")
          .where("status", "==", "active")
          .orderBy("createdAt", "desc")
          .get();
        const flags = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        dispatch({
          type: "FETCH_PLAYER_FLAGS_SUCCESS",
          payload: flags,
        });
        resolve(flags);
      } catch (error) {
        console.error(error);
        reject(error);
      }
    });
  };

export const fetchAllianceFlags =
  ({ allianceId }) =>
  (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        const querySnapshot = await db
          .where("allianceId", "==", allianceId)
          .where("type", "==", "alliance")
          .where("status", "==", "active")
          .orderBy("createdAt", "desc")
          .get();
        const flags = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        dispatch({
          type: "FETCH_ALLIANCE_FLAGS_SUCCESS",
          payload: flags,
        });
        resolve(flags);
      } catch (error) {
        console.error(error);
        reject(error);
      }
    });
  };

export const fetchAllianceFlagRequests =
  ({ allianceId, uid = null }) =>
  (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        let query = db
          .where("allianceId", "==", allianceId)
          .where("type", "==", "alliance")
          .where("status", "==", "pending");
        if (uid) {
          query = query.where("uid", "==", uid);
          console.log("uid: ", uid);
        }
        const querySnapshot = await query.orderBy("createdAt", "desc").get();
        const flags = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        dispatch({
          type: "FETCH_ALLIANCE_FLAG_REQUESTS_SUCCESS",
          payload: flags,
        });
        resolve(flags);
      } catch (error) {
        console.error(error);
        reject(error);
      }
    });
  };

export const allianceFlagRequestAction =
  ({ id, status }) =>
  (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        await db.doc(id).update({ status });
        dispatch({
          type: "EDIT_FLAG_SUCCESS",
          payload: { id, flag: status },
        });
        toast.success(
          `Flag request ${
            status === "active" ? "accepted" : "rejected"
          } successfully!`
        );
        resolve(id);
      } catch (error) {
        console.error(error);
        toast.error(error.message || "Unable to delete flag");
        reject(error);
      }
    });
  };

export const addFlag =
  ({ uid, payload }) =>
  (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        let duplicationError = "Only one player flag can be added";
        let query = db
          .where("type", "==", payload?.type)
          .where("status", "==", "active");
        if (payload?.markType === "player") {
          query = query.where("markType", "==", "player");
        }
        if (payload?.markType !== "player") {
          query = query.where("coordinates", "==", payload?.coordinates);
          duplicationError = "Flag already present at the same coordinates";
        }

        const querySnapshot = await query.get();
        if (!querySnapshot.empty) {
          throw new Error(duplicationError);
        }

        const favDocRef = db.doc();
        const flagData = {
          uid,
          ...payload,
          status: "active",
          createdAt: firebase.firestore.Timestamp.now(),
        };
        await favDocRef.set(flagData);
        toast.success("Flag added successfully!");
        dispatch({
          type: "ADD_FLAG_SUCCESS",
          payload: { id: favDocRef.id, ...flagData },
        });
        resolve(flagData);
      } catch (error) {
        console.error(error);
        toast.error(error.message || "Unable to add flag");
        reject(error);
      }
    });
  };

export const sendAllianceFlagRequest =
  ({ uid, payload }) =>
  (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        const querySnapshot = await db
          .where("type", "==", payload?.type)
          .where("coordinates", "==", payload?.coordinates)
          .where("status", "==", "active")
          .get();
        if (!querySnapshot.empty) {
          throw new Error("Flag already present at the same coordinates");
        }

        const reqDocRef = db.doc();
        const flagData = {
          uid,
          ...payload,
          status: "pending",
          createdAt: firebase.firestore.Timestamp.now(),
        };
        await reqDocRef.set(flagData);
        toast.success("Flag request sent successfully!");
        dispatch({
          type: "SEND_FLAG_REQUEST_SUCCESS",
          payload: { id: reqDocRef.id, ...flagData },
        });
        resolve(flagData);
      } catch (error) {
        console.error(error);
        toast.error(error.message || "Unable to add flag");
        reject(error);
      }
    });
  };

export const editFlag =
  ({ id, payload, checkForDuplicates }) =>
  (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        if (checkForDuplicates) {
          const querySnapshot = await db
            .where("coordinates", "==", payload?.coordinates)
            .get();
          if (!querySnapshot.empty) {
            throw new Error("Flag already present at the same coordinates");
          }
        }
        const flagData = {
          ...payload,
        };
        await db.doc(id).update(flagData);
        toast.success("Flag updated successfully!");
        dispatch({
          type: "EDIT_FLAG_SUCCESS",
          payload: { id, flag: flagData },
        });
        resolve(flagData);
      } catch (error) {
        console.error(error);
        toast.error(error.message || "Unable to update flag");
        reject(error);
      }
    });
  };

export const deleteFlag =
  ({ id }) =>
  (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        await db.doc(id).delete();
        dispatch({
          type: "DELETE_FLAG_SUCCESS",
          payload: id,
        });
        toast.success("Flag deleted successfully!");
        resolve(id);
      } catch (error) {
        console.error(error);
        toast.error(error.message || "Unable to delete flag");
        reject(error);
      }
    });
  };

export const fetchPlayerAlliance =
  ({ playerName }) =>
  () => {
    return new Promise(async (resolve, reject) => {
      try {
        // Name of collection with latest data (latestMapInformation)
        const querySnapshot = await firebase
          .firestore()
          .collection("latestMapInformation")
          .where("playerName", "==", playerName)
          .orderBy("currentDate", "desc")
          .limit(1)
          .get();

        const villageData =
          querySnapshot.docs.length > 0 ? querySnapshot.docs[0].data() : null;
        resolve(villageData?.allianceID || null);
      } catch (error) {
        console.error("Error fetching alliance id:", error);
        reject(error);
      }
    });
  };

export const fetchPlayerVillages =
  ({ playerName }) =>
  (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        const querySnapshot = await firebase
          .firestore()
          .collection("latestMapInformation")
          .where("playerName", "==", playerName)
          .orderBy("currentDate", "desc")
          .get();

        const villages = querySnapshot.docs.map((doc) => {
          const { x, y, ...rest } = doc.data();
          return {
            id: doc.id,
            coordinates: { x, y },
            ...rest,
          };
        });

        dispatch({
          type: "FETCH_PLAYER_VILLAGES_SUCCESS",
          payload: villages,
        });
        resolve(villages);
      } catch (error) {
        console.error("Error fetching alliance id:", error);
        reject(error);
      }
    });
  };
