import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import CustomMap from "../components/InteractiveMap/Map";
import { useDispatch, useSelector } from "react-redux";
import AllianceControlZone from "../components/InteractiveMap/AllianceControlZone";
import { fetchAllianceControlZone } from "../store/actions/allianceControlZoneActions";
import AlliancesFilter from "../components/InteractiveMap/Filters/AlliancesFilter";
import PlayersFilter from "../components/InteractiveMap/Filters/PlayersFilter";
import VillagesFilter from "../components/InteractiveMap/Filters/VillagesFilter";

const InteractiveMap = () => {
  const dispatch = useDispatch();
  const { serverSettingsData } = useSelector((state) => state.serverSettings);
  const { uid, user } = useSelector((state) => state.auth);
  const { allianceControlZone } = useSelector((state) => state.controlZone);

  const [allianceControlModal, setAllianceControlModal] = useState(false);
  const [alliancesModal, setAlliancesModal] = useState(false);
  const [playerModal, setPlayerModal] = useState(false);
  const [villagesModal, setVillagesModal] = useState(false);
  const [selectedAlliances, setSelectedAlliances] = useState([]);
  const [selectedPlayers, setSelectedPlayers] = useState([]);
  const [selectedVillages, setSelectedVillages] = useState([]);
  const [timezone, setTimeZone] = useState();
  const [villagesData, setVillagesData] = useState([]);
  const [activeItems, setActiveItems] = useState([]);

  const toggleModal = () => setAllianceControlModal(!allianceControlModal);
  const toggleFilterModal = () => {
    if (alliancesModal) {
      activeItems?.length && setActiveItems([]);
    } else {
      setActiveItems(selectedAlliances);
    }
    setAlliancesModal(!alliancesModal);
  };
  const togglePlayerModal = () => {
    if (playerModal) {
      activeItems?.length && setActiveItems([]);
    } else {
      setActiveItems(selectedPlayers);
    }
    setPlayerModal(!playerModal);
  };

  const toggleVillageModal = () => {
    if (villagesModal) {
      activeItems?.length && setActiveItems([]);
    } else {
      setActiveItems(selectedVillages);
    }
    setVillagesModal(!villagesModal);
  };

  const handleActiveItems = (item) => {
    setActiveItems((prev) =>
      prev.some((selectedItem) => selectedItem.id === item.id)
        ? prev.filter((selectedItem) => selectedItem.id !== item.id)
        : [...prev, item]
    );
  };

  useEffect(() => {
    if (serverSettingsData && serverSettingsData.length > 0) {
      const data = serverSettingsData[0];
      setTimeZone(data?.timezone);
    }
  }, [serverSettingsData]);

  useEffect(() => {
    if (uid) {
      dispatch(fetchAllianceControlZone({ uid }));
    }
  }, [uid]);

  return (
    <>
      <div className="d-flex justify-content-center flex-wrap gap-3 mt-4 mb-4">
        {user?.role === "Administrator" && (
          <Button
            className="text-white p-2"
            color="info"
            onClick={toggleModal}
            disabled={!user?.role === "Administrator"}
            type="button"
          >
            Set Alliance Control Zone
          </Button>
        )}
        <Button
          className="text-white p-2"
          color="info"
          onClick={toggleFilterModal}
          type="button"
        >
          Filter Alliances
        </Button>
        <Button
          className="text-white p-2"
          color="info"
          onClick={togglePlayerModal}
          type="button"
        >
          Filter Players
        </Button>
        <Button
          className="text-white p-2"
          color="info"
          type="button"
          onClick={toggleVillageModal}
        >
          Filter Villages
        </Button>
      </div>
      <div className="d-flex justify-content-center align-items-center mb-5">
        <CustomMap
          timezone={timezone}
          selectedAlliances={selectedAlliances}
          selectedPlayers={selectedPlayers}
          selectedVillages={selectedVillages}
        />
      </div>
      {/* Alliance Control Zone */}
      {allianceControlModal && (
        <AllianceControlZone
          isOpen={allianceControlModal}
          toggle={toggleModal}
          activeZone={allianceControlZone}
        />
      )}
      {/* Filters */}
      {alliancesModal && (
        <AlliancesFilter
          isOpen={alliancesModal}
          toggle={toggleFilterModal}
          timezone={timezone}
          activeItems={activeItems}
          setActiveItems={setActiveItems}
          handleActiveItems={handleActiveItems}
          setSelectedAlliances={setSelectedAlliances}
        />
      )}
      {playerModal && (
        <PlayersFilter
          isOpen={playerModal}
          toggle={togglePlayerModal}
          timezone={timezone}
          activeItems={activeItems}
          setActiveItems={setActiveItems}
          handleActiveItems={handleActiveItems}
          setSelectedPlayers={setSelectedPlayers}
          selectedAlliances={selectedAlliances}
        />
      )}
      {villagesModal && (
        <VillagesFilter
          isOpen={villagesModal}
          toggle={toggleVillageModal}
          timezone={timezone}
          activeItems={activeItems}
          setActiveItems={setActiveItems}
          handleActiveItems={handleActiveItems}
          setSelectedVillages={setSelectedVillages}
          selectedAlliances={selectedAlliances}
          selectedPlayers={selectedPlayers}
        />
      )}
    </>
  );
};

export default InteractiveMap;
