import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../assets/css/login.css"
import { useDispatch } from "react-redux";
import { loginUser } from "../../store/actions/authActions";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const dispatch = useDispatch();
  const Navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isButtonPressed, setIsButtonPressed] = useState(false);

  useEffect(() => {
    const checkLogin = async () => {
      const isLoggedIn = localStorage.getItem("userId");
      if (isLoggedIn) {
        return Navigate("/");
      }
    };

    checkLogin();
  }, [Navigate]);

  const handleLogin = async () => {
    setIsButtonPressed(true);

    try {
      await dispatch(
        loginUser(email, password, () => {
          Navigate("/");
          toast.success("Login successful!", {
            position: toast.POSITION.TOP_RIGHT,
          });
        })
      );
    } catch (error) {
      console.error("Login failed:", error.message);
      toast.error("Login failed. Please check your credentials.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setIsButtonPressed(false);
    }
  };

  return (
    <div className="login-container">
      <div className="login-form">
        <label className="login-label">Email:</label>
        <input
          type="text"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="login-input"
        />
        <br />
        <label className="login-label">Password:</label>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="login-input"
        />
        <br />
        <button
          onClick={handleLogin}
          className={`login-button ${isButtonPressed ? "pressed" : ""}`}
          disabled={isButtonPressed}
        >
          {isButtonPressed ? "Logging In..." : "Login"}
        </button>
      </div>
    </div>
  );
};

export default Login;
