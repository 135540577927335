const initialState = {
  permission: [],
  loading: false,
};

export default function userpermissionsReducer(state = initialState, action) {
  switch (action.type) {
    case "SET_PERMISSIONS":
      return {
        ...state,
        permission: action.payload,
      };
    case "IS_PERMISSIONS_LOADING":
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
}
