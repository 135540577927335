// authActions.js

import { toast } from "react-toastify";
import firebase from "../../config/firebase";

export const registerUser =
  (email, password, userDetails, onSuccess = () => {}) =>
  async (dispatch) => {
    dispatch(loading(true));
    try {
      const emailQuery = await firebase
        .firestore()
        .collection("users")
        .where("email", "==", email)
        .get();
      let ID = emailQuery.docs[0]?.id;
      let data = emailQuery.docs[0]?.data();

      if (data?.email == email && data?.isDeleted) {
        const shouldReactivate = window.confirm(
          `This email: ${data?.email} is already registered and blocked by admin. Do you want to reactivate it again?`
        );
        if (shouldReactivate) {
          firebase
            .firestore()
            .collection("users")
            .doc(ID)
            .update({
              isDeleted: false,
              role: "pending",
            })
            .then(() => {
              onSuccess();
              dispatch(loading(false));
              toast.success("Reactivate successful!", {
                position: toast.POSITION.TOP_RIGHT,
              });
            });
        }
      } else {
        const userCredential = await firebase
          .auth()
          .createUserWithEmailAndPassword(email, password);
        const { user } = userCredential;
        await firebase
          .firestore()
          .collection("users")
          .doc(user.uid)
          .set({
            ...userDetails,
            isDeleted: false,
          })
          .then(() => {
            onSuccess();
            dispatch({ type: "REGISTER_SUCCESS" });
            dispatch(loading(false));
            toast.success("Registered successful!", {
              position: toast.POSITION.TOP_RIGHT,
            });
          });
      }
    } catch (error) {
      dispatch(loading(false));
      dispatch({ type: "REGISTER_FAIL", payload: error.message });
    }
  };

export const loginUser =
  (email, password, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      const authResult = await firebase
        .auth()
        .signInWithEmailAndPassword(email, password);
      const userId = authResult.user.uid;

      const userDoc = await firebase
        .firestore()
        .collection("users")
        .doc(userId)
        .get();
      const userData = userDoc.data();

      if (userData && userData.role == "pending") {
        toast.error(
          "Your account is pending approval. Please wait for approval."
        );
      } else if (userData?.isDeleted) {
        toast.error("Your Account has been deleted");
      } else {
        localStorage.setItem("userId", userId);
        dispatch({ type: "LOGIN_SUCCESS", payload: { userId } });
        dispatch(fetchUserData(userId));
        onSuccess();
      }
    } catch (error) {
      dispatch({ type: "LOGIN_FAIL", payload: error.message });
      throw error;
    }
  };

export const fetchUserData = (userId) => async (dispatch) => {
  if (!userId) {
    window.location.href = "/";
    return;
  }

  dispatch({ type: "FETCH_USER_DATA_START" }); // Dispatch the loading start action

  try {
    const userDoc = await firebase
      .firestore()
      .collection("users")
      .doc(userId)
      .get();
    const userData = userDoc.data();
    dispatch({ type: "FETCH_USER_DATA_SUCCESS", payload: userData });
  } catch (error) {
    dispatch({ type: "FETCH_USER_DATA_FAIL", payload: error.message });
    throw error;
  }
};

export const getUidFromLocalStorage = () => async (dispatch) => {
  try {
    const userId = localStorage.getItem("userId");

    if (userId) {
      dispatch({ type: "GET_UID_FROM_LOCAL_STORAGE", payload: userId });
      dispatch(fetchUserData(userId));
    }
  } catch (error) {
    console.error("Error getting UID from local storage:", error.message);
  }
};

export const getAllDiscordNames = () => async (dispatch) => {
  try {
    firebase
      .firestore()
      .collection("users")
      .onSnapshot((query) => {
        let discordNames = [];
        for (let doc of query.docs) {
          let data = doc.data();
          discordNames.push(data.discordname);
        }
        dispatch({
          type: "ALL_DISCORD_NAMES",
          payload: discordNames,
        });
      });
  } catch (error) {
    console.log({ error });
  }
};

export const loading = (val) => async (dispatch) => {
  dispatch({
    type: "LOADING",
    payload: val,
  });
};
