const initialState = {
  Teutons: [],
  Romans: [],
  Guals: [],
  Nature: [],
  Natars: [],
  loading: false,
};

const TroopGlossaryReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_TEUTONS_DATA":
      return {
        ...state,
        Teutons: action.payload,
      };
    case "GET_ROMANS_DATA":
      return {
        ...state,
        Romans: action.payload,
      };
    case "GET_GUALS_DATA":
      return {
        ...state,
        Guals: action.payload,
      };
    case "GET_NATURE_DATA":
      return {
        ...state,
        Nature: action.payload,
      };
    case "GET_NATARS_DATA":
      return {
        ...state,
        Natars: action.payload,
      };
    case "IS_TROOP_DATA_LOADING":
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};

export default TroopGlossaryReducer;
