import { toast } from "react-toastify";
import firebase from "../../config/firebase";
import { getFilteredData } from "./playroasterAction";

export const createNewGoal =
  (payload, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      const docRef = await firebase
        .firestore()
        .collection("allianceBonus")
        .add(payload);

      await docRef.update({ id: docRef.id });

      onSuccess(docRef.id);
      dispatch(getGoalData());
      dispatch(getFilteredData());
      toast.success("New Goal started");
    } catch (error) {
      console.error(error);
      toast.error(`Error starting new goal`);
    }
  };

export const getGoalData = () => async (dispatch) => {
  try {
    dispatch(loader(true));
    const snapshot = await firebase
      .firestore()
      .collection("allianceBonus")
      .get();
    const rows = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    dispatch({ type: "GET_GOAL_DATA", payload: rows });
    dispatch(loader(false));
  } catch (error) {
    console.error("Error fetching rows:", error);
    dispatch(loader(false));
  }
};

export const addGameAccountName =
  (payload, onSuccess = () => {}) =>
  async () => {
    try {
      if (!payload || typeof payload !== "string" || payload.trim() === "") {
        return;
      }

      const querySnapshot = await firebase
        .firestore()
        .collection("allianceBonus")
        .where("isComplete", "==", false)
        .get();

      querySnapshot.forEach(async (doc) => {
        try {
          let resources = doc.data().resources || [];

          if (resources.some((item) => item.player === payload)) {
            return;
          }
          resources.push({ player: payload });

          await doc.ref.update({ resources });
          onSuccess(doc.id);
        } catch (updateError) {
          console.error("Error updating document:", updateError);
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

export const addDonation =
  (payload, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      const { donatedResources, player } = payload || {};

      if (
        !player ||
        typeof player !== "string" ||
        player.trim() === "" ||
        typeof donatedResources !== "number" ||
        donatedResources <= 0
      ) {
        console.error("Invalid payload:", payload);
        return;
      }

      const querySnapshot = await firebase
        .firestore()
        .collection("allianceBonus")
        .where("isComplete", "==", false)
        .get();

      querySnapshot.forEach(async (doc) => {
        try {
          let resources = doc.data().resources || [];

          const index = resources.findIndex((item) => item.player === player);

          if (index !== -1) {
            if (resources[index].hasOwnProperty("donatedResources")) {
              resources[index].donatedResources += donatedResources;
            } else {
              resources[index].donatedResources = donatedResources;
            }
          }

          await doc.ref.update({ resources });

          toast.success(`Resources donated successfully.`);
        } catch (updateError) {
          console.error("Error updating document:", updateError);
          toast.error(`Error sending resources.`);
        }
      });

      dispatch(getGoalData());
    } catch (error) {
      console.error(error);
    }
  };

export const addResourcesPerHour =
  (payload, onSuccess = () => {}) =>
  async (dispatch, getState) => {
    try {
      if (
        !payload.player ||
        typeof payload.player !== "string" ||
        payload.player.trim() === "" ||
        typeof payload.resourcesHour !== "number" ||
        payload.resourcesHour <= 0
      ) {
        console.error("Invalid payload:", payload);
        return;
      }

      const querySnapshot = await firebase
        .firestore()
        .collection("allianceBonus")
        .where("isComplete", "==", false)
        .get();

      querySnapshot.forEach(async (doc) => {
        try {
          let resources = doc.data().resources || [];

          const index = resources.findIndex(
            (item) => item.player === payload.player
          );

          if (index !== -1) {
            if (resources[index].hasOwnProperty("resourcesHour")) {
              resources[index].resourcesHour += payload.resourcesHour;
            } else {
              resources[index].resourcesHour = payload.resourcesHour;
            }
            await doc.ref.update({ resources });
            toast.success(`Resources per hour added successfully.`);
          } else toast.error("Player not found");
        } catch (updateError) {
          console.error("Error updating document:", updateError);
        }
      });

      dispatch(getGoalData());
    } catch (error) {
      console.error(error);
      toast.error(`Error adding Resources per hour.`);
    }
  };

export const completeGoal =
  (payload, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      const querySnapshot = await firebase
        .firestore()
        .collection("allianceBonus")
        .where("isComplete", "==", false)
        .get();

      querySnapshot.forEach(async (doc) => {
        try {
          await doc.ref.update({ isComplete: payload });

          dispatch({
            type: "UPDATE_GOAL_DATA",
            payload: { id: doc.id, isComplete: payload },
          });
          dispatch(getGoalData());
        } catch (error) {
          console.error("Error updating document:", error);
        }
      });
    } catch (error) {
      console.error("Error fetching documents:", error);
    }
  };

export const addWebhook = (payload) => async (dispatch) => {
  try {
    const docRef = firebase
      .firestore()
      .collection("webHooks")
      .doc("allianceBonus");
    await docRef.set(payload, { merge: true });
    dispatch({ type: "WEBHOOK_ADDED" });
    toast.success("Webhook updated Successfully");
  } catch (error) {
    console.error(error);
    dispatch({ type: "WEBHOOK_ERROR", error });
  }
};

export const loader = (val) => async (dispatch) => {
  dispatch({
    type: "GOAL_LOADING",
    payload: val,
  });
};
