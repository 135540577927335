const initialState = {};

export default function serverSettingsReducer(state = initialState, action) {
  switch (action.type) {
    case "SAVE_SERVER_SETTINGS_DATA":
      return {
        ...state,
        serverSettingsData: action.payload,
      };
    case "SAVE_SERVER_MAP_DATA":
      return {
        ...state,
        serverMapInformation: action.payload,
      };
    case "DELETE_SERVER_SETTINGS_DATA":
      return {
        ...state,
        serverSettingsData: state.serverSettingsData.filter(
          (item) => item.id !== action.payload
        ),
      };
    case "SERVER_SETTINGS_LOADING":
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
}
