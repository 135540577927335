import { toast } from "react-toastify";
import firebase from "../../config/firebase";

const db = firebase.firestore().collection("allianceControlZones");

export const fetchAllianceControlZone =
  ({ uid }) =>
  (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        const querySnapshot = await db.doc(uid).get();

        if (querySnapshot.exists) {
          const allianceControlZone = {
            id: querySnapshot.id,
            ...querySnapshot.data(),
          };
          dispatch({
            type: "FETCH_ALLIANCE_CONTROL_ZONE_SUCCESS",
            payload: allianceControlZone,
          });
          resolve(allianceControlZone);
        }
      } catch (error) {
        console.error(error);
        reject(error);
      }
    });
  };

export const addAllianceControlZone =
  ({ uid, payload }) =>
  (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        await db.doc(uid).set({
          ...payload,
          createdAt: firebase.firestore.Timestamp.now(),
          updatedAt: firebase.firestore.Timestamp.now(),
        });
        toast.success("Alliance Control Zone set successfully!");
        dispatch({
          type: "ADD_ALLIANCE_CONTROL_ZONE_SUCCESS",
          payload,
        });
        resolve(payload);
      } catch (error) {
        console.error(error);
        toast.error(error.message || "Unable to add alliance control zone");
        reject(error);
      }
    });
  };

export const deleteAllianceControlZone =
  ({ uid }) =>
  (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        await db.doc(uid).delete();
        toast.success("Alliance Control Zone deleted successfully!");
        dispatch({
          type: "DELETE_ALLIANCE_CONTROL_ZONE_SUCCESS",
          payload: uid,
        });
        resolve(uid);
      } catch (error) {
        console.error(error);
        toast.error(error.message || "Unable to delete alliance control zone");
        reject(error);
      }
    });
  };
