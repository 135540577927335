import React, { useState, useEffect } from "react";
import { Input } from "reactstrap";

const DebouncedSearch = ({ placeholder, onSearch }) => {
  const [searchInput, setSearchInput] = useState("");
  const [debouncedValue, setDebouncedValue] = useState(searchInput);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedValue(searchInput);
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [searchInput]);

  useEffect(() => {
    if (debouncedValue.trim() || debouncedValue === "") {
      onSearch(debouncedValue);
    }
  }, [debouncedValue, onSearch]);

  return (
    <Input
      type="text"
      placeholder={placeholder || ""}
      value={searchInput}
      onChange={(e) => setSearchInput(e.target.value)}
    />
  );
};

export default DebouncedSearch;
