import { toast } from "react-toastify";
import firebase from "../../config/firebase";
import { getGoalData } from "./allianceBonusAction";

export const updateResourcesPerHour =
  (payload, onSuccess = () => {}) =>
  async (dispatch, getState) => {
    try {
      if (!payload.player || !payload.rph || !payload.serverDay) {
        console.error("Invalid payload");
        return;
      }

      const querySnapshot = await firebase
        .firestore()
        .collection("playerTroop")
        .where("player", "==", payload.player)
        .get();

      let playerDoc = null;

      querySnapshot.forEach((doc) => {
        if (doc.data().player === payload.player) {
          playerDoc = doc;
        }
      });

      if (playerDoc) {
        try {
          const existingRPHArray = playerDoc.data().ResourcePerHour || [];

          const index = existingRPHArray.findIndex(
            (item) => item.serverDay === payload.serverDay
          );

          if (index !== -1) {
            existingRPHArray[index].rph = payload.rph;
          } else {
            existingRPHArray.push({
              serverDay: payload.serverDay,
              rph: payload.rph,
            });
          }
          await playerDoc.ref.update({
            ResourcePerHour: existingRPHArray,
          });

          toast.success(`Resources per hour updated successfully.`);
        } catch (updateError) {
          console.error("Error updating document:", updateError);
          toast.error("Error updating resources per hour.");
        }
      } else {
        try {
          await firebase
            .firestore()
            .collection("playerTroop")
            .add({
              player: payload.player,
              ResourcePerHour: [
                { serverDay: payload.serverDay, rph: payload.rph },
              ],
            });
          toast.success(`Resources per hour added successfully.`);
        } catch (addError) {
          console.error("Error adding document:", addError);
          toast.error("Error adding resources per hour.");
        }
      }

      dispatch(getRPHData());
      dispatch(getGoalData())
      onSuccess();
    } catch (error) {
      console.error("Error fetching documents:", error);
      toast.error("Error processing the request.");
    }
  };

export const getRPHData = () => async (dispatch) => {
  try {
    dispatch(loader(true));
    const snapshot = await firebase.firestore().collection("playerTroop").get();
    const rows = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    dispatch({ type: "GET_RPH_DATA", payload: rows });
    dispatch(loader(false));
  } catch (error) {
    console.error("Error fetching rows:", error);
    dispatch(loader(false));
  }
};

export const loader = (val) => async (dispatch) => {
  dispatch({
    type: "PLAYER_TROOP_LOADING",
    payload: val,
  });
};
