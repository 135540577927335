import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import { addAllianceControlZone } from "../../../store/actions/allianceControlZoneActions";

const AllianceControlZone = ({ isOpen, toggle, activeZone = null }) => {
  const dispatch = useDispatch();
  const { uid, user } = useSelector((state) => state.auth);

  const [coordinates, setCoordinates] = useState([
    { x: "", y: "" },
    { x: "", y: "" },
    { x: "", y: "" },
    { x: "", y: "" },
  ]);
  const [color, setColor] = useState("#000000");
  const [isLoading, setIsLoading] = useState(false);

  const handleCoordinateChange = (index, axis, value) => {
    const numberValue = Number(value);
    const newCoordinates = [...coordinates];
    newCoordinates[index][axis] = numberValue;
    setCoordinates(newCoordinates);
  };

  const handleSubmit = () => {
    if (uid && user?.role === "Administrator") {
      setIsLoading(true);
      dispatch(
        addAllianceControlZone({
          uid,
          payload: {
            coordinates,
            color,
          },
        })
      )
        .then(() => toggle())
        .finally(() => setIsLoading(false));
    }
  };

  useEffect(() => {
    if (isOpen) {
      if (activeZone) {
        setCoordinates(activeZone?.coordinates);
        setColor(activeZone?.color);
      } else {
        setCoordinates([
          { x: "", y: "" },
          { x: "", y: "" },
          { x: "", y: "" },
          { x: "", y: "" },
        ]);
        setColor("");
      }
    }
  }, [isOpen, activeZone]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>Set Alliance Control Zone</ModalHeader>
      <ModalBody>
        {coordinates?.map((coordinate, index) => (
          <div key={index} className="mb-3">
            <h6>Point {index + 1}</h6>
            <Input
              type="number"
              placeholder="X"
              value={coordinate?.x}
              onChange={(e) =>
                handleCoordinateChange(index, "x", e.target.value)
              }
              className="mb-2"
            />
            <Input
              type="number"
              placeholder="Y"
              value={coordinate?.y}
              onChange={(e) =>
                handleCoordinateChange(index, "y", e.target.value)
              }
            />
          </div>
        ))}
        <div className="mb-3">
          <h6>Color</h6>
          <Input
            type="color"
            placeholder="Select color"
            required
            value={color}
            onChange={(e) => setColor(e.target.value)}
            style={{ height: "37.33px" }}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={handleSubmit}
          disabled={isLoading || !user?.role === "Administrator"}
        >
          Submit
          {isLoading && <Spinner size="sm" className="ms-2" />}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AllianceControlZone;
