import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../assets/css/AdminDashboard.css";
import userAccounts from "./../assets/Images/buttonIcons/userAccounts.svg";
import premission from "./../assets/Images/buttonIcons/permission.svg";
import serverSettings from "./../assets/Images/buttonIcons/serverSettings.svg";
import permanent from "./../assets/Images/buttonIcons/permanent.svg";
import back from "./../assets/Images/buttonIcons/back.svg";
import { useDispatch, useSelector } from "react-redux";
import { getPermissions } from "../store/actions/userpermissionsAction";

const Admin = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { permission } = useSelector((state) => state.userPermissions);
  const [condition, setCondition] = useState();

  //-------------Roles/Permissions--------------//

  const permissionData = permission?.admin;
  const role = user?.role;
  const functionalRole = user?.functionalRole;
  const functionalPermission =
    permission && permissionData && permissionData[functionalRole];

  useEffect(() => {
    if (permission && permissionData) {
      const rolePermission = permissionData[role];
      const functionalPermission = permissionData[functionalRole];
      if (
        functionalPermission !== undefined &&
        functionalPermission.trim() !== ""
      ) {
        setCondition(functionalPermission);
      } else if (rolePermission !== undefined && rolePermission.trim() !== "") {
        setCondition(rolePermission);
      }
    }
  }, [permission, permissionData, role, functionalRole]);

  useEffect(() => {
    dispatch(getPermissions());
  }, []);

  return (
    <div className="container-fluid">
      {condition == "Locked" ? (
        <div
          className="text-center d-flex justify-content-center align-items-center "
          style={{ height: "70vh" }}
        >
          <h2>
            This page is not available for{" "}
            {functionalPermission !== undefined &&
            functionalPermission.trim() !== ""
              ? functionalRole
              : role}
          </h2>
        </div>
      ) : (
        <div>
          <Link className="btn mt-2 ms-3 button-link move-back-button" to="/">
            <img src={back} alt="Move Back" width={30} />
          </Link>
          <div className="px-5 button-card">
            <Link
              className="btn btn-primary mt-2 ms-3 button-link"
              to="/user-accounts"
            >
              <div className="button-icon">
                <img
                  src={userAccounts}
                  className="img-fluid"
                  alt={"User Accounts"}
                />
              </div>
              <p>User Accounts</p>
            </Link>
            <Link
              className="btn btn-primary mt-2 ms-3 button-link"
              to="/user-permissions"
            >
              <div className="button-icon">
                <img
                  src={premission}
                  className="img-fluid"
                  alt={"Permissions"}
                />
              </div>
              <p>User Permissions</p>
            </Link>
            <Link
              className="btn btn-primary mt-2 ms-3 button-link"
              to="/server-settings"
            >
              <div className="button-icon">
                <img
                  src={serverSettings}
                  className="img-fluid"
                  alt="Server Settings"
                />
              </div>
              <p>Server Settings</p>
            </Link>
            <Link
              className="btn btn-primary mt-2 ms-3 button-link"
              to="/permanent-roster"
            >
              <div className="button-icon">
                <img
                  src={permanent}
                  className="img-fluid"
                  alt="Permanent Roster"
                />
              </div>
              <p>Permanent Roster</p>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default Admin;
