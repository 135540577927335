import { toast } from "react-toastify";
import firebase from "../../config/firebase";

export const fetchFavoriteCoordinates =
  ({ uid }) =>
  (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        const querySnapshot = await firebase
          .firestore()
          .collection("favoriteCoordinates")
          .where("uid", "==", uid)
          .orderBy("createdAt", "desc")
          .get();
        const favorites = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        dispatch({
          type: "FETCH_FAVORITE_COORDINATES_SUCCESS",
          payload: favorites,
        });
        resolve(favorites);
      } catch (error) {
        console.error(error);
        reject(error);
      }
    });
  };

export const addFavoriteCoordinate =
  ({ uid, payload }) =>
  (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        const querySnapshot = await firebase
          .firestore()
          .collection("favoriteCoordinates")
          .where("coordinates", "==", payload?.coordinates)
          .get();
        if (!querySnapshot.empty) {
          throw new Error("Coordinate is already in favorites list");
        }
        const favDocRef = firebase
          .firestore()
          .collection("favoriteCoordinates")
          .doc();
        const favoriteData = {
          id: favDocRef.id,
          uid,
          ...payload,
          createdAt: firebase.firestore.Timestamp.now(),
        };
        await favDocRef.set(favoriteData);
        toast.success("Coordinate added to favorites list successfully!");
        dispatch({
          type: "ADD_FAVORITE_COORDINATE_SUCCESS",
          payload: favoriteData,
        });
        resolve(favoriteData);
      } catch (error) {
        console.error(error);
        toast.error(
          error.message || "Unable to add coordinate to favorites list"
        );
        reject(error);
      }
    });
  };

export const editFavoriteCoordinate =
  ({ id, payload, checkForDuplicates }) =>
  (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        if (checkForDuplicates) {
          const querySnapshot = await firebase
            .firestore()
            .collection("favoriteCoordinates")
            .where("coordinates", "==", payload?.coordinates)
            .get();
          if (!querySnapshot.empty) {
            throw new Error("Coordinates are already favorite");
          }
        }
        const favoriteData = {
          ...payload,
        };
        await firebase
          .firestore()
          .collection("favoriteCoordinates")
          .doc(id)
          .update(favoriteData);
        toast.success("Coordinate updated successfully!");
        dispatch({
          type: "EDIT_FAVORITE_COORDINATE_SUCCESS",
          payload: { id, favorite: favoriteData },
        });
        resolve(favoriteData);
      } catch (error) {
        console.error(error);
        toast.error(error.message || "Unable to update coordinate");
        reject(error);
      }
    });
  };

export const deleteFavoriteCoordinate =
  ({ id }) =>
  (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        await firebase
          .firestore()
          .collection("favoriteCoordinates")
          .doc(id)
          .delete();
        dispatch({
          type: "DELETE_FAVORITE_COORDINATE_SUCCESS",
          payload: id,
        });
        toast.success("Coordinate deleted from favorites list successfully!");
        resolve(id);
      } catch (error) {
        console.error(error);
        toast.error(error.message || "Unable to delete coordinate");
        reject(error);
      }
    });
  };
