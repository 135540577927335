import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";

const PopulationGraph = ({ data }) => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);
  const processData = (data) => {
    const serverDaysMap = new Map();
    data.forEach((item) => {
      if (serverDaysMap.has(item.serverDay)) {
        serverDaysMap.set(
          item.serverDay,
          serverDaysMap.get(item.serverDay) + item.totalPopulation
        );
      } else {
        serverDaysMap.set(item.serverDay, item.totalPopulation);
      }
    });

    const sortedServerDays = Array.from(serverDaysMap.keys()).sort(
      (a, b) => a - b
    );
    const labels = sortedServerDays;
    const populationData = sortedServerDays.map((serverDay) =>
      serverDaysMap.get(serverDay)
    );

    return { labels, populationData };
  };

  const downsampleData = (labels, data) => {
    const length = labels.length;
    let step = 1;

    if (length > 20 && length <= 75) {
      step = 5;
    } else if (length > 75) {
      step = 10;
    }

    const downsampledLabels = labels.filter((_, index) => index % step === 0);
    const downsampledData = data.filter((_, index) => index % step === 0);

    return { downsampledLabels, downsampledData };
  };

  useEffect(() => {
    if (chartRef.current && data.length > 0) {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }

      const { labels, populationData } = processData(data);
      const { downsampledLabels, downsampledData } = downsampleData(
        labels,
        populationData
      );

      const ctx = chartRef.current.getContext("2d");
      chartInstance.current = new Chart(ctx, {
        type: "line",
        data: {
          labels: downsampledLabels,
          datasets: [
            {
              label: "Population",
              data: downsampledData,
              borderColor: "#198754",
              tension: 0.1,
            },
          ],
        },
        options: {
          aspectRatio: 0,
          animation: false,
          scales: {
            x: {
              grid: {
                display: false,
              },
              title: {
                display: true,
                text: "Game Day",
              },
            },
            y: {
              title: {
                display: true,
                text: "Population",
              },
            },
          },
        },
      });
    }

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [data]);

  return <canvas ref={chartRef} height={250} />;
};

export default PopulationGraph;
