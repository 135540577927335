const initialState = {
  favoriteCoordinates: [],
};

const favoriteCoordinatesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_FAVORITE_COORDINATES_SUCCESS":
      return {
        ...state,
        favoriteCoordinates: action.payload,
      };
    case "ADD_FAVORITE_COORDINATE_SUCCESS":
      return {
        ...state,
        favoriteCoordinates: [action.payload, ...state.favoriteCoordinates],
      };
    case "EDIT_FAVORITE_COORDINATE_SUCCESS":
      return {
        ...state,
        favoriteCoordinates: state.favoriteCoordinates.map((fav) =>
          fav.id === action.payload.id
            ? { ...fav, ...action.payload.favorite }
            : fav
        ),
      };
    case "DELETE_FAVORITE_COORDINATE_SUCCESS":
      return {
        ...state,
        favoriteCoordinates: state.favoriteCoordinates.filter(
          (fav) => fav.id !== action.payload
        ),
      };
    default:
      return state;
  }
};

export default favoriteCoordinatesReducer;
