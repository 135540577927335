const initialState = {
  playerHistory: [],
  allianceHistory: [],
};

const PlayerStatisticsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_PLAYER_HISTORY_DATA":
      return {
        ...state,
        playerHistory: action.payload,
      };

    case "GET_ALLIANCE_HISTORY_DATA":
      return {
        ...state,
        allianceHistory: action.payload,
      };

    case "PLAYER_STATS_LOADING":
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};

export default PlayerStatisticsReducer;
